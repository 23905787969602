import { Check } from '@mui/icons-material';
import { Box } from '@mui/material';
import './SmallCheckbox.scss';

const SmallCheckbox = ({ found }: { found: boolean }): JSX.Element => {
  return (
    <Box
      className="smallCheckboxSelect"
      style={found ? { backgroundColor: '#344054', color: 'white' } : {}}
    >
      {found ? <Check /> : <></>}
    </Box>
  );
};

export default SmallCheckbox;
