import { Box } from '@mui/material';
import { TitleTypography } from 'common/TitleTypography';
import CustomButton from 'common/button/CustomButton';
import ModalComposed from 'common/modal/ModalComposed';
import { useEffect, type CSSProperties, type ReactElement } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalControllerAtom } from 'atoms/atomModalController';
import { MODAL_TYPES } from 'common/interfaces/enums';
import 'common/Common.scss';

const LinkSent = (): ReactElement => {
  const setModalController = useSetRecoilState(modalControllerAtom);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setModalController({ type: MODAL_TYPES.LOGIN });
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const generateTitleTypography = (
    text: string,
    style?: CSSProperties
  ): ReactElement => {
    return (
      <TitleTypography
        key={`title-${Math.random()}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...{ ...style }
        }}
      >
        {text}
      </TitleTypography>
    );
  };

  const generateSubTitleTypography = (
    text: string,
    color?: string,
    style?: object
  ): ReactElement => {
    const commonStyle = {
      justifyContent: 'flex-start',
      fontFamily: 'ft-system-regular',
      fontSize: '14px',
      color: color ?? '#344054',
      fontWeight: '400',
      ...style
    };
    return generateTitleTypography(text, commonStyle);
  };

  return (
    <ModalComposed
      open={true}
      className="md-modal-wrapper"
      body={
        <Box style={{ height: '100%', width: '100%' }}>
          {generateTitleTypography('Password reset link sent!', {
            marginBottom: '20px'
          })}
          {generateSubTitleTypography(
            "Please check your email to continue the process. Don't forget to check your spam/junk folder too.",
            '#667085',
            { textAlign: 'left' }
          )}
          {generateSubTitleTypography(
            'In case that no email arrives in a while could be because no account is linked to the email sent',
            '#667085',
            { marginBottom: '20px', textAlign: 'left' }
          )}
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            <a
              style={{ color: '#667085' }}
              target="_blank"
              rel="noreferrer"
              href={`${process.env.REACT_APP_CONTACT_AIZ_WEB ?? ''}`}
            >
              {generateSubTitleTypography(
                "Haven't received the email? Contact us!",
                '#667085'
              )}
            </a>
            <CustomButton
              style={{ maxWidth: 'none' }}
              onClick={() => {
                setModalController({ type: MODAL_TYPES.LOGIN });
              }}
            >
              {'Login'}
            </CustomButton>
          </Box>
        </Box>
      }
    />
  );
};

export default LinkSent;
