export enum MODAL_TYPES {
  SAVE_MODEL = 'INTRODUCE_MODEL',
  SAVE_DATASET = 'INTRODUCE_DATASET',
  DELETE_DATASET = 'DELETE_DATASET',
  CLOSE = 'CLOSE',
  START_CHAT = 'START_CHAT',
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  LINK_SENT = 'LINK_SENT',
  UPLOAD_LINK = 'UPLOAD_LINK',
  UPLOAD_MANAGER = 'UPLOAD_MANAGER',
  RESET_PASSWORD = 'RESET_PASSWORD'
}

export enum HOME_VIEW_TYPES {
  CHAT = 'CHAT',
  LOAD_MODELS = 'LOAD_MODELS',
  LOAD_DATASETS = 'LOAD_DATASETS',
  DETAIL_MODEL = 'INSIGHT_MODEL',
  DETAIL_DATASETS = 'INSIGHT_DATASETS',
  SAVE_DATASET = 'INTRODUCE_DATASET',
  SAVE_MODEL = 'INTRODUCE_MODEL',
  INSIGHTS = 'INSIGHTS',
  CROSS_TABS = 'CROSS_TABS',
  BULK_PREDICTIONS = 'BULK_PREDICTIONS',
  PLAYGROUND = 'PLAYGROUND',
  TRANSFORMATION = 'TRANSFORMATION',
  MERGE_DATASETS = 'MERGE_DATASETS',
  MERGE_DATASETS_PREVIEW = 'MERGE_DATASETS_PREVIEW'
}

export enum MODAL_ACTIONS_ID {
  LOAD_MODELS = 'LOAD_MODELS',
  LOAD_DATASETS = 'LOAD_DATASETS',
  TRANSFORMATION = 'VIEW_DATASET'
}

// Intents are the identifiers that are sent in between api's that inform the users intentions.
export enum MODAL_INTENTS {
  SAVED_MODEL = 'SAVED_MODEL',
  INTRODUCED_DATASET = 'INTRODUCED_DATASET',
  CHOOSE_LOADED_MODEL = 'CHOOSE_LOADED_MODEL',
  CANCEL_LOAD_MODEL = 'CANCEL_LOAD_MODEL',
  INTRODUCE_DATASET = 'INTRODUCE_DATASET',
  CHOOSE_LOADED_DATASET = 'CHOOSE_LOADED_DATASET',
  INTRODUCED_FEATURE_ENGINEERING = 'INTRODUCED_FEATURE_ENGINEERING'
}

export enum ICON_TYPE {
  SELECT_DATA = 'select-data',
  GENERATE_DATA = 'generate-data',
  USERS01 = 'users-01',
  TRASH01 = 'trash-01',
  EDIT02 = 'edit-02',
  EYEOFF = 'eye-off',
  EYE = 'eye',
  DOWNLOAD01 = 'download-01',
  HELP_CIRCLE = 'help-circle',
  INFO_CIRCLE = 'info-circle',
  LOCK01 = 'lock-01',
  LINK03 = 'link-03',
  UPLOAD_CLOUD_01 = 'upload-cloud-01',
  OPEN_MY_SPACE = 'open-my-space',
  MERGE_DATA = 'merge-data',
  TABLE = 'table',
  DOTPOINTS01 = 'dotpoints-01',
  REFRESHCCW01 = 'refresh-ccw-01',
  BULK_PREDICTION = 'bulk-prediction',
  DATA = 'data',
  SETTINGS03 = 'settings-03',
  SETTINGS_FILTERS = 'settings-filters',
  COMBINE01 = 'combine-01',
  LAST_CHILD = 'last-child',
  MID_CHILD = 'mid-child',
  BAR_CHART = 'bar-chart',
  SINGLE_PREDICTION = 'single-prediction',
  DATA_TRANSFORMATION = 'data-transformation',
  CROSS_TABS = 'cross-tabs',
  EDIT_CONTEXT = 'edit-context',
  EXPAND = 'expand',
  LOG_OUT = 'log-out',
  USER = 'user-02',
  CHEVRON_DONW = 'chevron-down',
  CHEVRON_UP = 'chevron-up',
  ARROW_LEFT = 'arrow-left',
  PLUS = 'plus',
  CLOSE = 'close',
  DOTS_VERTICAL = 'dots-vertical',
  MINUS = 'minus',
  CHAT = 'chat',
  DELETE_RETURN = 'delete-return',
  NAV_BAR = 'nav-bar',
  DATASETS = 'datasets',
  MODELS = 'models'
}

export enum ICON_INTERACTION_CLASSES {
  LIGHT = 'light-icon',
  DARK = 'dark-icon',
  LIGHT_NO_HOVER = 'light-icon-no-hover',
  DARK_NO_HOVER = 'dark-icon-no-hover'
}

export enum MODAL_COMPONENT_TYPES {
  HEADER = 'HEADER',
  BODY = 'BODY',
  FOOTER = 'FOOTER'
}

export enum ACCESS_TYPES {
  ACCOUNT_SHARED = 'account_shared',
  PRIVATE = 'private',
  HIDDEN = 'hidden',
  TEMPORARILY_STORED = 'Temporarily stored'
}

export enum RESTRICTIONS {
  ACCOUNT_MEMBERS = 'account_members',
  SHARED_RESOURCES = 'shared_resources',
  TRAINING_ROWS = 'training_rows',
  TRAINING_COLUMNS = 'training_columns',
  PREDICTION_ROWS = 'prediction_rows',
  PREDICTION_COLUMNS = 'prediction_columns',
  FILE_FORMAT = 'file_format',
  STORED_MODELS_ACCOUNT = 'stored_models_account',
  STORED_MODELS_USER = 'stored_models_user'
}

export enum BACKEND_STRINGS {
  UTTER_UPLOAD_INFERENCE_DATA = 'Please upload the data for the predictions.',
  PRESENT_TIMEOUT_1_BUTTONS = 'PRESENT_TIMEOUT_1_BUTTONS',
  START_ANALYSIS_MENU_OPTION = 'START_ANALYSIS_MENU_OPTION',
  INFERENCE_ASSET_TAG = 'inference'
}

export enum NUMBERS_STRINGS {
  FIRST = 'FIRST',
  SECOND = 'SECOND'
}

export enum PredictStatus {
  FAILED = 'Failed',
  RUNNING = 'Running',
  COMPLETED = 'Completed',
  // TODO: PLAION temporal change, it will be removed when AIZ-1507 is done
  SUCCEEDED = 'Succeeded'
}

export enum ModalSize {
  SMALL = 'dialog-sm',
  MEDIUM = 'dialog-md',
  FULL = 'dialog-full',
  COMPOSED_FULL = 'dialog-composed'
}
