import CustomIcon from 'common/CustomIcon';
import {
  MENU,
  SUB_MENU,
  type IColumnSettingsProps
} from 'featureEngineering/featureEngineeringInterface';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import { CustomSubmenuTooltip } from 'featureEngineering/TransformationSelectedMenu';
import { useRecoilValue } from 'recoil';
import { transformFeatureStateAtom } from 'featureEngineering/atoms/atomTransformFeature';
import { getPreviewModified } from 'featureEngineering/utils/transformationUtils';

interface IColumnSettings extends IColumnSettingsProps {
  typeChange: boolean;
}

const ColumnSettings = ({
  selectedMenu,
  setSelectedMenu,
  updateTransformState,
  typeChange = true
}: IColumnSettings): JSX.Element | null => {
  const transformationState = useRecoilValue(transformFeatureStateAtom);
  const { edited, disregard } = getPreviewModified(
    transformationState,
    selectedMenu.columnData.name
  );

  const disableFilter = (): boolean => {
    return (
      selectedMenu.columnData.data.dataValue === 'id' ||
      selectedMenu.columnData.data.dataValue === 'textual'
    );
  };

  return selectedMenu !== undefined ? (
    <>
      <Box className="transformation-selected">
        <Box>
          <CustomIcon type={selectedMenu.icon} />
          <p>{selectedMenu.text}</p>
        </Box>
        <ChevronLeftIcon
          className="cursor-pointer"
          onClick={() => {
            setSelectedMenu(undefined);
          }}
        />
      </Box>
      <Box
        className={
          edited === false
            ? 'transformation-sub-menu'
            : 'transformation-sub-menu-disabled'
        }
        onClick={() => {
          if (edited === false) {
            updateTransformState(
              selectedMenu.selected,
              SUB_MENU.DISREGARD_COLUMN,
              {
                action: SUB_MENU.DISREGARD_COLUMN.toLowerCase(),
                type: selectedMenu.columnData.data.dataValue,
                column: selectedMenu.columnData.name
              }
            );
          }
        }}
      >
        <p>{disregard !== false ? 'Show column' : 'Disregard column'}</p>
        {CustomSubmenuTooltip(
          'Deactivate this column for any analysis done with using the modified dataset.'
        )}
      </Box>
      <Box
        className={
          typeChange
            ? 'transformation-sub-menu'
            : 'transformation-sub-menu-disabled'
        }
        onClick={() => {
          if (typeChange) {
            setSelectedMenu({
              ...selectedMenu,
              text: 'Change column type',
              selected: MENU.CHANGE_COLUMN
            });
          }
        }}
      >
        <p>Change column type</p>
        {CustomSubmenuTooltip(
          typeChange
            ? 'Modify the type of the column if it has not been correctly assigned automatically.'
            : "This column's type has already been changed once"
        )}
      </Box>
      <Box
        className={
          disableFilter()
            ? 'transformation-sub-menu-disabled'
            : 'transformation-sub-menu'
        }
        onClick={() => {
          setSelectedMenu({
            ...selectedMenu,
            text: 'Filter Variables',
            selected: MENU.FILTER_COLUMN
          });
        }}
      >
        <p>Filter column variables</p>
        {CustomSubmenuTooltip(
          'Disregard variables or ranges from for any analysis done with this modified dataset. Rows with those variables will be deactivated.'
        )}
      </Box>
    </>
  ) : null;
};

export default ColumnSettings;
