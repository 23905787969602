import { Box, Typography } from '@mui/material';
import { TitleTypography } from 'common/TitleTypography';
import React, { useEffect, useRef, useState, type ReactElement } from 'react';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { GAUserEvent } from '../../../utils/utils';
import { generateInput } from '../common/common';

import CustomButton from 'common/button/CustomButton';
import ModalComposed from 'common/modal/ModalComposed';
import 'common/Common.scss';
import './ResetPassword.scss';

const ResetPassword = (): ReactElement => {
  const authenticationService = AuthenticationService.getInstance();
  const [displayMessage, setDisplayMessage] = useState<string>();
  const [token, setToken] = useState<string>();
  const [mode, setMode] = useState<string>();

  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();

  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmRef = useRef<HTMLInputElement>(null);
  const btnResetPassword = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    const mode = searchParams.get('mode');

    if (token !== null && mode !== null) {
      setToken(token);
      setMode(mode);
    }
  }, []);

  useEffect(() => {
    const regexPassword =
      /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;

    if (newPassword !== undefined) {
      if (!regexPassword.test(newPassword)) {
        setDisplayMessage(
          'The password introduced does not meet the requirements. Passwords must have at least 8 characters, one letter uppercase, one letter lowercase, a number and a special character ($, %, &, etc.)'
        );
      } else if (
        confirmNewPassword !== undefined &&
        newPassword !== confirmNewPassword
      ) {
        setDisplayMessage('Passwords do not match');
      } else {
        setDisplayMessage(undefined);
      }
    } else {
      setDisplayMessage(undefined);
    }
  }, [newPassword, confirmNewPassword]);

  const resetPassword = (
    newPassword: string | undefined,
    confirmNewPassword: string | undefined
  ): void => {
    if (
      token !== undefined &&
      newPassword !== undefined &&
      confirmNewPassword !== undefined
    ) {
      authenticationService
        .resetPassword(token, newPassword, confirmNewPassword)
        .then(() => {
          GAUserEvent('RESET_PASSWORD_SUCCESS');
          setToken(undefined);
          // We are using window.location.origin instead of props.onChange('login')
          // because we need to reload the page to clear the query params
          let assistantFrontUrl = `${window.location.origin}`;
          const urlMarketPlace = process.env.REACT_APP_MARKETPLACE_BASE_URL;

          if (mode === '0' && urlMarketPlace !== undefined) {
            assistantFrontUrl = `${urlMarketPlace}`;
          }
          window.location.href = assistantFrontUrl;
        })
        .catch((error) => {
          GAUserEvent('RESET_PASSWORD_FAIL');
          console.error(error);
          setDisplayMessage(
            'Something went wrong, please check your credentials or contact the support team'
          );
        });
    }
  };

  return (
    <ModalComposed
      open={true}
      className="md-modal-wrapper"
      body={
        <Box className="reset-password">
          <TitleTypography className="reset-password__title">
            Reset password
          </TitleTypography>
          <Typography className="reset-password__subtitle">
            Please check your email to continue the process. Don&apos;t forget
            to check your spam/junk folder too.
          </Typography>

          <Box className="reset-password__body">
            <Box className="input-wrapper">
              <Typography className="input-wrapper__title">
                New Password
              </Typography>
              {generateInput(
                'password',
                'Password',
                (e: string) => {
                  setNewPassword(e);
                },
                passwordRef,
                (e: React.KeyboardEvent<HTMLDivElement>) => {
                  e.preventDefault();

                  if (confirmRef.current !== null) {
                    confirmRef.current.focus();
                  }
                }
              )}
            </Box>
            <Box className="input-wrapper">
              <Typography className="input-wrapper__title">
                Confirm New Password
              </Typography>
              {generateInput(
                'password',
                'Confirm password',
                (e: string) => {
                  setConfirmNewPassword(e);
                },
                confirmRef,
                (e: React.KeyboardEvent<HTMLDivElement>) => {
                  e.preventDefault();

                  if (
                    btnResetPassword.current !== null &&
                    !btnResetPassword.current.disabled
                  ) {
                    btnResetPassword.current.focus();
                  } else if (passwordRef.current !== null) {
                    passwordRef.current.focus();
                  }
                }
              )}
              {displayMessage !== undefined && (
                <Typography className="reset-password-error-message">
                  {displayMessage}
                </Typography>
              )}
            </Box>
            <Box className="button-wrapper">
              <CustomButton
                customRef={btnResetPassword}
                disabled={
                  !(
                    newPassword !== undefined &&
                    confirmNewPassword !== undefined
                  ) || displayMessage !== undefined
                }
                onClick={() => {
                  resetPassword(newPassword, confirmNewPassword);
                }}
                style={{ maxWidth: 'none' }}
              >
                {'Save new password'}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default ResetPassword;
