import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { loadingJobAtom, scenarioAtom } from 'playground/atoms/atomPlayground';
import { useEffect, type ReactElement } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  FeatureType,
  ScenarioSelectors,
  type Feature as FeatureInterface
} from '../../interfaces/playground';
import './Feature.scss';

interface FeatureProps extends FeatureInterface {
  name: string;
  categoryVariants: string[] | null;
  value: string | dayjs.Dayjs;
  updateFeatureValues: (feature: string, value: string) => void;
}

const Feature = ({
  name,
  type,
  categoryVariants,
  updateFeatureValues,
  value,
  scenario
}: FeatureProps): ReactElement => {
  const disabled = useRecoilValue(loadingJobAtom);
  const [scenarioValues, setScenarioValues] = useRecoilState(scenarioAtom);

  useEffect(() => {
    if (scenarioValues.update) {
      setScenarioValues({ ...scenarioValues, update: false });
    }
  }, [scenarioValues.update]);

  const setType = (type: string): string => {
    switch (type) {
      case FeatureType.NUMERICAL:
        return 'number';
      case FeatureType.TEXTUAL:
        return 'text';
      case FeatureType.DATETIME:
        return 'date';
      default:
        return 'text';
    }
  };

  const scenarioMatch = (value: string | dayjs.Dayjs, type: string): string => {
    const bestCaseClass = 'feature-best';
    const worstCaseClass = 'feature-worst';
    let min;
    let max;
    const numericalValue = parseFloat(value as string);
    switch (type) {
      case FeatureType.CATEGORICAL:
      case FeatureType.BINARY_CATEGORICAL:
      case FeatureType.TEXTUAL:
        if (value === scenario.bestCase) {
          return bestCaseClass;
        } else if (value === scenario.worstCase) {
          return worstCaseClass;
        } else {
          return '';
        }
      case FeatureType.NUMERICAL:
        if (scenario.bestCase.length === 2 && scenario.worstCase.length === 2) {
          min = scenario.bestCase[0] as number;
          max = scenario.bestCase[1] as number;
          if (numericalValue >= min && numericalValue <= max) {
            return bestCaseClass;
          }
          min = scenario.worstCase[0] as number;
          max = scenario.worstCase[1] as number;
          if (numericalValue >= min && numericalValue <= max) {
            return worstCaseClass;
          }
          return '';
        } else if (
          Array.isArray(scenario.bestCase) ||
          Array.isArray(scenario.worstCase)
        ) {
          min = scenario.bestCase[0] as number;
          if (numericalValue === min) {
            return bestCaseClass;
          }
          min = scenario.worstCase[0] as number;
          if (numericalValue === min) {
            return worstCaseClass;
          }
          return '';
        } else {
          return '';
        }
      case FeatureType.DATETIME:
        if (dayjs(value) === dayjs(scenario.bestCase as string)) {
          return bestCaseClass;
        } else if (dayjs(value) === dayjs(scenario.worstCase as string)) {
          return worstCaseClass;
        } else {
          return '';
        }
      default:
        return '';
    }
  };

  const renderScenario = (): string => {
    if (scenarioValues.scenario === ScenarioSelectors.BEST) {
      if (scenario.bestCase.length === 0) return '';
      if (typeof scenario.bestCase === 'string') {
        return `Best: ${scenario.bestCase}`;
      } else if (scenario.bestCase.length === 2) {
        return `Best: ${scenario.bestCase[0]} - ${scenario.bestCase[1]}`;
      } else {
        return `Best: ${scenario.bestCase[0]}`;
      }
    } else if (scenarioValues.scenario === ScenarioSelectors.WORST) {
      if (scenario.worstCase.length === 0) return '';
      if (typeof scenario.worstCase === 'string') {
        return `Worst: ${scenario.worstCase}`;
      } else if (scenario.worstCase.length === 2) {
        return `Worst: ${scenario.worstCase[0]} - ${scenario.worstCase[1]}`;
      } else {
        return `Worst: ${scenario.worstCase[0]}`;
      }
    } else {
      return '';
    }
  };

  return (
    <Box className="feature-item">
      <InputLabel htmlFor={`input-${name}`}>{name}</InputLabel>
      {categoryVariants !== null ? (
        <Select
          id={`input-${name}`}
          name={name}
          label={name}
          value={value}
          disabled={disabled}
          className={scenarioMatch(value, type)}
        >
          {categoryVariants.map((option: string, index: number) => {
            return (
              <MenuItem
                key={index}
                value={option}
                onClick={() => {
                  updateFeatureValues(name, option);
                }}
              >
                {option}
              </MenuItem>
            );
          })}
        </Select>
      ) : type === FeatureType.DATETIME ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={`feature-item date-picker ${scenarioMatch(value, type)}`}
            slotProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
            value={value === '' ? null : value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: any) => {
              updateFeatureValues(name, event);
            }}
          />
        </LocalizationProvider>
      ) : (
        <TextField
          className={`feature-item ${scenarioMatch(value, type)}`}
          id={`input-${name}`}
          name={name}
          type={setType(type)}
          value={value}
          onChange={(event) => {
            updateFeatureValues(name, event.target.value);
          }}
          disabled={disabled}
        />
      )}
      <Typography
        variant="caption"
        className="feature-type"
        style={{ fontSize: '14px', color: '#667085' }}
      >
        {renderScenario()}
      </Typography>
    </Box>
  );
};

export default Feature;
