import { CustomSubmenuTooltip } from 'featureEngineering/TransformationSelectedMenu';
import { LinearProgress, Typography } from '@mui/material';
import './MergeColumnDetail.scss';

interface MergeColumnDetailProps {
  title: string;
  label: string;
  tooltipText: string;
  reach: number;
  progress: number;
  color: string;
}

const MergeColumnDetail = ({
  title,
  label,
  tooltipText,
  reach,
  progress,
  color
}: MergeColumnDetailProps): JSX.Element => {
  return (
    <div
      className="column-detail-wrapp"
      style={
        Boolean(color) && typeof color === 'string'
          ? { borderColor: color }
          : {}
      }
    >
      <div>
        <Typography className="ft-bold">{title}</Typography>
        {CustomSubmenuTooltip(tooltipText)}
      </div>
      {label}
      <div className="column-detail-progress">
        {progress}
        <div style={{ color }}>
          <LinearProgress
            variant="determinate"
            value={(progress * 100) / reach}
            color="inherit"
          />
        </div>
      </div>
    </div>
  );
};

export default MergeColumnDetail;
