import Box from '@mui/material/Box';
import CustomButton from 'common/button/CustomButton';
import { ICON_TYPE, ModalSize } from 'common/interfaces/enums';
import { useEffect, useState, type ReactElement } from 'react';
import ModalComposed from 'common/modal/ModalComposed';
import './Image.scss';

interface ImageProps {
  open?: boolean;
  options?: {
    image_url?: string;
  };
  closeModal?: () => void;
  onlyModal?: boolean;
}

/**
 * The any appear s because the Image are used in Chat but not initialized
 * on the default form
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Image = (props: ImageProps | any): ReactElement => {
  const [open, setOpen] = useState<boolean>(props.open ?? false);
  const [background, setBackground] = useState<string>('#ffffff');
  const [backgroundButton, setBackgroundButton] = useState<string>('#565658');

  useEffect(() => {
    if (props?.options?.image_url?.includes('.svg') === true) {
      setBackground('#565658');
      setBackgroundButton('#ffffff');
    }
  }, [props.options]);

  useEffect(() => {
    if (props?.closeModal !== undefined && !open) {
      props.closeModal(false);
    }
  }, [open]);

  const generateModal = (): JSX.Element => {
    const headerComponent = (
      <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
        <CustomButton
          variant="tertiary"
          onClick={() => {
            setOpen(false);
          }}
          icon={{
            type: ICON_TYPE.CLOSE,
            position: 'left'
          }}
          style={{
            color: backgroundButton
          }}
        />
      </div>
    );

    const bodyComponent = (
      <Box>
        {props?.options?.image_url !== undefined ? (
          <div
            style={{
              width: '96%',
              margin: '2%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img src={props?.options.image_url} className="imgSize" />
          </div>
        ) : null}
      </Box>
    );
    return (
      <ModalComposed
        open={open}
        id="image-box"
        size={ModalSize.FULL}
        style={{ background }}
        header={headerComponent}
        body={bodyComponent}
        onClose={() => {
          setOpen(false);
        }}
      />
    );
  };

  return (
    <>
      {props.onlyModal === undefined ? (
        <>
          {props.options?.image_url !== undefined && (
            <Box style={{ maxWidth: '100%', borderRadius: '16px' }}>
              <img
                onClick={() => {
                  setOpen(true);
                }}
                src={props?.options.image_url}
                style={{
                  backgroundColor: 'white',
                  maxWidth: '100%',
                  width: '90%',
                  padding: '5%',
                  cursor: 'pointer',
                  borderRadius: '16px'
                }}
              />
            </Box>
          )}
          {open !== undefined && background !== undefined && generateModal()}
        </>
      ) : (
        generateModal()
      )}
    </>
  );
};

export default Image;
