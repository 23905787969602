import { Box } from '@mui/material';
import { TEXTUAL_GROUPING_TYPE } from 'featureEngineering/featureEngineeringInterface';

const TextualAgrupationExamples = (
  exampleType = TEXTUAL_GROUPING_TYPE.WORDS
): JSX.Element => {
  if (exampleType === TEXTUAL_GROUPING_TYPE.LETTERS) {
    return (
      <Box>
        <p>
          <strong>Values:</strong> bmw x1, bmw x3, bmw-z2, bmw x3
        </p>
        <p>
          <strong>Inputs:</strong> 2 + Last + Letters
        </p>
        <p>
          <strong>Output:</strong> x1, x3, z2, x3
        </p>
      </Box>
    );
  }
  return (
    <Box>
      <p>
        <strong>Values:</strong> Mr John, Mrs Mary, Mss Maria, Mr Smith
      </p>
      <p>
        <strong>Inputs:</strong> 1 + First + Words
      </p>
      <p>
        <strong>Output:</strong> Mr, Mrs, Mss
      </p>
    </Box>
  );
};

export default TextualAgrupationExamples;
