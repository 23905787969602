import { type Payload } from 'playground/interfaces/playground';
import io, { type Socket } from 'socket.io-client';
export class AssistantApiSocketClient {
  static instance: AssistantApiSocketClient;
  private readonly socket: Socket;
  private readonly baseUrl: string;

  public static getInstance(): AssistantApiSocketClient {
    if (AssistantApiSocketClient.instance === undefined) {
      AssistantApiSocketClient.instance = new AssistantApiSocketClient();
    }
    return AssistantApiSocketClient.instance;
  }

  constructor() {
    this.baseUrl = String(process.env.REACT_APP_MAIN_SERVICE_BASE_URL);
    this.socket = io(this.baseUrl, {
      path:
        String(process.env.REACT_APP_MAIN_SERVICE_PREFIX_URI) +
        '/assistant/socket.io'
    });
  }

  public setAuth(token: string): void {
    this.socket.io.opts.extraHeaders = {
      Authorization: 'Bearer ' + token
    };
    this.socket.disconnect();
    this.socket.connect();
  }

  public addIsConnectedListener(callback: () => void = () => undefined): void {
    this.socket.on('connect', () => {
      callback();
    });
  }

  public addIsDisconnectedListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (msg: any) => any = (msg: any) => undefined
  ): void {
    this.socket.on('disconnect', callback);
  }

  public removeIsDisconnectedListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (msg: any) => any = (msg: any) => undefined
  ): void {
    this.socket.off('disconnect', callback);
  }

  public addInteractiveMessageListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (msg: any) => any = (msg: any) => undefined
  ): void {
    this.socket.on('interactivemsg', callback);
    this.socket.on('interactivemessage', callback);
  }

  public removeInteractiveMessageListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (msg: any) => any = (msg: any) => undefined
  ): void {
    this.socket.off('interactivemsg', callback);
    this.socket.off('interactivemessage', callback);
  }

  public isConnected(): boolean {
    return this.socket.connected;
  }

  public socketId(): string {
    return this.socket.id ?? '';
  }

  public getSocket(): Socket {
    return this.socket;
  }

  public sendInteractiveMsg(payload: Payload): void {
    this.socket.emit('interactivemsg', { payload });
  }

  public sendMessage(payload: Payload): void {
    this.socket.emit('interactivemessage', { payload });
  }

  public sendGrantAccess(payload: Payload): void {
    this.socket.emit('grant_access', { payload });
  }

  public sendConnectionInfo(payload: Payload): void {
    console.log('Sending connection_info', payload);
    this.socket.emit('connection_info', payload);
  }

  public ackMessage(payload: Payload): void {
    console.log('Sending ack_message', payload);
    this.socket.emit('ack_message', payload);
  }
}
