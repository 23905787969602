import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import Input from 'common/Input';
import CustomButton from 'common/button/CustomButton';
import type {
  NumericalTransformationRange,
  SendTransformation
} from 'common/interfaces/interfaces';
import {
  MENU,
  SUB_MENU,
  type IColumnSettingsProps
} from 'featureEngineering/featureEngineeringInterface';
import { useEffect, useState } from 'react';
import './../Transformation.scss';

interface CategoricalCheckbox {
  category: string;
  check: boolean;
}

const FilterSettings = ({
  selectedMenu,
  setSelectedMenu,
  updateTransformState
}: IColumnSettingsProps): JSX.Element | null => {
  const defaultNumericalValues: NumericalTransformationRange = {
    min: 0,
    max: 0
  };
  const [categoricalValues, setCategoricalValues] = useState<
    CategoricalCheckbox[]
  >([]);
  const [numericalValues, setNumericalValues] =
    useState<NumericalTransformationRange>(defaultNumericalValues);

  const [minMaxValues, setMinMaxValues] =
    useState<NumericalTransformationRange>(defaultNumericalValues);

  const [isNumerical, setIsNumerical] = useState<boolean>(false);

  useEffect(() => {
    const dataValue = selectedMenu?.columnData?.data?.dataValue;
    if (dataValue === 'categorical' || dataValue === 'binary categorical') {
      setCategoricalValues(
        buildCategoricalCheckbox(
          selectedMenu?.columnData?.data?.sampleData as string[]
        )
      );
    } else {
      setIsNumerical(true);
      const sampleData = selectedMenu.columnData.data
        .sampleData as NumericalTransformationRange;
      setMinMaxValues({
        min: sampleData.min as number,
        max: sampleData.max as number
      });
    }
  }, [selectedMenu]);

  const buildCategoricalCheckbox = (
    values: string[]
  ): CategoricalCheckbox[] => {
    const categoricalObject = values.map((value) => {
      return { category: value, check: true };
    });
    return categoricalObject;
  };

  const updateCheck =
    (index: number) =>
    (event: React.SyntheticEvent<Element, Event>): void => {
      const newCategoricalValues = [...categoricalValues];
      const target = event.target as EventTarget & HTMLInputElement;
      newCategoricalValues[index].check = target.checked;
      setCategoricalValues(newCategoricalValues);
    };

  const saveChanges = (): void => {
    let newValues;

    if (isNumerical) {
      newValues = [numericalValues.min, numericalValues.max];
    } else {
      newValues = categoricalValues
        .filter((value) => value.check)
        .map((value) => value.category);
    }
    const values: SendTransformation = {
      action: MENU.FILTER_COLUMN.toLowerCase(),
      column: selectedMenu.columnData.name,
      type: selectedMenu.columnData.data.dataValue,
      params: {
        keep_values: newValues
      }
    };
    updateTransformState(selectedMenu.selected, SUB_MENU.FILTER_COLUMN, values);
    setCategoricalValues([]);
    setNumericalValues(defaultNumericalValues);
    setIsNumerical(false);
    setMinMaxValues(defaultNumericalValues);
    setSelectedMenu(undefined);
  };

  const validateSave = (): boolean => {
    if (isNumerical) {
      return !(numericalValues.min !== 0 || numericalValues.max !== 0);
    }
    return categoricalValues.find((value) => !value.check) === undefined;
  };

  return (
    <>
      <Box className="transformation-selected">
        <Box>
          <CustomIcon type={selectedMenu.icon} />
          <p>{selectedMenu.text}</p>
        </Box>
        <ChevronLeftIcon
          className="cursor-pointer"
          onClick={() => {
            setSelectedMenu(undefined);
          }}
        />
      </Box>
      <Box className="transformation-filtering">
        {isNumerical ? (
          <Box className="transformation-filtering-numerical">
            <Divider orientation="horizontal" flexItem />
            <p>Select the range of values</p>
            <Box className="display-row" style={{ gap: '15px' }}>
              <Box className="display-column">
                <Input
                  type="number"
                  id="filtering-show-only-min"
                  placeholder="Min"
                  value={numericalValues.min}
                  minValue={minMaxValues.min as number}
                  maxValue={minMaxValues.max as number}
                  onChange={(value: string) => {
                    setNumericalValues({
                      min: Number(value),
                      max: numericalValues.max
                    });
                  }}
                />
                <p className="input-bottom-label">Min {minMaxValues.min}</p>
              </Box>
              <Box className="display-column">
                <Input
                  type="number"
                  id="filtering-show-only-max"
                  placeholder="Max"
                  value={numericalValues.max}
                  minValue={minMaxValues.min as number}
                  maxValue={minMaxValues.max as number}
                  onChange={(value: string) => {
                    setNumericalValues({
                      min: numericalValues.min,
                      max: Number(value)
                    });
                  }}
                />
                <p className="input-bottom-label">Max {minMaxValues.max}</p>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="transformation-filtering-categorical">
            <Divider orientation="horizontal" flexItem />
            <FormGroup>
              {categoricalValues.map((value, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="filter-checkbox"
                        checked={value.check}
                      />
                    }
                    label={value.category}
                    key={index}
                    onChange={updateCheck(index)}
                  />
                );
              })}
            </FormGroup>
            <Box className="filtering-categorical-button-selection">
              <CustomButton
                onClick={() => {
                  const newCategoricalValues = [...categoricalValues];
                  newCategoricalValues.forEach((value) => {
                    value.check = true;
                  });
                  setCategoricalValues(newCategoricalValues);
                }}
              >
                Select All
              </CustomButton>
              <CustomButton
                variant="secondary"
                onClick={() => {
                  const newCategoricalValues = [...categoricalValues];
                  newCategoricalValues.forEach((value) => {
                    value.check = false;
                  });
                  setCategoricalValues(newCategoricalValues);
                }}
              >
                Deselect All
              </CustomButton>
            </Box>
          </Box>
        )}
        <Divider
          orientation="horizontal"
          flexItem
          style={{ width: '88%', marginLeft: '6%' }}
        />
      </Box>
      <CustomButton
        data-cy="transformation-modal-save-button"
        disabled={validateSave()}
        onClick={saveChanges}
        style={{ maxWidth: 'none', width: '90%', margin: '10px' }}
      >
        Save
      </CustomButton>
    </>
  );
};

export default FilterSettings;
