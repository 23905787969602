import React, {
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type ReactElement,
  type Ref
} from 'react';

import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  Typography,
  type TypographyProps
} from '@mui/material';

import { ArrowBack } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

import { type BotComponentSubmit } from 'chat/Chat';
import { USER_MESSAGE } from 'chat/interfaces/enums';
import { type AddNewMessageType } from 'chat/interfaces/messages';
import {
  BACKEND_STRINGS,
  HOME_VIEW_TYPES,
  ICON_TYPE
} from 'common/interfaces/enums';

import { AssistantApiService } from 'chat/services/AssistantApiService';
import { ExposeService } from 'services/ExposeService';
import { uploadFile } from 'services/UploadService';
import { EXTENSIONS_FILES, type EXTENSION_FILE } from './extensionFiles';

import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { lastResponseAtom } from 'chat/atoms/atomLastResponse';
import CustomIcon from 'common/CustomIcon';
import CustomButton from 'common/button/CustomButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  REGEX_HTTP,
  formatBytes,
  lastMessageIsInference,
  sanitizeFilename
} from 'utils/utils';
import InputSend from '../InputSend';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';

const ButtonTypograpy = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'ft-system-regular',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.02em',
  color: 'inherit',
  width: '100%',
  userSelect: 'none',
  textAlign: 'center'
}));

interface PreparingDataProps extends BotComponentSubmit {
  updateTitle: (title: string) => void;
  collapseIsland: () => void;
}

const PreparingData = ({
  updateTitle,
  collapseIsland,
  addNewMessages
}: PreparingDataProps): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const [activateLink, setActivateLink] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [userInferenceDatasetsCount, setUserInferenceDatasetsCount] =
    useState<number>(-1);

  const [acceptedExtensions, setAcceptedExtensions] = useState<string>();
  const assistantApiService = AssistantApiService.getInstance();
  const exposeService = ExposeService.getInstance();

  const inputRef: Ref<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (
      lastMessageIsInference(lastResponse) &&
      userInferenceDatasetsCount === -1
    ) {
      getUserInferenceDatasetsCount();
    }
    if (acceptedExtensions !== undefined) {
      let newAcceptedExtensions = '';
      EXTENSIONS_FILES.forEach(
        (extension: EXTENSION_FILE) =>
          (newAcceptedExtensions += extension.app + ', ')
      );
      setAcceptedExtensions(newAcceptedExtensions);
    }
  }, [acceptedExtensions]);

  const submitFile = (file: File): void => {
    // TODO: ideally the island shouldn't be collapsed (but buttons disabled)
    // until the file is uploaded, that way we can show an error message
    // if anything goes wrong and let the user retry the upload.
    collapseIsland();
    const renamedFile = sanitizeFilename(file);
    uploadFile(renamedFile)
      .then((signedUrl) => {
        const message: AddNewMessageType = {
          value: formatBytes(renamedFile.size),
          valueObject: renamedFile,
          title: renamedFile.name
        };
        addNewMessages(message, USER_MESSAGE.MESSAGE, true);
        assistantApiService.sendFileLink(signedUrl.uri);
      })
      .catch((error) => {
        return error;
      });
  };

  const submitLink = (link: string): void => {
    collapseIsland();
    addNewMessages(link, USER_MESSAGE.FILE, true);
    assistantApiService.sendFileLink(link);
  };

  const handleClick = (): void => {
    if (inputRef?.current !== null) {
      inputRef.current.click();
    }
  };

  const showErrorAlert = (): void => {
    setErrorAlert(true);
    setTimeout(() => {
      setErrorAlert(false);
    }, 5000);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.target;
    const fileObj = target.files !== null ? target.files[0] : undefined;
    if (fileObj === undefined) {
      return;
    }
    const typeFile = EXTENSIONS_FILES.find(
      (extension: EXTENSION_FILE) => extension.app === fileObj.type
    );
    if (typeFile !== undefined) {
      submitFile(fileObj);
    } else {
      showErrorAlert();
    }
  };

  const getUserInferenceDatasetsCount = (): void => {
    void exposeService
      .getUserDatasets(1, 0, [BACKEND_STRINGS.INFERENCE_ASSET_TAG])
      .then((response) => {
        if (response !== undefined) {
          setUserInferenceDatasetsCount(response.collectionSize);
        }
      });
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          {activateLink && (
            <IconButton
              style={{ color: '#101828', marginLeft: '-10px' }}
              onClick={(e) => {
                setActivateLink(false);
                updateTitle('Select an option');
              }}
            >
              <ArrowBack />
            </IconButton>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20px',
            margin: '10px 0',
            width: '100%'
          }}
        >
          {!activateLink && (
            <React.Fragment>
              <CustomButton
                variant="outlined"
                style={{ maxWidth: 'none' }}
                onClick={() => {
                  setActivateLink(true);
                  updateTitle('Link to your data');
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <CustomIcon
                    type={ICON_TYPE.LINK03}
                    style={{
                      transform: 'scale(1.5)',
                      marginTop: '10px'
                    }}
                  />
                  <ButtonTypograpy
                    style={{
                      marginTop: '30px',
                      fontWeight: 'bold'
                    }}
                  >
                    Link
                  </ButtonTypograpy>
                </div>
              </CustomButton>
              <input
                style={{ display: 'none' }}
                ref={inputRef}
                type="file"
                accept={acceptedExtensions}
                onChange={handleFileChange}
              />
              <CustomButton
                variant="outlined"
                style={{ maxWidth: 'none' }}
                onClick={() => {
                  handleClick();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <CustomIcon
                    type={ICON_TYPE.UPLOAD_CLOUD_01}
                    style={{
                      transform: 'scale(1.7)',
                      marginTop: '10px'
                    }}
                  />
                  <ButtonTypograpy
                    style={{
                      marginTop: '30px',
                      fontWeight: 'bold'
                    }}
                  >
                    Upload
                  </ButtonTypograpy>
                </div>
                <input type="file" hidden />
              </CustomButton>
              {/* Show My Space if user is not selecting a dataset to generate predictions
               or if that's the case, show it if the user has at least one inference dataset */}
              {!lastMessageIsInference(lastResponse) ||
              userInferenceDatasetsCount > 0 ? (
                <CustomButton
                  variant="outlined"
                  style={{ maxWidth: 'none' }}
                  onClick={() => {
                    setUserLocationVariable({
                      ...userLocationVariable,
                      current: `${userLocationVariable.current}_${USER_TRACKING_LOCATION_NAMES.MYSPACE}`,
                      previous: userLocationVariable.current
                    });
                    setViewRender({
                      type: HOME_VIEW_TYPES.LOAD_DATASETS,
                      stored: viewRender.stored
                    });
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <CustomIcon
                      type={ICON_TYPE.OPEN_MY_SPACE}
                      style={{
                        transform: 'scale(1.5)',
                        marginTop: '15px'
                      }}
                    />
                    <ButtonTypograpy
                      style={{
                        marginTop: '20px',
                        fontWeight: 'bold'
                      }}
                    >
                      Select from my space
                    </ButtonTypograpy>
                  </div>
                </CustomButton>
              ) : null}
            </React.Fragment>
          )}
          {activateLink && (
            <InputSend
              collapseIsland={collapseIsland}
              addNewMessages={addNewMessages}
              regex={REGEX_HTTP}
              submitLink={submitLink}
            />
          )}
        </div>
      </div>
      <Snackbar open={errorAlert} style={{ width: '80%' }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          <AlertTitle>Error</AlertTitle>
          This file is <strong>invalid!</strong>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default PreparingData;
