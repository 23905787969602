import { type ReactElement } from 'react';
import './App.scss';
import Home from './home/Home';
import { ThemeProvider } from '@mui/material';
import { theme } from 'themes/theme';
import { RecoilRoot } from 'recoil';

const App = (): ReactElement => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        zIndex: 0
      }}
      className="App"
    >
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <Home />
        </ThemeProvider>
      </RecoilRoot>
    </div>
  );
};

export default App;
