import { atom } from 'recoil';

export type ContextSelection = Array<{
  name: string;
  description: string;
  checked: boolean;
}>;

export const contextSelectionAtom = atom<ContextSelection>({
  key: 'contextSelectionAtom',
  default: []
});
