import { Box } from '@mui/material';
import type { ReactElement } from 'react';

import 'common/Common.scss';
import './Stepper.scss';

const Stepper = ({
  active,
  elements
}: {
  active: number;
  elements: string[];
}): ReactElement => {
  return (
    <Box className="stepper-process">
      {elements.map((element, index) => (
        <Box className="stepper-process-2" key={`stepper-${index}`}>
          <Box className={`stepper-inner ${active === index ? 'active' : ''}`}>
            {index + 1}
          </Box>
          {element}
          {index !== elements.length - 1 && <span />}
        </Box>
      ))}
    </Box>
  );
};

export default Stepper;
