import { Box, Tooltip } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { ICON_TYPE } from 'common/interfaces/enums';
import type { DatasetSchema, TableData } from 'common/interfaces/interfaces';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ChangeColumnType from './SubMenus/ChangeColumnType';
import ColumnSettings from './SubMenus/ColumnSettings';
import DataTransformation from './SubMenus/DataTransformation';
import {
  activeTransformViewAtom,
  transformFeatureStateAtom
} from './atoms/atomTransformFeature';
import {
  MENU,
  SUB_MENU,
  TRANSFORM_COLUMN,
  type FeatureState,
  type FeatureSubMenu,
  type SelectedMenu,
  type SelectedTransformationColumn,
  type TransformDataBySubMenuParameters
} from './featureEngineeringInterface';
import {
  getPreviewModified,
  modifyOriginalPreviewDatas,
  transformAction,
  transformPreviewData,
  transformRowsPreviewData
} from './utils/transformationUtils';

import {
  USER_TRACKING_FENG_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import 'common/Common.scss';
import { GAUserEvent } from 'utils/utils';
import FilterSettings from './SubMenus/FilterSettings';
import GroupCategories from './SubMenus/groupingCategories/GroupCategories';
import './Transformation.scss';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';

export const CustomSubmenuTooltip = (
  text = 'This is just a tooltip example where some info will appear'
): JSX.Element => (
  <Tooltip
    title={
      <Box className="tooltip" sx={{ width: '175px', padding: '6px' }}>
        {text}
      </Box>
    }
    placement="bottom"
    arrow
  >
    <div>
      <CustomIcon
        className="grey-icon-no-hover"
        style={{
          justifyContent: 'center',
          paddingLeft: '5px',
          width: '15px'
        }}
        type={ICON_TYPE.INFO_CIRCLE}
      />
    </div>
  </Tooltip>
);

interface TransformationSelectedMenuProps {
  previewController: [
    DatasetSchema[] | undefined,
    Dispatch<SetStateAction<DatasetSchema[] | undefined>>,
    DatasetSchema[] | undefined
  ];
  rowsController: [
    TableData | undefined,
    Dispatch<SetStateAction<TableData | undefined>>,
    TableData | undefined
  ];
  selectedColumnController: {
    selectedTransform: SelectedTransformationColumn;
    setSelectedTransform: Dispatch<
      SetStateAction<SelectedTransformationColumn | undefined>
    >;
  };
}

const TransformationSelectedMenu = ({
  previewController,
  rowsController,
  selectedColumnController
}: TransformationSelectedMenuProps): JSX.Element => {
  const { selectedTransform, setSelectedTransform } = selectedColumnController;
  const [transformationState, setTransformState] = useRecoilState(
    transformFeatureStateAtom
  );
  const viewRender = useRecoilValue(homeViewRenderAtom);

  const setActiveTransformView = useSetRecoilState(activeTransformViewAtom);
  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const [selectedMenu, setSelectedMenu] = useState<SelectedMenu>();
  const [disregardedColumns, setDisregardedColumns] = useState<string[]>([]);

  const [preview, setPreview, originalPreview] = previewController;
  const [rowsPreview, setRowsPreview, originalRows] = rowsController;

  const selectedColumn = selectedTransform.name;
  const columnIsModified = transformationState[selectedColumn];
  const { added } = getPreviewModified(transformationState, selectedColumn);
  const allowTypeChange =
    columnIsModified?.CHANGE_COLUMN?.CHANGE_COLUMN_TYPE === undefined;

  let selectedMenuKey: FeatureSubMenu | undefined;
  if (
    columnIsModified !== undefined &&
    selectedMenu !== undefined &&
    columnIsModified[selectedMenu.selected] !== undefined
  ) {
    selectedMenuKey = columnIsModified[selectedMenu.selected];
  }

  useEffect(() => {
    if (selectedMenu !== undefined) {
      setSelectedMenu(undefined);
    }
  }, [selectedTransform]);

  const selectedTransformTypeBuild = (
    newSchema: DatasetSchema
  ): SelectedTransformationColumn => ({
    index: selectedTransform.index,
    name: newSchema.name,
    type: newSchema.type,
    data: {
      dataValue: newSchema.dataValue,
      sampleData: newSchema.sampleData
    }
  });

  const addTransformDataBySubMenu: TransformDataBySubMenuParameters = (
    transformMenuKey,
    transformSubMenuKey,
    values,
    newSchema
  ): void => {
    switch (transformSubMenuKey) {
      case SUB_MENU.DISREGARD_COLUMN: {
        if (values !== undefined) {
          const newTransform = transformAction(
            transformationState,
            selectedColumn,
            transformMenuKey,
            transformSubMenuKey,
            values
          )(true);
          setTransformState(newTransform);
          GAUserEvent(
            `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.DISREGARD}`
          );
        }
        break;
      }
      case SUB_MENU.ADD_GROUP_CATEGORIES: {
        modifyOriginalPreviewDatas(
          transformationState,
          { preview, setPreview },
          { rowsPreview, setRowsPreview },
          selectedTransform,
          values,
          transformSubMenuKey
        );

        if (
          transformSubMenuKey === SUB_MENU.ADD_GROUP_CATEGORIES &&
          values?.params?.new_column_name !== undefined
        ) {
          const newTransform = transformAction(
            transformationState,
            values.params.new_column_name,
            transformMenuKey,
            transformSubMenuKey,
            values
          )(false);
          setTransformState(newTransform);
        } else if (values !== undefined) {
          const newTransform = transformAction(
            transformationState,
            selectedColumn,
            transformMenuKey,
            transformSubMenuKey,
            values
          )(false);
          setTransformState(newTransform);
        }
        GAUserEvent(
          `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.GROUPING}`
        );
        setSelectedMenu(undefined);
        break;
      }
      case SUB_MENU.CHANGE_COLUMN: {
        if (values !== undefined) {
          const newTransform = transformAction(
            transformationState,
            selectedColumn,
            transformMenuKey,
            transformSubMenuKey,
            values
          )(false);
          let buildedSelection = selectedTransform;
          if (newSchema !== undefined) {
            buildedSelection = selectedTransformTypeBuild(newSchema);
            setSelectedTransform({
              ...selectedTransform,
              ...buildedSelection
            });
          }
          modifyOriginalPreviewDatas(
            newTransform,
            { preview, setPreview },
            { rowsPreview, setRowsPreview },
            buildedSelection,
            values,
            transformSubMenuKey
          );
          setTransformState(newTransform);
          GAUserEvent(
            `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.CHANGE_TYPE}`
          );
        }
        break;
      }
      case SUB_MENU.FILTER_COLUMN: {
        modifyOriginalPreviewDatas(
          transformationState,
          { preview, setPreview },
          { rowsPreview, setRowsPreview },
          selectedTransform,
          values,
          transformSubMenuKey
        );
        if (values !== undefined) {
          const newTransform = transformAction(
            transformationState,
            selectedColumn,
            transformMenuKey,
            transformSubMenuKey,
            values
          )(false);
          setTransformState(newTransform);
          GAUserEvent(
            `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.FILTER}`
          );
        }
      }
    }

    if (transformSubMenuKey === SUB_MENU.DISREGARD_COLUMN) {
      if (disregardedColumns.includes(selectedColumn)) {
        const disIndex = disregardedColumns.indexOf(selectedColumn);
        setDisregardedColumns([
          ...disregardedColumns.slice(0, disIndex),
          ...disregardedColumns.slice(disIndex + 1)
        ]);
      } else {
        setDisregardedColumns([...disregardedColumns, selectedColumn]);
      }
    }
  };

  const removeColumnGroup = (): void => {
    const columnData = transformationState[selectedColumn];
    if (columnData !== undefined) {
      const validationParameters = [
        preview,
        originalPreview,
        rowsPreview,
        originalRows
      ];
      const transformation: FeatureState = {};
      for (const key in transformationState) {
        if (selectedColumn !== key) {
          const element = transformationState[key];
          transformation[key] = element;
        } else if (validationParameters.every((param) => param !== undefined)) {
          let { added, edited } = getPreviewModified(
            transformationState,
            selectedColumn
          );
          added = Boolean(added);
          const isEdited = typeof edited === 'object' && 'action' in edited;
          if (added) {
            setPreview(
              transformPreviewData(
                transformationState,
                preview as DatasetSchema[],
                selectedTransform,
                undefined,
                true,
                {
                  originalPreview
                }
              )
            );
            setRowsPreview(
              transformRowsPreviewData(
                rowsPreview as TableData,
                selectedTransform,
                undefined,
                true,
                {
                  originalRows
                }
              )
            );
            setActiveTransformView(TRANSFORM_COLUMN.DEFAULT);
          } else if (isEdited) {
            // The edited exception is only by change column and is only affecting the preview
            setPreview(
              transformPreviewData(
                transformationState,
                preview as DatasetSchema[],
                selectedTransform,
                undefined,
                false,
                {
                  originalPreview
                }
              )
            );
            const buildedSelection = selectedTransformTypeBuild(
              (originalPreview as DatasetSchema[])[selectedTransform.index]
            );
            setSelectedTransform({
              ...selectedTransform,
              ...buildedSelection
            });
          }
          setSelectedMenu(undefined);
        }
      }
      setTransformState(transformation);
    }
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.REVERT}`
    );
  };

  const selectedMenuHasKey = (): JSX.Element | null => {
    switch (selectedMenu?.selected) {
      case MENU.COLUMN_SETTINGS:
        return (
          <ColumnSettings
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            updateTransformState={addTransformDataBySubMenu}
            selectedMenuKey={selectedMenuKey}
            typeChange={allowTypeChange}
          />
        );
      case MENU.DATA_TRANSFORMATION:
        return (
          <DataTransformation
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            updateTransformState={addTransformDataBySubMenu}
            selectedMenuKey={selectedMenuKey}
          />
        );
      case MENU.GROUP_CATEGORIES:
        return (
          <GroupCategories
            rowsNames={preview?.map((prev) => prev.name) ?? []}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            updateTransformState={addTransformDataBySubMenu}
          />
        );
      case MENU.CHANGE_COLUMN:
        return (
          <ChangeColumnType
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            originalPreview={originalPreview}
            removeColumnGroup={removeColumnGroup}
            updateTransformState={addTransformDataBySubMenu}
            datasetId={viewRender.payload.datasetId}
          />
        );
      case MENU.FILTER_COLUMN:
        return (
          <FilterSettings
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            updateTransformState={addTransformDataBySubMenu}
            selectedMenuKey={selectedMenuKey}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box className="transformation-manager-selected-content">
      {selectedMenu === undefined ? (
        <>
          <div
            className={`${
              added === false
                ? 'transformation-menu'
                : 'transformation-menu-disabled'
            }`}
            onClick={() => {
              if (added === false) {
                setSelectedMenu({
                  icon: ICON_TYPE.SETTINGS03,
                  text: 'Column settings',
                  selected: MENU.COLUMN_SETTINGS,
                  columnData: selectedTransform
                });
              }
            }}
          >
            <div>
              <CustomIcon type={ICON_TYPE.SETTINGS03} />
              <p>Column settings</p>
            </div>
            {CustomSubmenuTooltip(
              'Filter column view, type or variables to take into account on your analysis'
            )}
          </div>
          <div
            className={`${
              added === false
                ? 'transformation-menu'
                : 'transformation-menu-disabled'
            }`}
            onClick={() => {
              if (added === false) {
                setSelectedMenu({
                  icon: ICON_TYPE.DATA,
                  text: 'Data transformation',
                  selected: MENU.DATA_TRANSFORMATION,
                  columnData: selectedTransform
                });
              }
            }}
          >
            <div>
              <CustomIcon type={ICON_TYPE.DATA} />
              <p>Data transformation</p>
            </div>
            {CustomSubmenuTooltip(
              'Transform data variables within the column or use this column as starting point to create a new one based on modified factors.'
            )}
          </div>
          <div
            className={`${
              transformationState[selectedColumn] !== undefined
                ? 'transformation-menu'
                : 'transformation-menu-disabled'
            }`}
            onClick={removeColumnGroup}
          >
            <div>
              <CustomIcon type={ICON_TYPE.REFRESHCCW01} />
              <p>Revert changes</p>
            </div>
            {CustomSubmenuTooltip(
              'Reverting changes will loose any modifications done on this column.'
            )}
          </div>
        </>
      ) : (
        selectedMenuHasKey()
      )}
    </Box>
  );
};

export default TransformationSelectedMenu;
