import { type ReactElement, useState } from 'react';

import { Box, Select, Chip, MenuItem } from '@mui/material';

import type { AccessSelectData } from 'common/interfaces/interfaces';
import type { ICON_TYPE } from 'common/interfaces/enums';

import CustomIcon from 'common/CustomIcon';
import SmallCheckbox from 'common/smallCheckbox/SmallCheckbox';
import { Clear, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

import '../Common.scss';
import './SelectWithChips.scss';
interface SelectAccessProps {
  disabled: boolean;
  disabledOnlyChip?: boolean;
  label: string;
  selectedOptions: AccessSelectData[];
  onChange: (value: AccessSelectData) => void;
  onDelete: (value: AccessSelectData) => void;
  accessSelectData: AccessSelectData[];
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 270,
      marginLeft: '1em'
    }
  }
};

const SelectWithChips = ({
  disabled,
  disabledOnlyChip,
  label,
  onChange,
  onDelete,
  selectedOptions = [],
  accessSelectData = []
}: SelectAccessProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const defineValue = (): string => {
    if (selectedOptions.length > 0) {
      return selectedOptions.map((selected) => selected.text).join();
    }
    return '';
  };

  return (
    <Box className="display-column">
      <label id="access-label">{label}</label>
      <Select
        labelId="access-label"
        onClose={() => {
          setIsOpen(false);
        }}
        onOpen={() => {
          setIsOpen(true);
        }}
        disabled={disabled}
        onChange={(event) => {
          const found = accessSelectData.find(
            (e: AccessSelectData) => e.text === event.target.value
          );
          if (found !== undefined) {
            onChange(found);
          }
        }}
        IconComponent={() =>
          isOpen ? (
            <KeyboardArrowUp
              style={{ marginRight: '0.5em', marginBottom: '0.2em' }}
            />
          ) : (
            <KeyboardArrowDown style={{ marginRight: '0.5em' }} />
          )
        }
        value={defineValue()}
        MenuProps={MenuProps}
        renderValue={() => `Select ${label} type`}
      >
        {accessSelectData.map((accessElement: AccessSelectData) => {
          const found = selectedOptions.find((e: AccessSelectData) => {
            return e.id === accessElement.id;
          });
          return (
            <MenuItem
              key={`${accessElement.id}${accessElement.text}`}
              value={accessElement.text}
            >
              <Box className="selectChips">
                <SmallCheckbox found={found !== undefined} />
                {accessElement.icon !== undefined &&
                accessElement.icon !== '' ? (
                  <CustomIcon type={accessElement.icon as ICON_TYPE} />
                ) : null}
                {accessElement.text}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
      <Box className="chips-wrapper">
        {selectedOptions.map((access: AccessSelectData) => (
          <Chip
            className="chip"
            disabled={disabled || disabledOnlyChip}
            key={access.id}
            label={access.text}
            icon={
              access.icon !== undefined && access.icon !== '' ? (
                <CustomIcon type={access.icon as ICON_TYPE} />
              ) : (
                <></>
              )
            }
            deleteIcon={<Clear />}
            onDelete={() => {
              onDelete(access);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SelectWithChips;
