import { Box, Button } from '@mui/material';
import { TitleTypography } from 'common/TitleTypography';
import { getLinkPreview } from 'link-preview-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState, type ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Image from './Image/Image';

const LinkPreview = (props: {
  url: string;
  orientation: string;
}): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataURL, setDataURL]: any = useState();

  useEffect(() => {
    if (props.url !== undefined) {
      const urlArray = props.url.split('|');
      const url = urlArray[0].replace('<', '');
      getLinkPreview(url)
        .then((data) => {
          setDataURL(data);
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  }, [props.url]);

  const classes = {
    box: {
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    image: {
      width: '25%',
      height: 'auto',
      borderRadius: '8px'
    },
    description: {
      fontFamily: 'ft-system-regular',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.01em',
      color: '#c1c1c1',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'wrap',
      height: '60px',
      display: 'inline-block'
    }
  };

  const generateOrientation = (): ReactElement => {
    if (props.orientation === 'horizontal') {
      return generateHorizontalBox();
    } else {
      return generateVerticalBox();
    }
  };

  const generateHorizontalBox = (): ReactElement => {
    return (
      <Box
        sx={classes.box}
        style={{ padding: '10px', width: '100%' }}
        key={uuidv4()}
      >
        {(dataURL.images.length > 0 || dataURL.favicons.length > 0) &&
          generateImage()}
        <Box
          sx={classes.box}
          style={{
            flexDirection: 'column',
            maxWidth: '100%'
          }}
        >
          {generateTitle()}
          {generateDescription()}
          {generateNavigationButton()}
        </Box>
      </Box>
    );
  };

  const generateVerticalBox = (): ReactElement => {
    return (
      <Box
        sx={classes.box}
        style={{ padding: '10px', flexDirection: 'column', width: '100%' }}
        key={uuidv4()}
      >
        {generateImage()}
        <Box
          sx={classes.box}
          style={{
            flexDirection: 'column',
            maxWidth: '100%'
          }}
        >
          {generateTitle()}
          {generateDescription()}
          {generateNavigationButton()}
        </Box>
      </Box>
    );
  };

  const generateImage = (): ReactElement => {
    if (dataURL.images?.length > 0 || dataURL.favicons?.length > 0) {
      let images =
        dataURL.images?.length > 0 ? dataURL.images : dataURL.favicons;
      if (images[0]?.includes('.gif') === true) {
        images = dataURL.favicons;
      }
      const image = images[0];
      if (image !== undefined) {
        const imageProps = { options: { image_url: image } };
        return <Image {...imageProps} />;
      }
    }
    return <></>;
  };

  const generateTitle = (): ReactElement => {
    if (dataURL?.title !== undefined) {
      return (
        <TitleTypography
          key={`title-${Math.random()}`}
          style={{
            display: 'flex',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'left',
            color: '#ffffff'
          }}
        >
          {dataURL?.title}
        </TitleTypography>
      );
    }
    return <></>;
  };

  const generateDescription = (): ReactElement => {
    if (dataURL?.description !== undefined) {
      return (
        <TitleTypography
          sx={classes.description}
          key={`title-${Math.random()}`}
          style={{ display: 'flex', textAlign: 'justify' }}
        >
          {dataURL?.description}
        </TitleTypography>
      );
    }
    return <></>;
  };

  const generateNavigationButton = (): ReactElement => {
    if (dataURL?.url !== undefined) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <Button
            onClick={() => {
              window.open(dataURL.url, '_blank');
            }}
            style={{ borderRadius: '10px' }}
          >
            Navigate
          </Button>
        </div>
      );
    }
    return <></>;
  };

  return (
    <React.Fragment>
      {dataURL !== undefined && generateOrientation()}
    </React.Fragment>
  );
};

LinkPreview.propTypes = {
  url: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

LinkPreview.defaultProps = {
  orientation: 'vertical'
};

export default LinkPreview;
