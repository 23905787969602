import { Box, Typography } from '@mui/material';
import { TitleTypography } from 'common/TitleTypography';
import CustomButton from 'common/button/CustomButton';
import { useEffect, useState, type ReactElement } from 'react';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import Hand from 'assets/imgs/hand.svg';
import { useSetRecoilState } from 'recoil';
import { modalControllerAtom } from 'atoms/atomModalController';
import { MODAL_TYPES } from 'common/interfaces/enums';
import ModalComposed from 'common/modal/ModalComposed';
import 'common/Common.scss';
import './StartChat.scss';

const CONTACT_AIZ_WEB = process.env.REACT_APP_CONTACT_AIZ_WEB ?? '';

const StartChat = (): ReactElement => {
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const setModalController = useSetRecoilState(modalControllerAtom);
  const [assistantApiSocketIsConnected, setAssistantApiSocketIsConnected] =
    useState<boolean>(assistantApiSocketClient.isConnected() ?? false);

  useEffect(() => {
    assistantApiSocketClient?.addIsConnectedListener(() => {
      setAssistantApiSocketIsConnected(true);
    });
    const keyDownHandler = (event: globalThis.KeyboardEvent): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setModalController({ type: MODAL_TYPES.LOGIN });
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <ModalComposed
      open={true}
      className="md-modal-wrapper"
      body={
        <Box id="start-chat">
          <img className="hand-image" src={Hand} />
          <Box className="start-chat-box">
            <Typography className="start-title">
              {'Hi,\nI am Aizy,\nyour AI assistant'}
            </Typography>
            <Typography className="start-content">
              {
                'Let me help you define your goals and get actionable insights and predictions in minutes.'
              }
            </Typography>
          </Box>
          <CustomButton
            style={{ maxWidth: 'auto', width: '100%' }}
            disabled={!assistantApiSocketIsConnected}
            variant={'primary'}
            onClick={() => {
              setModalController({ type: MODAL_TYPES.LOGIN });
            }}
          >
            Login
          </CustomButton>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'baseline',
              gap: '5px',
              justifyContent: 'center'
            }}
          >
            <TitleTypography
              key={`title-${Math.random()}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'ft-system-regular',
                color: '#667085',
                fontSize: '16px',
                margin: '0px',
                padding: '0px',
                marginBlock: '0px',
                width: '40%'
              }}
            >
              {"Don't have an account?"}
            </TitleTypography>
            <Typography
              key={`modal-button-${Math.random()}`}
              className="signup-button"
              onClick={() => {
                window.location.href = `${CONTACT_AIZ_WEB}`;
              }}
              sx={{
                width: '30%',
                color: '#344054',
                marginLeft: '-35px',
                textDecoration: 'underline',
                fontSize: '16px',
                textTransform: 'capitalize'
              }}
            >
              Sign up here
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default StartChat;
