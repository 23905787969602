export interface EXTENSION_FILE {
  key: string;
  extension: string;
  app: string;
  name: string;
}

export const EXTENSIONS_FILES: EXTENSION_FILE[] = [
  {
    key: 'csv',
    extension: '.csv',
    app: 'text/csv',
    name: 'CSV'
  },
  {
    key: 'xls',
    extension: '.xls',
    app: 'application/vnd.ms-excel',
    name: 'XLS'
  },
  {
    key: 'xlsx',
    extension: '.xlsx',
    app: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'XLSX'
  }
];
