import type { CSSInterpolation } from '@mui/material';

export const dialogComposedFooter: CSSInterpolation = {
  gridRow: 'footer',
  width: 'auto',
  border: '1px',
  padding: '1em 2em',
  borderTop: '1px solid #E0E0E0'
};

export const dialogSmFooter: CSSInterpolation = {
  width: '100%',
  padding: '0'
};
