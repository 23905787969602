import { Box } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { ICON_TYPE, NUMBERS_STRINGS } from 'common/interfaces/enums';
import type { Dispatch, ReactElement, SetStateAction } from 'react';
import './SwitchTransformer.scss';
import {
  USER_TRACKING_LOCATION_NAMES,
  USER_TRACKING_FENG_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { useRecoilValue } from 'recoil';
import { GAUserEvent } from 'utils/utils';

const SwitchTransformer = ({
  detailPreview,
  setDetailPreview,
  disableFirstButton = false,
  disableSecondButton = false
}: {
  detailPreview: string;
  setDetailPreview: Dispatch<SetStateAction<string>>;
  disableFirstButton?: boolean;
  disableSecondButton?: boolean;
}): ReactElement => {
  const userLocationVariable = useRecoilValue(userTrackingLocation);
  return (
    <Box className="display-row">
      <button
        disabled={disableFirstButton}
        className={
          detailPreview === NUMBERS_STRINGS.SECOND
            ? 'switch-preview-first'
            : 'switch-preview-first background-grey'
        }
        onClick={() => {
          if (detailPreview === NUMBERS_STRINGS.SECOND) {
            setDetailPreview(NUMBERS_STRINGS.FIRST);
          }
          if (
            userLocationVariable.current.includes(
              USER_TRACKING_LOCATION_NAMES.FEATURE_ENGINEERING
            )
          ) {
            GAUserEvent(
              `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.CHANGE_VIEW_MODE}`
            );
          }
        }}
      >
        <CustomIcon
          className={
            detailPreview === NUMBERS_STRINGS.SECOND
              ? 'dark-icon'
              : 'light-thin-grey-icon-no-hover'
          }
          type={ICON_TYPE.TABLE}
        />
      </button>
      <button
        disabled={disableSecondButton}
        className={
          detailPreview === NUMBERS_STRINGS.FIRST
            ? 'switch-preview-second'
            : 'switch-preview-second background-grey'
        }
        onClick={() => {
          if (detailPreview === NUMBERS_STRINGS.FIRST) {
            setDetailPreview(NUMBERS_STRINGS.SECOND);
          }
          if (
            userLocationVariable.current.includes(
              USER_TRACKING_LOCATION_NAMES.FEATURE_ENGINEERING
            )
          ) {
            GAUserEvent(
              `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.CHANGE_VIEW_MODE}`
            );
          }
        }}
      >
        <CustomIcon
          className={
            detailPreview === NUMBERS_STRINGS.FIRST
              ? 'dark-icon'
              : 'light-thin-grey-icon-no-hover'
          }
          type={ICON_TYPE.DOTPOINTS01}
        />
      </button>
    </Box>
  );
};

export default SwitchTransformer;
