import { Box } from '@mui/system';
import type { Accessory, ViewMore } from 'chat/interfaces/messages';
import { useState, type ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Image from './Image/Image';

interface ShowMoreProperties {
  showMore: ViewMore[];
  message?: {
    message?: string;
    from?: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomComponent?: any;
}

const ShowMore = (props: ShowMoreProperties): ReactElement => {
  const [showMore, setShowMore] = useState(props.showMore ?? []);
  const CustomComponent = props.CustomComponent;
  let message: string =
    props?.message?.message !== undefined ? '' + props.message.message : '';
  let secondPart = '';

  const showMoreLinks = (index: number): void => {
    const newViewMore: ViewMore[] = showMore.map(
      (viewMore: ViewMore, ind: number) => {
        if (ind === index) {
          viewMore.visible =
            viewMore.visible === undefined ? true : !viewMore.visible;
          return viewMore;
        }
        return viewMore;
      }
    );
    setShowMore(newViewMore);
  };

  const filteredShowMore: ViewMore[] = showMore.filter(
    (elem: ViewMore, index: number) => {
      if (elem?.accessory?.image_url !== undefined) {
        elem.accessory.disable = true;
      }

      const start = message.indexOf(elem?.accessory?.alt_text?.trim() ?? '');
      if (start > 0) {
        const end = start + elem.accessory.alt_text.length;
        const firstPart = message.slice(0, start);
        secondPart = message.slice(end);

        if (showMore.length - 1 === index && secondPart !== '') {
          secondPart.replace(/\s+/g, ' ').trim();
        }

        message = firstPart + secondPart;
        return true;
      }
      return false;
    }
  );

  const upperPart = message
    .slice(0, message.indexOf(secondPart))
    .replace(/\s+/g, ' ')
    .trim();
  const lowerPart = secondPart.trim();

  const printText = (value: string | { text: string }): string | undefined => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'object' && typeof value.text === 'string') {
      return value.text;
    }
  };

  return (
    <CustomComponent
      keys={uuidv4()}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      {upperPart !== '' && <Box key={uuidv4()}>{upperPart}</Box>}
      {filteredShowMore.length > 0 &&
        filteredShowMore.map((link: ViewMore, index: number) => {
          const accessory: Accessory = link.accessory;
          if (accessory === undefined) {
            return null;
          }
          const imageProps = { options: { image_url: accessory.image_url } };
          return (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
              key={uuidv4()}
            >
              <Box>{accessory.alt_text}</Box>
              {!accessory.disable && (
                <button
                  style={{
                    width: 'fit-content',
                    marginTop: '0.2em',
                    marginBottom: '0.4em',
                    fontFamily: 'ft-system-regular',
                    borderRadius: '2em',
                    border: 'none',
                    padding: '0.8em'
                  }}
                  onClick={() => {
                    showMoreLinks(index);
                  }}
                >
                  {link.visible === true ? 'Show less' : 'Learn more'}
                </button>
              )}
              {link.visible === true && (
                <Box>
                  <Image {...imageProps} key={uuidv4()} />
                  {link.text !== undefined && link.text !== '' && (
                    <Box style={{ marginLeft: '10px' }}>
                      {printText(link.text)}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      {lowerPart !== undefined && lowerPart !== '' && (
        <Box key={uuidv4()}>{lowerPart}</Box>
      )}
    </CustomComponent>
  );
};
export default ShowMore;
