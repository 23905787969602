import { createTheme } from '@mui/material';
import { MuiButtonRegular, MuiButtonWhite } from './button';
import { MuiDialogRegular } from './dialogs/dialog';

const compiledThemes = {
  components: {
    MuiButton: MuiButtonRegular,
    MuiDialog: MuiDialogRegular
  },
  typography: {
    fontFamily: 'ft-system-regular'
  }
};

export const theme = createTheme(compiledThemes);

export const themeWhiteButtons = createTheme({
  ...compiledThemes,
  components: {
    ...compiledThemes.components,
    MuiButton: MuiButtonWhite
  }
});
