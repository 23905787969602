import type { CSSInterpolation } from '@mui/material';

export const dialogComposedContent: CSSInterpolation = {
  gridRow: 'body',
  display: 'flex',
  justifyContent: 'center',
  width: '96%',
  height: '97%',
  padding: '1% 2%'
};

export const dialogFullContent: CSSInterpolation = {
  display: 'flex',
  justifyContent: 'center',
  width: '99%',
  padding: '0'
};
