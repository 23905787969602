import { type ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import ModalComposed from 'common/modal/ModalComposed';

import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './MergeCompabilityAlert.scss';
import { ModalSize } from 'common/interfaces/enums';

interface MergeOptions {
  continueMerge: () => void;
  discardMerge: () => void;
}

const MergeCompatibility = ({
  continueMerge,
  discardMerge
}: MergeOptions): ReactElement => {
  const headerComponent = (
    <Box className="merge-compability-modal-header">
      <Typography className="sm-modal-title-header">
        We have identified columns with over 60% empty values in the selected
        datasets
      </Typography>
    </Box>
  );

  const bodyComponent = (
    <Box className="merge-compability-modal-body">
      <Typography className="sm-modal-body-title">
        These predominantly empty columns may affect accurate analysis or
        predictions. Please review your chosen datasets or consider alternative
        selections for a more effective merge.
      </Typography>
    </Box>
  );

  const footerComponent = (
    <Box className="sm-modal-footer" style={{ gap: '20px' }}>
      <CustomButton
        style={{ maxWidth: 'none' }}
        variant="secondary"
        onClick={continueMerge}
      >
        Ignore and continue
      </CustomButton>
      <CustomButton style={{ maxWidth: 'none' }} onClick={discardMerge}>
        Review datasets
      </CustomButton>
    </Box>
  );

  return (
    <ModalComposed
      className="sm-modal-wrapper"
      open={true}
      size={ModalSize.SMALL}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default MergeCompatibility;
