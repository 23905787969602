import CustomIcon from 'common/CustomIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@mui/material';
import {
  MENU,
  SUB_MENU,
  type IColumnSettingsProps
} from 'featureEngineering/featureEngineeringInterface';
import { FeatureType } from 'playground/interfaces/playground';
import { CustomSubmenuTooltip } from 'featureEngineering/TransformationSelectedMenu';

const DataTransformation = ({
  selectedMenu,
  setSelectedMenu
}: IColumnSettingsProps): JSX.Element | null => {
  const { dataValue } = selectedMenu.columnData.data;
  return selectedMenu !== undefined ? (
    <>
      <Box className="transformation-selected">
        <Box>
          <CustomIcon type={selectedMenu.icon} />
          <p>{selectedMenu.text}</p>
        </Box>
        <ChevronLeftIcon
          className="cursor-pointer"
          onClick={() => {
            setSelectedMenu(undefined);
          }}
        />
      </Box>
      {dataValue === FeatureType.NUMERICAL ||
      dataValue === FeatureType.TEXTUAL ? (
        <Box
          className="transformation-sub-menu"
          onClick={() => {
            setSelectedMenu({
              ...selectedMenu,
              text: 'Grouping in categories',
              selected: MENU.GROUP_CATEGORIES
            });
          }}
        >
          <p>Grouping in categories</p>
          {CustomSubmenuTooltip(
            'Group data from this column into smaller, more manageable categories or bins, enabling analysis based on those categories.'
          )}
        </Box>
      ) : null}
      <Box
        className="transformation-sub-menu-disabled"
        onClick={() => {
          console.log(SUB_MENU.TRANSFORMATION);
        }}
      >
        <p>Transformation</p>
        {CustomSubmenuTooltip(
          'Mathematical or logical operations, allowing for conversions, scaling, or deriving new information.'
        )}
      </Box>
      <Box
        className="transformation-sub-menu-disabled"
        onClick={() => {
          console.log(SUB_MENU.FILTER_COLUMN);
        }}
      >
        <p>Deal with missing values</p>
        {CustomSubmenuTooltip(
          'Handle missing data by applying techniques like imputation or removal, ensuring data integrity.'
        )}
      </Box>
    </>
  ) : null;
};

export default DataTransformation;
