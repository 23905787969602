import { styled, Typography, type TypographyProps } from '@mui/material';

export const TitleTypography = styled(Typography)<TypographyProps>(() => ({
  fontFamily: 'ft-system-bold',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '18px',
  lineHeight: '28px',
  letterSpacing: '-0.02em',
  color: '#000000',
  width: '100%',
  userSelect: 'none',
  textAlign: 'center'
}));
