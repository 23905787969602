import axios, { type AxiosResponse } from 'axios';

export async function getFileSize(link: string): Promise<number> {
  try {
    const response: AxiosResponse = await axios.head(link);

    if (response.status !== 200) {
      return -1;
    }
    return Number(response.headers['content-length']);
  } catch (error) {
    console.log(error);
    return -1;
  }
}
