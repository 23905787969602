import { HOME_VIEW_TYPES } from 'common/interfaces/enums';
import type { HomeViewRenderType } from 'common/interfaces/interfaces';
import { atom } from 'recoil';

export const chatActiveAtom = atom<boolean>({
  key: 'chatActiveAtom',
  default: false
});

export const homeViewRenderAtom = atom<HomeViewRenderType>({
  key: 'displayViewAtom',
  default: { type: HOME_VIEW_TYPES.CHAT }
});
