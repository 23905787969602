import { Box, Typography } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import CustomButton from 'common/button/CustomButton';
import { ICON_TYPE } from 'common/interfaces/enums';
import { activeTransformViewAtom } from 'featureEngineering/atoms/atomTransformFeature';
import { TRANSFORM_COLUMN } from 'featureEngineering/featureEngineeringInterface';
import { type ReactElement } from 'react';
import { useSetRecoilState } from 'recoil';

const DefaultView = (): ReactElement => {
  const setActiveTransformView = useSetRecoilState(activeTransformViewAtom);
  return (
    <Box className="transformation-block-manager-content">
      <CustomIcon
        className="grey-icon-no-hover"
        type={ICON_TYPE.EDIT02}
        style={{
          maxWidth: '52px',
          height: '52px',
          width: '52px',
          margin: '5px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
      <Box className="transformation-message">
        <Typography id="transformation-block-title">
          {'Dataset\n transformations'}
        </Typography>
        <Typography>
          {
            'Start by selecting a column or create\n new columns by combining or\n transforming existing ones.'
          }
        </Typography>
      </Box>
      <Box className="transformation-buttons">
        <CustomButton
          style={{ maxWidth: 'none' }}
          onClick={() => {
            setActiveTransformView(TRANSFORM_COLUMN.NEW_COLUMN);
          }}
        >
          Create new column
        </CustomButton>
        <CustomButton
          disabled={true}
          variant="secondary"
          style={{ maxWidth: 'none' }}
        >
          Combine datasets
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DefaultView;
