import React, { useEffect, useRef, useState, type ReactElement } from 'react';

import { type BotComponentSubmit } from 'chat/Chat';
import { lastResponseAtom } from 'chat/atoms/atomLastResponse';
import { USER_MESSAGE } from 'chat/interfaces/enums';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import Input from 'common/Input';
import CustomButton from 'common/button/CustomButton';
import { useRecoilValue } from 'recoil';

interface InputSendProps extends BotComponentSubmit {
  collapseIsland: () => void;
  caption?: string;
  regex?: string;
  submitLink?: (value: string) => void;
}

const InputSend = ({
  caption,
  regex,
  collapseIsland,
  addNewMessages,
  submitLink
}: InputSendProps): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);

  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState<string>('');
  const assistantApiService = AssistantApiService.getInstance();

  useEffect(() => {
    setTimeout(() => {
      if (text === null) {
        inputRef?.current?.focus();
      }
    }, 1000);
  }, []);

  const addText = (newText: string): void => {
    if (regex !== undefined) {
      const regexTest = new RegExp(regex);
      if (regexTest.test(newText)) {
        setText(newText);
      } else {
        setText('');
      }
    } else {
      setText(newText);
    }
  };

  const submitValue = (value: string): void => {
    collapseIsland();
    addNewMessages(value, USER_MESSAGE.MESSAGE, true);
    if (submitLink !== undefined) {
      submitLink(value);
    } else {
      assistantApiService.inputInteraction(lastResponse.blocks, value);
    }
  };

  const onEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (text !== undefined && text !== '') submitValue(text);
    }
  };

  return (
    <React.Fragment>
      <div
        className="InputSend"
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Input
          ref={inputRef}
          onChange={(text: string) => {
            text !== undefined && addText(text.replace(/['"]+/g, ''));
          }}
          enterAction={onEnter}
        />
        {caption !== undefined ? (
          <p style={{ fontSize: '12px' }}>{caption}</p>
        ) : (
          <></>
        )}
        <CustomButton
          onClick={() => {
            submitValue(text);
          }}
          style={{
            marginTop: caption === null ? '0px' : '20px',
            maxWidth: 'none'
          }}
          disabled={text === undefined || text === ''}
        >
          Send
        </CustomButton>
      </div>
    </React.Fragment>
  );
};

export default InputSend;
