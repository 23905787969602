import { useState, type ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

import { Box, Typography } from '@mui/material';

import { BOT_COMPONENT, USER_MESSAGE } from 'chat/interfaces/enums';

import {
  ICON_INTERACTION_CLASSES,
  type ICON_TYPE
} from 'common/interfaces/enums';

import { lastResponseAtom } from 'chat/atoms/atomLastResponse';
import { type BotComponentSubmit } from 'chat/Chat';
import { AssistantApiService } from 'chat/services/AssistantApiService';

import { type ButtonType } from 'chat/interfaces/messages';
import CustomButton from 'common/button/CustomButton';
import CustomIcon from 'common/CustomIcon';
import './ResponseButtons.scss';

interface ResponseButtonPros extends BotComponentSubmit {
  buttons: ButtonType[] | string[];
  componenType: BOT_COMPONENT;
  collapseIsland: () => void;
}

const ResponseButtons = ({
  buttons,
  componenType,
  addNewMessages,
  collapseIsland
}: ResponseButtonPros): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);

  const assistantApiService = AssistantApiService.getInstance();
  const [selected, setSelected] = useState<string>('');
  const isRadio = componenType === BOT_COMPONENT.RADIO_BUTTONS;

  const submitValue = (value: string): void => {
    collapseIsland();
    addNewMessages(value, USER_MESSAGE.MESSAGE, true);
    assistantApiService.selectOption(lastResponse.blocks, value);
  };

  const getTextFromButton = (btn: ButtonType | string): string => {
    if (typeof btn !== 'string' && 'value' in btn) {
      return btn.value as string;
    }
    return btn as string;
  };

  const generateCustomButton = (
    btn: string | ButtonType,
    buttonText: string,
    isSelected: boolean,
    index: number
  ): JSX.Element => {
    const calculateSpacePerElement =
      buttons.length === 1
        ? '100%'
        : `${(100 - 5 * (buttons.length - 1)) / buttons.length}%`;
    const key = `response-buttons-${index}`;
    return (
      <CustomButton
        variant="secondary"
        key={key}
        className={`
          ${isRadio ? 'radioButtons' : 'responseButton'} 
          ${
            isSelected
              ? ICON_INTERACTION_CLASSES.LIGHT_NO_HOVER + ' selected'
              : ICON_INTERACTION_CLASSES.DARK_NO_HOVER
          }
        `}
        style={{
          maxWidth: calculateSpacePerElement
        }}
        onClick={() => {
          if (
            typeof btn !== 'string' &&
            'value' in btn &&
            typeof btn.value === 'string'
          ) {
            isRadio ? setSelected(btn.value) : submitValue(buttonText);
          } else if (typeof btn === 'string') {
            submitValue(buttonText);
          }
        }}
      >
        {typeof btn !== 'string' &&
          'icon' in btn &&
          'value' in btn &&
          btn.icon !== null &&
          typeof btn.value === 'string' && (
            <Box className={`${isRadio ? 'radioMargin' : ''}`}>
              {btn.icon !== undefined ? (
                <CustomIcon
                  style={{
                    maxWidth: '40px',
                    width: '40px',
                    margin: '5px',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  type={btn.icon as ICON_TYPE}
                />
              ) : null}
              <Typography className="ft-regular ft-sm">{buttonText}</Typography>
            </Box>
          )}
        {typeof btn === 'string' && (
          <Typography className="ft-regular ft-sm">{buttonText}</Typography>
        )}
      </CustomButton>
    );
  };

  const responseButtons = (
    <Box className="responseWrapper">
      {buttons.map((btn: string | ButtonType, index: number) => {
        const buttonText = getTextFromButton(btn);
        const isSelected = buttonText === selected;
        return generateCustomButton(btn, buttonText, isSelected, index);
      })}
    </Box>
  );

  return (
    <>
      {buttons.length > 0 && (
        <>
          {isRadio ? (
            <Box className="radioWrapper">
              {responseButtons}
              <CustomButton
                disabled={selected === ''}
                customKey={`response-buttons-${buttons.length}`}
                style={{ maxWidth: 'none' }}
                onClick={() => {
                  submitValue(selected);
                }}
              >
                Send
              </CustomButton>
            </Box>
          ) : (
            responseButtons
          )}
        </>
      )}
    </>
  );
};

export default ResponseButtons;
