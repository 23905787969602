import {
  Box,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent
} from '@mui/material';
import { PredictStatus } from 'common/interfaces/enums';
import {
  loadingJobAtom,
  runningButtonTextAtom,
  scenarioAtom
} from 'playground/atoms/atomPlayground';
import { type ReactElement } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  TRACKED_USER_ACTION_PLAYGROUND,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import { ScenarioSelectors } from 'playground/interfaces/playground';
import { GAUserEvent } from 'utils/utils';
import './Scenario.scss';

interface ScenarioProps {
  categoryVariants: string[];
  runScenario: (
    selector: string,
    intention: string,
    goal: string
  ) => Promise<void>;
}

enum ScenarioUpdate {
  SCENARIO,
  INTENTION,
  GOAL,
  UPDATE
}

const Scenario = ({
  categoryVariants,
  runScenario
}: ScenarioProps): ReactElement => {
  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const runningButtonText = useRecoilValue(runningButtonTextAtom);
  const loadingPrediction = useRecoilValue(loadingJobAtom);
  const [scenario, setScenario] = useRecoilState(scenarioAtom);

  const updateScenario = (
    variable: ScenarioUpdate,
    event?: SelectChangeEvent,
    update?: boolean
  ): void => {
    switch (variable) {
      case ScenarioUpdate.SCENARIO:
        setScenario({
          ...scenario,
          scenario: event?.target.value as ScenarioSelectors
        });
        break;
      case ScenarioUpdate.INTENTION:
        setScenario({
          ...scenario,
          intention: event?.target.value as ScenarioSelectors
        });
        break;
      case ScenarioUpdate.GOAL:
        setScenario({
          ...scenario,
          goal: event?.target.value as string
        });
        break;
      case ScenarioUpdate.UPDATE:
        setScenario({
          ...scenario,
          update: update as boolean
        });
        break;
    }
  };

  const disableExploreButton = (): boolean => {
    if (categoryVariants.length === 0) {
      return loadingPrediction;
    } else {
      return loadingPrediction || scenario.goal === '';
    }
  };

  return (
    <>
      <Box id="scenario-container">
        <Box id="scenario-container__selectors">
          <Box id="scenario-select">
            <InputLabel id="scenario-select-label">Chosen Scenario</InputLabel>
            <Select
              onChange={(event) => {
                updateScenario(ScenarioUpdate.SCENARIO, event);
              }}
              value={scenario.scenario}
              variant="standard"
              disableUnderline
              disabled={runningButtonText === PredictStatus.RUNNING}
            >
              <MenuItem value={ScenarioSelectors.BEST}>
                {ScenarioSelectors.BEST}
              </MenuItem>
              <MenuItem value={ScenarioSelectors.WORST}>
                {ScenarioSelectors.WORST}
              </MenuItem>
            </Select>
          </Box>
          <Divider orientation="vertical" />
          <Box id="scenario-intention">
            <InputLabel id="scenario-intention-label">
              Prediction Intention
            </InputLabel>
            <Select
              value={scenario.intention}
              onChange={(event) => {
                updateScenario(ScenarioUpdate.INTENTION, event);
              }}
              variant="standard"
              disableUnderline
              disabled={runningButtonText === PredictStatus.RUNNING}
            >
              <MenuItem value={ScenarioSelectors.INCREASE}>
                {ScenarioSelectors.INCREASE}
              </MenuItem>
              <MenuItem value={ScenarioSelectors.DECREASE}>
                {ScenarioSelectors.DECREASE}
              </MenuItem>
            </Select>
          </Box>
          {categoryVariants.length === 0 ? null : (
            <Box id="scenario-goal">
              <InputLabel id="scenario-goal-label">Main goal</InputLabel>
              <Select
                value={scenario.goal}
                onChange={(event) => {
                  updateScenario(ScenarioUpdate.GOAL, event);
                }}
                variant="standard"
                disableUnderline
                disabled={runningButtonText === PredictStatus.RUNNING}
                style={{ width: '100%' }}
              >
                {categoryVariants.map((goal, index) => (
                  <MenuItem key={`${goal}_${index}`} value={goal}>
                    {goal}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </Box>
        <CustomButton
          style={{ marginLeft: '10px' }}
          disabled={disableExploreButton()}
          variant="secondary"
          onClick={() => {
            GAUserEvent(
              `${userLocationVariable.current}_${TRACKED_USER_ACTION_PLAYGROUND.EXPLORE}`
            );
            updateScenario(ScenarioUpdate.UPDATE, undefined, true);
            void runScenario(
              scenario.scenario,
              scenario.intention,
              scenario.goal
            );
          }}
        >
          Explore
        </CustomButton>
      </Box>
    </>
  );
};

export default Scenario;
