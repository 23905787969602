import { type ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import ModalComposed from 'common/modal/ModalComposed';

import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './MergeDuplicatedAlert.scss';
import { ModalSize } from 'common/interfaces/enums';

interface MergeOptions {
  continueMerge: () => void;
  discardMergeDuplicated: () => void;
  disableMergeAllButton: boolean;
}

const MergeDuplicated = ({
  continueMerge,
  discardMergeDuplicated,
  disableMergeAllButton
}: MergeOptions): ReactElement => {
  const headerComponent = (
    <Box className="merge-compability-modal-header">
      <Typography className="sm-modal-title-header">
        There are duplicated columns
      </Typography>
    </Box>
  );

  const bodyComponent = (
    <Box className="merge-compability-modal-body">
      <Typography className="sm-modal-body-title">
        {
          "In case you haven't noticed, there are duplicated columns, you can review them or continue with the merge."
        }
      </Typography>
    </Box>
  );

  const footerComponent = (
    <Box className="sm-modal-footer" style={{ gap: '20px' }}>
      <CustomButton
        disabled={disableMergeAllButton ?? false}
        style={{ maxWidth: 'none' }}
        onClick={discardMergeDuplicated}
      >
        Review datasets
      </CustomButton>
      <CustomButton
        variant="secondary"
        disabled={disableMergeAllButton ?? false}
        onClick={continueMerge}
        style={{ maxWidth: 'none' }}
      >
        Yes, merge all
      </CustomButton>
    </Box>
  );

  return (
    <ModalComposed
      className="sm-modal-wrapper"
      open={true}
      size={ModalSize.SMALL}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default MergeDuplicated;
