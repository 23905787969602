import { atom } from 'recoil';
import type { Filter } from 'models/Filter';

export interface FilterOptions {
  page: number;
  pageSize: number;
  sortBy?: string;
  order?: string;
  keyFactor?: string;
  factorsDisplay?: string[];
  filter?: Filter;
  reset?: boolean;
}

export const previewfiltersStateAtom = atom<FilterOptions>({
  key: 'previewfiltersStateAtom',
  default: { page: 1, pageSize: 10 }
});
