import { atom } from 'recoil';

export enum MESSAGE_TYPES {
  INFO = 'info',
  ERROR = 'error'
}

export interface NotifyMessageTypes {
  status: MESSAGE_TYPES;
  open: boolean;
  message: string;
}

export const notifyMessageAtom = atom<NotifyMessageTypes>({
  key: 'notifyMessageAtom',
  default: {
    status: MESSAGE_TYPES.INFO,
    open: false,
    message: ''
  }
});

export const createErrorNotification = (
  message: string,
  setNotifyMessage: (elem: NotifyMessageTypes) => void
): void => {
  setNotifyMessage({
    status: MESSAGE_TYPES.ERROR,
    open: true,
    message
  });
};

export const createInfoNotification = (
  message: string,
  setNotifyMessage: (elem: NotifyMessageTypes) => void
): void => {
  setNotifyMessage({
    status: MESSAGE_TYPES.INFO,
    open: true,
    message
  });
};
