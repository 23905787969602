import {
  useEffect,
  useState,
  type Dispatch,
  type RefObject,
  type SetStateAction
} from 'react';

import { Box, TextField } from '@mui/material';

import InputWithChips from 'common/inputWithChips/InputWithChips';
import type {
  AccessSelectData,
  NotifyBoxTypes
} from 'common/interfaces/interfaces';
import { nameRegex } from 'utils/utils';

import { ACCESS_TYPES, HOME_VIEW_TYPES } from 'common/interfaces/enums';
import NotifyBox from 'common/notifyBox/NotifyBox';
import SelectWithChips from 'common/selectWithChips/SelectWithChips';
import { useRecoilValue } from 'recoil';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import AreaTextWysiwyg from 'common/areaTextWysiwyg/AreaTextWysiwyg';
import 'common/Common.scss';
import './AssetsForm.scss';

export interface AssetsFormProps {
  disableInputs: boolean;
  onEnter?: (event: React.KeyboardEvent) => void;
  inputControllers: {
    stateName: {
      name: string;
      setName: Dispatch<SetStateAction<string>>;
      nameRef: RefObject<HTMLInputElement>;
      validName: boolean;
      setValidName: Dispatch<SetStateAction<boolean>>;
    };
    stateDescription: {
      description?: string;
      setDescription?: Dispatch<SetStateAction<string>>;
      descriptionRef: RefObject<HTMLTextAreaElement>;
    };
    stateAccess: {
      access: AccessSelectData[];
      visibility: ACCESS_TYPES;
      setVisibility: Dispatch<SetStateAction<ACCESS_TYPES>>;
    };
    stateTags: [string[], Dispatch<SetStateAction<string[]>>];
    shared: boolean;
  };
  messageError?: null | NotifyBoxTypes;
}

const AssetsForm = ({
  disableInputs,
  inputControllers,
  onEnter,
  messageError = null
}: AssetsFormProps): JSX.Element => {
  const { name, setName, nameRef, validName, setValidName } =
    inputControllers.stateName;
  const viewRender = useRecoilValue(homeViewRenderAtom);

  const { description, setDescription, descriptionRef } =
    inputControllers.stateDescription ?? {};

  const isEdit =
    viewRender.type === HOME_VIEW_TYPES.DETAIL_MODEL ||
    viewRender.type === HOME_VIEW_TYPES.DETAIL_DATASETS;
  const isDataSet =
    viewRender.type === HOME_VIEW_TYPES.SAVE_DATASET ||
    viewRender.type === HOME_VIEW_TYPES.DETAIL_DATASETS;

  const { access, visibility, setVisibility } = inputControllers.stateAccess;
  const { shared } = inputControllers;
  const [accessElements] = useState<AccessSelectData[]>(access);
  const [localTags, setLocalTags] = inputControllers.stateTags;

  const initialSelected = access.filter(
    (accessData) => accessData.id === visibility
  );
  const accessPrivate = access.filter(
    (accessData) => accessData.id === ACCESS_TYPES.PRIVATE
  );
  const [accessSelected, setAccessSelected] = useState<AccessSelectData[]>(
    isEdit ? initialSelected : accessPrivate
  );
  const correctNameStatus =
    name.match(nameRegex) !== null && (name === '' || name.length > 0);

  useEffect(() => {
    if (!validName && correctNameStatus && name.length > 0) {
      setValidName(true);
    } else if (name === '' || (validName && !correctNameStatus)) {
      setValidName(false);
    }
  }, [name]);

  useEffect(() => {
    if (
      shared &&
      accessSelected[0] !== undefined &&
      accessSelected[0].id === ACCESS_TYPES.ACCOUNT_SHARED &&
      visibility !== ACCESS_TYPES.ACCOUNT_SHARED
    ) {
      setVisibility(ACCESS_TYPES.ACCOUNT_SHARED);
    } else if (
      accessSelected[0] !== undefined &&
      accessSelected[0].id === ACCESS_TYPES.PRIVATE &&
      visibility !== ACCESS_TYPES.PRIVATE
    ) {
      setVisibility(ACCESS_TYPES.PRIVATE);
    }
    if (accessSelected.length === 0) {
      setAccessSelected(accessPrivate);
    } else if (accessSelected.length > 1) {
      setAccessSelected([accessSelected[1]]);
    }
  }, [accessSelected]);

  const onChangeSelectAccess = (newSelectedOption: AccessSelectData): void => {
    setAccessSelected([...accessSelected, newSelectedOption]);
  };

  const onDeleteSelectAccess = (option: AccessSelectData): void => {
    const newSelectedOptions = accessSelected.filter(
      (access: AccessSelectData) => access.id !== option.id
    );
    setAccessSelected(newSelectedOptions);
  };

  const handleAddElement = (element: string): void => {
    const inputText = element.trim();
    if (
      inputText !== '' &&
      typeof inputText === 'string' &&
      !localTags.includes(inputText)
    ) {
      setLocalTags([...localTags, inputText]);
    }
  };

  const handleDeleteTag = (elementToDelete: string): void => {
    setLocalTags(localTags.filter((element) => element !== elementToDelete));
  };

  const onNameChange = (): void => {
    if (setName !== undefined && nameRef.current !== null) {
      setName(nameRef.current.value);
    }
  };

  return (
    <>
      <form className="display-row save-form-block" action="">
        <Box className="save-section-one">
          <Box className="display-column ">
            <label htmlFor="saveName">Name</label>
            <TextField
              inputRef={nameRef}
              id="saveName"
              name="saveName"
              disabled={disableInputs}
              className={!correctNameStatus ? 'input-error' : ''}
              value={name}
              inputProps={{
                maxLength: 250
              }}
              onChange={onNameChange}
              onKeyUp={onEnter}
              variant="outlined"
              type="text"
              placeholder={
                'e.g.: ' + (isDataSet ? 'dataset_name' : 'model_name')
              }
              autoComplete="off"
              data-cy="assets-form-save-name-input"
            />
            {!correctNameStatus ? (
              <Box className="errorMsg">
                {
                  'Name only admits the following characters A-Z, a-z, 0-9, _-.*()'
                }
              </Box>
            ) : null}
          </Box>
          <Box className="display-column">
            <AreaTextWysiwyg
              label="Description (optional)"
              descriptionRef={descriptionRef}
              value={description ?? ''}
              placeholder={`e.g.: What is the objective of this ${
                isDataSet ? 'dataset' : 'model'
              }?`}
              onChange={(content: string) => {
                if (setDescription !== undefined) {
                  setDescription(content);
                }
              }}
            />
          </Box>
        </Box>

        <Box className="display-column save-section-two">
          <SelectWithChips
            disabled={!shared || disableInputs}
            disabledOnlyChip={accessSelected[0]?.id === ACCESS_TYPES.PRIVATE}
            label={'Access'}
            onChange={onChangeSelectAccess}
            onDelete={onDeleteSelectAccess}
            selectedOptions={accessSelected}
            accessSelectData={accessElements}
          />
          <InputWithChips
            disabled={disableInputs || localTags.length === 10}
            inputElements={localTags}
            handleAddElement={handleAddElement}
            onDelete={handleDeleteTag}
          />
        </Box>
      </form>
      {messageError !== null ? (
        <Box sx={{ margin: '0 1em 2em 0em' }}>
          <NotifyBox type={messageError.type} message={messageError.message} />
        </Box>
      ) : null}
    </>
  );
};

export default AssetsForm;
