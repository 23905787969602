import { type SignedUploadUrl } from 'common/interfaces/interfaces';
import { ExposeService } from './ExposeService';
import axios, { AxiosError } from 'axios';

export async function uploadFile(file: File): Promise<SignedUploadUrl> {
  const exposeService = ExposeService.getInstance();
  const response = await exposeService.getSignedDatasetUploadUrl(file);

  if (response instanceof AxiosError) {
    throw response;
  }
  const signedUrl = response.url;
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type
    }
  });
  return response;
}
