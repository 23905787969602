import type { CSSInterpolation } from '@mui/material';

export const dialogCompsedHeader: CSSInterpolation = {
  gridRow: 'header',
  width: '100%',
  '> p': {
    padding: '1em 2em',
    border: '1px',
    borderBottom: '1px solid #E0E0E0'
  },
  '> div': {
    padding: '1em 2em',
    border: '1px',
    borderBottom: '1px solid #E0E0E0'
  }
};

export const dialogFullHeader: CSSInterpolation = {
  width: '100%',
  '> div': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
};
