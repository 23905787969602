import { useState, type ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from '@mui/material';
import { type BotComponentSubmit } from 'chat/Chat';
import { USER_MESSAGE } from 'chat/interfaces/enums';
import { type Option } from 'chat/interfaces/messages';
import { lastResponseAtom } from 'chat/atoms/atomLastResponse';

import { AssistantApiService } from 'chat/services/AssistantApiService';

import './Checkbox.scss';

interface CheckboxProps extends BotComponentSubmit {
  buttons: Array<{ title: string }>;
  options: Option[];
  collapseIsland: () => void;
}

const Checkbox = ({
  buttons,
  options,
  collapseIsland,
  addNewMessages
}: CheckboxProps): ReactElement => {
  const lastResponse = useRecoilValue(lastResponseAtom);

  const [selected, setSelected] = useState<string[]>([]);
  const assistantApiService = AssistantApiService.getInstance();

  const submitValue = (): void => {
    collapseIsland();
    if (selected.length > 0) {
      addNewMessages(selected.join(', '), USER_MESSAGE.MESSAGE, true);
    } else {
      addNewMessages(
        'None of the textual columns has been marked',
        USER_MESSAGE.MESSAGE,
        true
      );
    }
    assistantApiService.checkBoxInteraction(lastResponse.blocks, selected);
  };

  const updateSelected = (option: Option): void => {
    const isActive = selected.includes(option.value);
    if (isActive) {
      const newSelected = selected.filter((item: string) => {
        return item !== option.value;
      });
      setSelected(newSelected);
    } else {
      setSelected([...selected, option.value]);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '8rem',
          overflow: 'overlay'
        }}
      >
        {options.map((option, index: number) => {
          return (
            <label
              className="CustomCheckbox"
              key={`checkbox-options-${index}}`}
            >
              <input
                type="checkbox"
                checked={selected.includes(option.value)}
                value={option.value}
                onChange={() => {
                  updateSelected(option);
                }}
              />
              {option.text}
            </label>
          );
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {buttons.map((button: { title: string }, index: number) => {
          return (
            <Button
              sx={{
                width: buttons.length === 1 ? '100%' : '47%'
              }}
              key={`custom-checkbox-${index}`}
              onClick={submitValue}
            >
              {button.title}
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default Checkbox;
