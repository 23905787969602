import React, { type ReactElement, useState, useRef, useEffect } from 'react';

import type { Option } from 'chat/interfaces/messages';

import { MenuItem, Select } from '@mui/material';
import { Check, KeyboardArrowDown } from '@mui/icons-material';

interface CustomSelectProps {
  options: Option[];
  onChange: (value: string | string[]) => void;
  className?: string;
  value?: string;
  multiValue?: string[];
  multiple?: boolean;
  initialvalue?: string | string[];
}

const classes = {
  width: '100%',
  height: '48px',
  margin: '24px 0px',
  background: '#FFFFFF',
  border: '1px solid #D0D5DD',
  boxShadow:
    '0px 0px 0px 4px rgba(168, 42, 171, 0.3), 0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A82AAB !important',
    borderRadius: '8px'
  }
};

const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: '100%',
      boxSizing: 'borderBox',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px',
      position: 'absolute',
      background: '#FFFFFF',
      border: '1px solid #F2F4F7',
      boxShadow:
        '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      borderRadius: '8px',
      margin: '-5px 0px',
      '& .MuiList-root': {
        maxHeight: '20rem',
        width: '100%',
        '::-webkit-scrollbar': {
          width: '0.4em'
        },

        '::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, .5)'
        }
      },
      '& .MuiButtonBase-root': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 14px',
        gap: '8px',
        height: '44px',
        flex: 'none',
        order: '0',
        alignSelf: 'stretch',
        flexGrow: '0',
        borderRadius: '8px',
        marginBottom: '3px',
        '&:hover': {
          background: 'rgba(168, 42, 171, 0.73)',
          color: 'white'
        }
      },
      '& .Mui-selected': {
        background: '#F2F4F7',
        '&:hover': {
          background: 'rgba(168, 42, 171, 0.73)',
          color: 'white'
        }
      }
    }
  },
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'top'
  },
  transformOrigin: {
    horizontal: 'left',
    vertical: 'bottom'
  }
};

const CustomSelect = ({
  options,
  onChange,
  initialvalue,
  className = '',
  value = '',
  multiValue = [],
  multiple = false
}: CustomSelectProps): ReactElement => {
  initialvalue = multiple ? initialvalue ?? [] : initialvalue ?? '';
  const [optionSelected, setOptionSelected] = useState<string>('');
  const [optionMultiSelected, setOptionMultiSelected] = useState<string[]>();
  const selectField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (optionSelected !== '') {
      onChange(optionSelected);
    }
  }, [optionSelected]);

  useEffect(() => {
    if (optionMultiSelected !== undefined) {
      onChange(optionMultiSelected);
    }
  }, [optionMultiSelected]);

  useEffect(() => {
    if (value !== undefined && optionSelected === '') {
      setOptionSelected(value);
    }
  }, [value]);

  return (
    <React.Fragment>
      <Select
        ref={selectField}
        defaultValue={!multiple ? value : multiValue}
        value={initialvalue}
        sx={classes}
        className={className}
        multiple={multiple}
        onChange={(e) => {
          if (
            multiple &&
            e?.target?.value !== undefined &&
            Array.isArray(e.target.value)
          ) {
            const optionsTarget = e.target.value;
            const newOptionsSelected = optionsTarget.filter((option) => {
              const found = options.find((opt) => opt.value === option);
              if (found !== undefined) {
                return option;
              }
              return null;
            });
            setOptionMultiSelected(newOptionsSelected);
          } else if (!Array.isArray(e.target.value)) {
            setOptionSelected(e.target.value);
          }
        }}
        // @ts-expect-error MUI type does not expect the value prop but it works
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDown}
      >
        {options.map((option: Option) => (
          <MenuItem
            style={{ fontFamily: 'ft-system-regular' }}
            key={`option-${option.value}`}
            value={option?.value}
          >
            {option?.label}
            {!multiple && optionSelected === option.value ? <Check /> : <></>}
            {multiple &&
            optionMultiSelected?.find(
              (optionSelect) => optionSelect === option.value
            ) !== undefined ? (
              <Check />
            ) : (
              <></>
            )}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
};

export default CustomSelect;
