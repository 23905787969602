import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ModalSize } from 'common/interfaces/enums';
import '../Common.scss';
import './ModalController.scss';

const DialogComposed = ({
  open,
  id,
  className,
  onClose,
  size = ModalSize.MEDIUM,
  style = {},
  header,
  body,
  footer,
  disablePortal = false
}: {
  open: boolean;
  id?: string;
  className?: string;
  onClose?: () => void;
  size?: ModalSize;
  style?: React.CSSProperties;
  header?: JSX.Element | null;
  body?: JSX.Element | null;
  footer?: JSX.Element;
  disablePortal?: boolean;
}): JSX.Element => {
  const hasHead = header !== null && header !== undefined;
  const hasFooter = footer !== null && footer !== undefined;

  return (
    <Dialog
      id={id}
      disablePortal={disablePortal}
      open={open}
      aria-describedby="dialog-content"
      disableAutoFocus
      disableEscapeKeyDown={true}
      classes={{ paper: `${className ?? ''} ${size}` }}
      PaperProps={{ style }}
    >
      {hasHead ? <Box className="dialog-header">{header}</Box> : null}
      {body !== null && body !== undefined ? (
        <DialogContent
          className={`dialog-content ${
            !(hasHead && hasFooter) ? 'individual-body' : ''
          }`}
        >
          {body}
        </DialogContent>
      ) : null}
      {hasFooter ? (
        <DialogActions className="dialog-footer">{footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};

export default DialogComposed;
