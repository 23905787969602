import { Box, Divider, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { memo, useEffect, useRef } from 'react';

import { previewfiltersStateAtom } from 'bulkPredictions/atoms/atomPreviewfilersState';
import { type Option } from 'chat/interfaces/messages';
import CustomIcon from 'common/CustomIcon';
import CustomSelect from 'common/CustomSelect';
import Input from 'common/Input';
import { ICON_TYPE } from 'common/interfaces/enums';
import { Filter, type MinMax } from 'models/Filter';

import {
  USER_TRACKING_SUMMARY_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { updateFilterValue } from 'bulkPredictions/utils/summaryLayerUtils';
import 'common/Common.scss';
import CustomButton from 'common/button/CustomButton';
import { FeatureType } from 'playground/interfaces/playground';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GAUserEvent, debouncer } from 'utils/utils';
import './Filtering.scss';

interface FilteringProps {
  targetVariable?: string;
  paginationSizeAction?: (e: string) => void;
  paginationSizeValue?: number;
  columnOptions: Option[];
  rowsSize?: number;
  online?: boolean;
  handleResetFilter?: () => void;
}

const MAX_PAGE_SIZE = 100;
const orderByOptions: Option[] = [
  { id: 1, value: 'asc', label: 'Ascending' },
  { id: 2, value: 'desc', label: 'Descending' }
];

const Filtering = memo(function FilteringDataRows({
  handleResetFilter,
  targetVariable = undefined,
  paginationSizeValue = 0,
  columnOptions = [],
  rowsSize = 0,
  online = false
}: FilteringProps) {
  const filterby = useRef<string>();
  const [filterOptions, setFilterOptions] = useRecoilState(
    previewfiltersStateAtom
  );
  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const allowReset = Object.keys(filterOptions).length < 3;

  useEffect(() => {
    if (filterOptions.pageSize !== paginationSizeValue) {
      setFilterOptions((localFilter) => {
        const newFilter: { filter?: Filter | undefined } = {
          filter: updateFilterValue(localFilter.filter)
        };
        return {
          ...localFilter,
          ...(newFilter.filter !== undefined ? newFilter : {}),
          pageSize: paginationSizeValue
        };
      });
    }
  }, []);

  const getValueToNumericalCategory = (
    property: string,
    value?: string
  ): string | string[] | MinMax | undefined => {
    let filterValue = filterOptions?.filter?.value;
    const localValue = value !== '' ? Number(value) : undefined;

    if (
      filterValue !== undefined &&
      !Array.isArray(filterValue) &&
      typeof filterValue === 'object'
    ) {
      if (property === 'min') {
        filterValue = { ...filterValue, min: localValue };
      } else if (property === 'max') {
        filterValue = { ...filterValue, max: localValue };
      }
    } else if (filterOptions?.filter !== undefined) {
      if (property === 'max') {
        filterValue = { min: undefined, max: localValue };
      } else {
        filterValue = { min: localValue, max: undefined };
      }
    }
    return filterValue;
  };

  const getCategoricalOptions = (): Option[] => {
    const showOnlyName = filterOptions?.filter?.name;
    const showOnlyType = filterOptions?.filter?.type;
    if (showOnlyName !== undefined) {
      const option: Option | undefined = columnOptions.find((column) => {
        if (
          column.type === showOnlyType &&
          column.sampleData !== undefined &&
          (column.value === showOnlyName ||
            column.value === 'predicted_' + showOnlyName)
        ) {
          return column;
        }
        return false;
      });
      if (
        option?.sampleData !== undefined &&
        Array.isArray(option.sampleData)
      ) {
        const newOptions: Option[] = [];
        option.sampleData
          .map((option) => String(option))
          .forEach((sample: string) =>
            newOptions.push({
              id: `${sample}`,
              label: `${sample}`,
              value: `${sample}`
            })
          );
        return newOptions;
      }
    }
    return [];
  };

  const getKeysFactorsOptions = (): Option[] => {
    const target = targetVariable ?? '';
    const predictedTargetVariable = `predicted_${target}`;
    const newColumns = columnOptions.filter(
      (column) =>
        !column.label.startsWith('probability') &&
        column.label !== predictedTargetVariable
    );
    return newColumns;
  };

  const getFactorsToDisplayOptions = (): Option[] => {
    const newColumnOptions = columnOptions.filter((column) => {
      if (
        !column.label.startsWith('probability') &&
        !column.label.startsWith(`predicted_${targetVariable ?? ''}`) &&
        column.label !== filterOptions?.keyFactor
      ) {
        return column;
      }
      return null;
    });
    return newColumnOptions;
  };

  const getDefaultValueKeyFactorsSelect = (): string => {
    const factorsOptions = getKeysFactorsOptions();
    const targetInKeyFactos = factorsOptions.find(
      (factor: Option) => factor.label === targetVariable
    );
    if (targetInKeyFactos !== undefined) {
      if (targetVariable !== undefined) {
        return targetVariable;
      } else {
        return factorsOptions[0].label;
      }
    }
    return '';
  };

  const renderInputTypeByFilter = (type: string | undefined): JSX.Element => {
    switch (type) {
      case FeatureType.NUMERICAL: {
        const value = filterOptions.filter?.value;
        let min, max;
        if (
          value !== undefined &&
          !Array.isArray(value) &&
          typeof value === 'object'
        ) {
          min = value.min;
          max = value.max;
        }
        return (
          <Box className="display-row" style={{ gap: '8px' }}>
            <Input
              type={'number'}
              id="filtering-show-only-min"
              value={min}
              onChange={(value: string) => {
                if (filterby.current !== value && value !== undefined) {
                  filterby.current = value !== '' ? value : undefined;
                  debouncer(
                    (newValue: string): void => {
                      const filterValue = getValueToNumericalCategory(
                        'min',
                        newValue
                      );
                      const newOption = new Filter({
                        name: filterOptions?.filter?.name,
                        type: filterOptions?.filter?.type,
                        value: filterValue
                      });
                      setFilterOptions((localFilter) => {
                        return {
                          ...localFilter,
                          filter: newOption,
                          page: 1,
                          // Filtering can be used with two data sources, online and offline
                          // reset only applies to the online data source
                          reset: online
                        };
                      });
                    },
                    filterby,
                    1000
                  );
                }
              }}
            />
            <Input
              type={'number'}
              id="filtering-show-only-max"
              value={max}
              onChange={(value: string) => {
                if (filterby.current !== value && value !== undefined) {
                  filterby.current = value !== '' ? value : undefined;
                  debouncer(
                    (newValue: string): void => {
                      const filterValue = getValueToNumericalCategory(
                        'max',
                        newValue
                      );
                      const newOption = new Filter({
                        name: filterOptions?.filter?.name,
                        type: filterOptions?.filter?.type,
                        value: filterValue
                      });
                      setFilterOptions((localFilter) => {
                        return {
                          ...localFilter,
                          filter: newOption,
                          page: 1,
                          // Filtering can be used with two data sources, online and offline
                          // reset only applies to the online data source
                          reset: online
                        };
                      });
                    },
                    filterby,
                    1000
                  );
                }
              }}
            />
          </Box>
        );
      }
      case FeatureType.BINARY_CATEGORICAL:
      case FeatureType.CATEGORICAL:
        return (
          <Box className="display-row" style={{ gap: '8px' }}>
            <CustomSelect
              className="filtering-select-categorical"
              multiple={true}
              initialvalue={filterOptions.filter?.value as string[]}
              options={getCategoricalOptions()}
              onChange={(optionMultiSelected) => {
                const newOption = new Filter({
                  name: filterOptions?.filter?.name,
                  type: filterOptions?.filter?.type,
                  value: optionMultiSelected
                });
                setFilterOptions((localFilter) => {
                  return {
                    ...localFilter,
                    filter: newOption,
                    page: 1,
                    // Filtering can be used with two data sources, online and offline
                    // reset only applies to the online data source
                    reset: online
                  };
                });
              }}
            />
          </Box>
        );
      case FeatureType.TEXTUAL:
        return (
          <Box className="display-column" style={{ gap: '8px' }}>
            <Input
              type={'text'}
              id="filtering-textual-input"
              onChange={(value) => {
                const newValue = value !== '' ? value : undefined;
                const newOption = new Filter({
                  name: filterOptions?.filter?.name,
                  type: filterOptions?.filter?.type,
                  value: newValue
                });
                setFilterOptions((localFilter) => {
                  return {
                    ...localFilter,
                    filter: newOption,
                    page: 1,
                    // Filtering can be used with two data sources, online and offline
                    // reset only applies to the online data source
                    reset: online
                  };
                });
              }}
            />
          </Box>
        );
      case FeatureType.DATETIME:
        return (
          <Box className="display-column" style={{ gap: '8px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="feature-item date-picker"
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                onChange={(value: string | null) => {
                  if (value !== null) {
                    value = new Date(value).toLocaleDateString();
                  }
                  const newOption = new Filter({
                    name: filterOptions?.filter?.name,
                    type: filterOptions?.filter?.type,
                    value: value === null ? '' : value
                  });
                  setFilterOptions((localFilter) => {
                    return {
                      ...localFilter,
                      filter: newOption,
                      page: 1,
                      // Filtering can be used with two data sources, online and offline
                      // reset only applies to the online data source
                      reset: online
                    };
                  });
                }}
              />
            </LocalizationProvider>
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box className="filtering-main">
      {online ? (
        <Box className="filtering-header display-row">
          <Box className="filtering-icon">
            <CustomIcon type={ICON_TYPE.SETTINGS_FILTERS} />
          </Box>
          <Typography
            className="filtering-header-title-1 color-dark"
            style={{ alignSelf: 'center' }}
          >
            Filter results
          </Typography>
        </Box>
      ) : (
        <Box className="filtering-header display-row">
          <Box className="filtering-icon">
            <CustomIcon type={ICON_TYPE.BULK_PREDICTION} />
          </Box>
          <Box className="display-column">
            <Typography className="filtering-header-title-1 color-dark">
              Predicted value of:
            </Typography>
            {targetVariable !== undefined && (
              <Typography className="color-dark">{targetVariable}</Typography>
            )}
          </Box>
        </Box>
      )}
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        flexItem
        className="filtering-divider"
      />
      <Box className="filtering-block-1 display-column">
        <Box className="display-column">
          <Typography className="filtering-block-title-1">
            Results per page
          </Typography>
          <Input
            type={'number'}
            id="filtering-input-results"
            value={
              filterOptions?.pageSize !== undefined
                ? filterOptions.pageSize
                : ''
            }
            onChange={(value: string) => {
              const isTruthy = Boolean(value);
              const isValidNumber = Number(value);
              if (
                isTruthy &&
                !Number.isNaN(isValidNumber) &&
                isValidNumber > 0 &&
                isValidNumber <= Math.min(rowsSize, MAX_PAGE_SIZE)
              ) {
                setFilterOptions((localFilter) => {
                  const newFilter: { filter?: Filter | undefined } = {
                    filter: updateFilterValue(localFilter.filter)
                  };
                  return {
                    ...localFilter,
                    ...(newFilter.filter !== undefined ? newFilter : {}),
                    pageSize: Number(value),
                    page: 1,
                    // Filtering can be used with two data sources, online and offline
                    // reset only applies to the online data source
                    reset: online
                  };
                });
              }
            }}
          />
        </Box>
        {columnOptions?.length > 0 && (
          <Box className="display-column">
            <Typography className="filtering-block-title-1">
              Order by
            </Typography>
            <CustomSelect
              className="filtering-select"
              options={columnOptions}
              initialvalue={filterOptions.sortBy}
              onChange={(value) => {
                if (filterOptions.sortBy !== undefined || value.length > 0) {
                  GAUserEvent(
                    `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.ORDER}`
                  );
                  setFilterOptions((localFilter) => {
                    const newFilter: { filter?: Filter | undefined } = {
                      filter: updateFilterValue(localFilter.filter)
                    };
                    return {
                      ...localFilter,
                      ...(newFilter.filter !== undefined ? newFilter : {}),
                      sortBy:
                        typeof value === 'string' ? value : localFilter?.sortBy,
                      page: 1,
                      // Filtering can be used with two data sources, online and offline
                      // reset only applies to the online data source
                      reset: online
                    };
                  });
                }
              }}
            />
            <CustomSelect
              className="filtering-select"
              options={orderByOptions}
              initialvalue={filterOptions.order}
              onChange={(value) => {
                if (filterOptions.order !== undefined || value.length > 0) {
                  setFilterOptions((localFilter) => {
                    const newFilter: { filter?: Filter | undefined } = {
                      filter: updateFilterValue(localFilter.filter)
                    };
                    return {
                      ...localFilter,
                      ...(newFilter.filter !== undefined ? newFilter : {}),
                      order:
                        typeof value === 'string' ? value : localFilter?.order,
                      page: 1,
                      // Filtering can be used with two data sources, online and offline
                      // reset only applies to the online data source
                      reset: online
                    };
                  });
                }
              }}
            />
          </Box>
        )}
        {columnOptions?.length > 0 && (
          <>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              flexItem
              className="filtering-divider"
            />
            <Box className="display-column">
              <Typography className="filtering-block-title-1">
                Key factor
              </Typography>
              <CustomSelect
                className="filtering-select"
                options={getKeysFactorsOptions()}
                initialvalue={filterOptions.keyFactor}
                value={getDefaultValueKeyFactorsSelect()}
                onChange={(value) => {
                  if (
                    filterOptions.keyFactor !== undefined ||
                    value.length > 0
                  ) {
                    GAUserEvent(
                      `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.KEYFACT}`
                    );
                    setFilterOptions((localFilter) => {
                      const newFilter: { filter?: Filter | undefined } = {
                        filter: updateFilterValue(localFilter.filter)
                      };
                      return {
                        ...localFilter,
                        ...(newFilter.filter !== undefined ? newFilter : {}),
                        keyFactor:
                          typeof value === 'string'
                            ? value
                            : localFilter?.keyFactor
                      };
                    });
                  }
                }}
              />
            </Box>
            <Box className="display-column">
              <Typography className="filtering-block-title-1">
                Factors to display
              </Typography>
              <CustomSelect
                className="filtering-select"
                multiple={true}
                initialvalue={filterOptions.factorsDisplay}
                options={getFactorsToDisplayOptions()}
                onChange={(value) => {
                  if (
                    filterOptions.factorsDisplay !== undefined ||
                    value.length > 0
                  ) {
                    GAUserEvent(
                      `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.FACTORDISP}`
                    );
                    setFilterOptions((localFilter) => {
                      const newFilter: { filter?: Filter | undefined } = {
                        filter: updateFilterValue(localFilter.filter)
                      };
                      return {
                        ...localFilter,
                        ...(newFilter.filter !== undefined ? newFilter : {}),
                        factorsDisplay: Array.isArray(value)
                          ? value
                          : localFilter?.factorsDisplay
                      };
                    });
                  }
                }}
              />
            </Box>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              flexItem
              className="filtering-divider"
            />
            <Box className="display-column">
              <Typography className="filtering-block-title-1">
                Filter by
              </Typography>
              <CustomSelect
                className="filtering-select"
                options={columnOptions}
                initialvalue={filterOptions.filter?.name}
                value={filterby.current ?? ''}
                onChange={(value) => {
                  if (typeof value === 'string') {
                    filterby.current = value;
                    const option = columnOptions.find(
                      (column) => column.value === value
                    );

                    const newOption = new Filter({
                      name: option?.value,
                      type: option?.type
                    });
                    GAUserEvent(
                      `${userLocationVariable.current}_${USER_TRACKING_SUMMARY_ACTIONS.FILTER}`
                    );
                    setFilterOptions((localFilter) => {
                      const newFilter: { filter?: Filter | undefined } = {
                        filter: updateFilterValue(localFilter.filter)
                      };
                      return {
                        ...localFilter,
                        ...(newFilter.filter !== undefined ? newFilter : {}),
                        filter: newOption,
                        page: 1,
                        // Filtering can be used with two data sources, online and offline
                        // reset only applies to the online data source
                        reset: online
                      };
                    });
                  }
                }}
              />
              {renderInputTypeByFilter(filterOptions?.filter?.type)}
            </Box>
            <CustomButton
              disabled={allowReset}
              variant="secondary"
              onClick={handleResetFilter}
              style={{ maxWidth: 'none' }}
              icon={{
                type: ICON_TYPE.REFRESHCCW01,
                position: 'left'
              }}
            >
              Reset
            </CustomButton>
          </>
        )}
      </Box>
    </Box>
  );
});

export default Filtering;
