import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import confetti from 'assets/imgs/confetti.svg';
import progress_loading_dots from 'assets/imgs/loading-dots.svg';
import progress_rotate_oval from 'assets/imgs/progress_rotate_oval.gif';
import sadFace from 'assets/imgs/sad_face.png';
import type {
  ProgressBarProperties,
  ProgressTraker
} from 'common/interfaces/interfaces';
import { useEffect, useRef, useState, type ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

const ProgressBar = (props: ProgressBarProperties): ReactElement => {
  const [isFinished, setFinished] = useState(false);
  const currentProgressValue = Number(props?.options?.progress);
  let allElementsFinished = true;

  const initialProgressTracker: ProgressTraker[] = [
    { progress: 20, text: 'Cleaning the data', isFinished: false },
    {
      progress: 50,
      text: 'Transforming the data for analysis',
      isFinished: false
    },
    { progress: 70, text: 'Creating various models', isFinished: false },
    { progress: 90, text: 'Evaluating models', isFinished: false },
    { progress: 100, text: 'Preparing insights', isFinished: false }
  ];

  const progressTracker = useRef(initialProgressTracker);

  useEffect(() => {
    if (
      isFinished &&
      props.index !== undefined &&
      (props.disabled === undefined || !props.disabled) &&
      props.componentprogressBarIndexes !== undefined &&
      props.componentprogressBarIndexes[props.index] === undefined &&
      props.sound !== undefined
    ) {
      props.componentprogressBarIndexes[props.index] = true;
      props.sound();
      progressTracker.current = initialProgressTracker;
    }
  }, [isFinished]);

  const determineProgressStatus = (
    tracker: ProgressTraker
  ): JSX.Element | null => {
    if (!tracker.isFinished && currentProgressValue >= tracker.progress) {
      tracker.isFinished = true;
    }
    if (tracker.isFinished) {
      return <CheckCircleOutlineIcon color="secondary" />;
    }
    return null;
  };

  const progressTrackerIcon = (): ReactElement => {
    if ((props.disabled === true && allElementsFinished) || isFinished) {
      return (
        <img
          style={{ width: '100%', margin: '0.5em' }}
          src={confetti}
          alt={'confetti'}
        />
      );
    } else if (props.disabled === true) {
      return (
        <img
          style={{ width: '50%', margin: '0.5em' }}
          src={sadFace}
          alt={'sad face'}
        />
      );
    } else {
      return (
        <img
          style={{ width: '100%', margin: '0.5em' }}
          src={progress_rotate_oval}
          alt={'progress rotate oval'}
        />
      );
    }
  };

  const generateProgressTracker = (): ReactElement => {
    const progressTrackerComponent = progressTracker.current.map(
      (tracker: ProgressTraker, index: number) => {
        const statusImage = determineProgressStatus(tracker);
        if (!tracker.isFinished) {
          allElementsFinished = false;
        }
        const lastStatus: boolean =
          progressTracker.current[index - 1]?.isFinished;
        return (
          <div key={uuidv4()} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              key={uuidv4()}
              style={{
                width: '3em',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {(lastStatus === undefined || lastStatus) &&
              !tracker.isFinished ? (
                <img
                  style={{ width: '15px' }}
                  src={progress_loading_dots}
                  alt={'progress_loading_dots'}
                />
              ) : (
                statusImage
              )}
            </div>
            <Typography style={tracker.isFinished ? { color: 'black' } : {}}>
              {tracker.text}
            </Typography>
          </div>
        );
      }
    );
    if (
      allElementsFinished &&
      !isFinished &&
      (props.disabled === undefined || !props.disabled)
    ) {
      setFinished(true);
    }
    return <>{progressTrackerComponent}</>;
  };

  const buildProgressComponent = (): ReactElement => {
    const tracker = generateProgressTracker();
    const icon = progressTrackerIcon();
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '10em',
            height: '10em',
            margin: '0.5em'
          }}
        >
          {icon}
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {tracker}
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        margin: '1em',
        marginLeft: '0',
        marginTop: '0'
      }}
    >
      {buildProgressComponent()}
    </div>
  );
};

export default ProgressBar;
