import { type ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { type OperationItem } from 'featureEngineering/atoms/atomNewColumn';

interface OperationProps {
  item: OperationItem;
  index: number;
}
const Operation = ({ item, index }: OperationProps): ReactElement => {
  return (
    <Box key={index} className={`operation-item operation-item-${item.type}`}>
      <Typography>{item.value}</Typography>
    </Box>
  );
};

export default Operation;
