export enum USER_MESSAGE {
  MESSAGE = 'USER_MESSAGE',
  FILE = 'USER_MESSAGE_FILE'
}

export enum BOT_MESSAGE {
  MESSAGE = 'BOT_MESSAGE',
  MESSAGE_2 = 'BOT_MESSAGE_2',
  COMPONENT = 'BOT_MESSAGE_COMPONENT',
  WHITE = 'BOT_MESSAGE_WHITE',
  CHART = 'BOT_MESSAGE_CHART',
  FILE = 'BOT_MESSAGE_FILE',
  LINK_PREVIEW = 'LINK_PREVIEW',
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  MULTI_SELECT = 'MULTI_SELECT',
  OPEN_MODAL = 'BOT_MESSAGE_OPEN_MODAL'
}

export enum BOT_COMPONENT {
  COMPONENT = 'BOT_COMPONENT',
  INPUT = 'INPUT',
  EMAIL = 'EMAIL',
  SELECT = 'SELECT',
  PREPARING_DATA = 'PREPARING_DATA',
  RESPONSE_BUTTONS = 'RESPONSE_BUTTONS',
  PROGRESS_BAR = 'PROGRESS_BAR',
  IMAGE = 'IMAGE',
  PLAIN_TEXT = 'PLAIN_TEXT',
  BUTTON = 'BUTTON',
  CHECKBOXES = 'CHECKBOXES',
  RADIO_BUTTONS = 'RADIO_BUTTONS',
  STATIC_SELECT = 'STATIC_SELECT',
  PLAIN_TEXT_INPUT = 'PLAIN_TEXT_INPUT',
  // TODO: Difference between BOT_COMPONENT and BOT_MESSAGE_COMPONENT
  LINK_PREVIEW = 'LINK_PREVIEW',
  MULTI_SELECT = 'MULTI_SELECT'
}

export enum COMPONENT_TYPE {
  COMPONENT = 'component',
  MESSAGES = 'messages',
  MESSAGE = 'message',
  COMPLEMENT = 'complement'
}

export enum TYPES {
  SECTION = 'SECTION',
  IMAGE = 'IMAGE',
  ACTIONS = 'ACTIONS',
  INPUT = 'INPUT',
  CONTEXT = 'CONTEXT',
  MODAL = 'MODAL'
}

export enum LOADING_STATE {
  IDLE = 'IDLE',
  WAITING_MESSAGE = 'WAITING_MESSAGE',
  PROCESSING_DATA = 'PROCESSING_DATA'
}
