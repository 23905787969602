import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  IconButton,
  InputLabel,
  List,
  ListItem,
  TextField,
  Typography
} from '@mui/material';
import CustomButton from 'common/button/CustomButton';
import { ICON_TYPE, ModalSize } from 'common/interfaces/enums';
import { type DatasetSchema } from 'common/interfaces/interfaces';
import { useEffect, useState, type ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import {
  newColumnOperationAtom,
  operationBuilderModalAtom,
  type OperationItem
} from '../atoms/atomNewColumn';
import Operation from './Operation';
import ModalComposed from 'common/modal/ModalComposed';
import './NewColumn.scss';

interface OperationBuilderProps {
  columns: DatasetSchema[] | undefined;
}
enum OPERATORS {
  ADD = '+',
  SUBTRACT = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
  PARENTHESIS_OPEN = '(',
  PARENTHESIS_CLOSE = ')'
}

const OPERATORS_GROUP = [
  OPERATORS.ADD,
  OPERATORS.SUBTRACT,
  OPERATORS.MULTIPLY,
  OPERATORS.DIVIDE
] as string[];

const OPERATION_LABEL =
  'The formula builder allows you to select columns to be added, add operations and organize the operation as required.';

enum ERRORS {
  FIRST_OPERATOR = 'The first operator must be an open parenthesis, a number or a column',
  OPERATOR = 'You must select a number, column or open parenthesis',
  SELECT_OPERATOR = 'You must select an operator',
  SELECT_NUMBER_COLUMN = 'You must select a number or column',
  UNCLOSED_PARENTHESIS = 'You have unclosed parenthesis',
  UNOPENED_PARENTHESIS = 'You have unopened parenthesis',
  OPERATION_END = 'Operation can not end with an operator'
}

const OperationBuilder = ({ columns }: OperationBuilderProps): ReactElement => {
  const [openModal, setOpenModal] = useRecoilState(operationBuilderModalAtom);
  const [operation, setOperation] = useRecoilState(newColumnOperationAtom);
  const [filteredColumns, setFilteredColumns] = useState<DatasetSchema[]>(
    columns !== undefined ? columns : []
  );
  const [number, setNumber] = useState<number | undefined>(undefined);
  const [validationError, setValidationError] = useState<string>('');
  /**
    Flag to check if parenthesis are balanced.
    Positive number means there are more open parenthesis than close parenthesis and vice versa
  */
  const [flagParenthesis, setFlagParenthesis] = useState<number>(0);
  const [isValidOperation, setIsValidOperation] = useState<boolean>(false);

  useEffect(() => {
    if (
      operation.length > 2 &&
      validationError.length === 0 &&
      flagParenthesis === 0
    ) {
      const lastOperation = operation[operation.length - 1];
      if (
        lastOperation.type === 'operator' &&
        lastOperation.value !== OPERATORS.PARENTHESIS_CLOSE
      ) {
        setValidationError(ERRORS.OPERATION_END);
        setIsValidOperation(false);
      } else {
        setIsValidOperation(true);
      }
    } else {
      setIsValidOperation(false);
    }
  }, [operation, flagParenthesis, validationError]);

  const addOperation = (type: OperationItem['type'], value: string): void => {
    // First element must be number column or open parenthesis
    if (operation.length === 0) {
      if (type === 'operator' && value !== OPERATORS.PARENTHESIS_OPEN) {
        setValidationError(ERRORS.FIRST_OPERATOR);
        return;
      } else if (type === 'operator' && value === OPERATORS.PARENTHESIS_OPEN) {
        setFlagParenthesis(flagParenthesis + 1);
      }
    } else {
      const lastOperation = operation[operation.length - 1];
      if (lastOperation.type === 'operator') {
        if (lastOperation.value === OPERATORS.PARENTHESIS_CLOSE) {
          // Closed parenthesis must be followed by an operator or close parenthesis
          if (type === 'column' || type === 'number') {
            setValidationError(ERRORS.SELECT_OPERATOR);
            return;
          } else if (value === OPERATORS.PARENTHESIS_OPEN) {
            setValidationError(ERRORS.SELECT_OPERATOR);
            return;
          }
        }
        if (lastOperation.value === OPERATORS.PARENTHESIS_OPEN) {
          // Open parenthesis must be followed by a number, column or open parenthesis
          if (type === 'operator' && value !== OPERATORS.PARENTHESIS_OPEN) {
            setValidationError(ERRORS.SELECT_NUMBER_COLUMN);
            return;
          }
        }
        if (OPERATORS_GROUP.includes(lastOperation.value)) {
          // Operator must be followed by a number, column or open parenthesis
          if (type === 'operator' && value !== OPERATORS.PARENTHESIS_OPEN) {
            setValidationError(ERRORS.OPERATOR);
            return;
          }
        }
      } else if (
        lastOperation.type === 'column' ||
        lastOperation.type === 'number'
      ) {
        // Coulumn/number must be followed by an operator other than open parenthesis
        if (type === 'column' || type === 'number') {
          setValidationError(ERRORS.SELECT_OPERATOR);
          return;
        } else if (value === OPERATORS.PARENTHESIS_OPEN) {
          setValidationError(ERRORS.SELECT_OPERATOR);
          return;
        }
      }
      // Set parenthesis open flag
      if (type === 'operator' && value === OPERATORS.PARENTHESIS_OPEN) {
        setFlagParenthesis(flagParenthesis + 1);
      }
      // Set parenthesis close flag
      if (type === 'operator' && value === OPERATORS.PARENTHESIS_CLOSE) {
        setFlagParenthesis(flagParenthesis - 1);
      }
    }
    setOperation([...operation, { type, value }]);
    setValidationError('');
  };

  const removeOperation = (): void => {
    if (operation.length === 0) return;
    const lastOperation = operation[operation.length - 1];
    if (lastOperation.type === 'operator') {
      if (lastOperation.value === OPERATORS.PARENTHESIS_OPEN) {
        setFlagParenthesis(flagParenthesis - 1);
      }
      if (lastOperation.value === OPERATORS.PARENTHESIS_CLOSE) {
        setFlagParenthesis(flagParenthesis + 1);
      }
    }

    if (validationError === ERRORS.OPERATION_END) setValidationError('');
    setOperation(operation.slice(0, -1));
  };

  const resetValidation = (): void => {
    setValidationError('');
    setFlagParenthesis(0);
    setIsValidOperation(false);
  };

  const bodyComponent = (
    <Box className="operation-builder__content">
      <Box className="operation-builder__content-container">
        <InputLabel className="new-column-input-label">
          {OPERATION_LABEL}
        </InputLabel>
        <Box className="operation-wrapper">
          <Box className="operation-builder-field">
            {operation.map((item, index) => {
              return <Operation key={index} item={item} index={index} />;
            })}
          </Box>
          <CustomButton
            variant="secondary"
            onClick={() => {
              removeOperation();
            }}
            icon={{
              type: ICON_TYPE.DELETE_RETURN,
              position: 'left'
            }}
          />
        </Box>
        <Box className="validation-errors">
          {validationError.length > 0 && (
            <Alert severity="error">{validationError}</Alert>
          )}
          {flagParenthesis !== 0 && (
            <Alert severity="warning">
              {flagParenthesis > 0
                ? ERRORS.UNCLOSED_PARENTHESIS
                : ERRORS.UNOPENED_PARENTHESIS}
            </Alert>
          )}
        </Box>
      </Box>
      <Box className="operations-container">
        <Box className="operation-section" id="search-column">
          <span className="operation-title">Select column</span>
          <TextField
            id="column-name"
            label="Search column"
            variant="outlined"
            onChange={(event) => {
              const value = event.target.value;
              if (columns !== undefined) {
                setFilteredColumns(
                  columns.filter((column) =>
                    column.name.toLowerCase().includes(value.toLowerCase())
                  )
                );
              }
            }}
          />
          <List>
            {filteredColumns.map((column, index) => {
              return (
                <ListItem
                  className="search-column-item"
                  key={index}
                  onClick={() => {
                    addOperation('column', column.name);
                  }}
                >
                  {column.name}
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box className="operation-section">
          <span className="operation-title">Select operator</span>
          <Box className="operators-container">
            {Object.values(OPERATORS).map((operator, index) => {
              return (
                <div
                  key={index}
                  className="operators-item"
                  onClick={() => {
                    addOperation('operator', operator);
                  }}
                >
                  {operator}
                </div>
              );
            })}
          </Box>
        </Box>
        <Box className="operation-section">
          <span className="operation-title">Add number</span>
          <Box id="add-number-container">
            <TextField
              id="number"
              type="number"
              onChange={(event) => {
                setNumber(Number(event.target.value));
              }}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  number !== undefined &&
                  number !== 0
                ) {
                  addOperation('number', number.toString());
                }
              }}
            />
            <IconButton
              className="add-number-button"
              onClick={() => {
                if (number !== undefined && number !== 0) {
                  addOperation('number', number.toString());
                }
              }}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <ModalComposed
      open={openModal}
      size={ModalSize.COMPOSED_FULL}
      className="operation-builder-modal"
      header={
        <Box className="operation-builder-header">
          <Typography className="header-title">Build your operation</Typography>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              resetValidation();
              setOperation([]);
              setOpenModal(false);
            }}
          />
        </Box>
      }
      body={bodyComponent}
      footer={
        <Box className="operation-builder-footer">
          <CustomButton
            variant="primary"
            disabled={!isValidOperation}
            onClick={() => {
              resetValidation();
              setOpenModal(false);
            }}
          >
            Save operation
          </CustomButton>
        </Box>
      }
    />
  );
};

export default OperationBuilder;
