export const BASE_COLORS = {
  BASE_100: '#000000',
  BASE_90: '#101828',
  BASE_80: '#344054',
  BASE_70: '#667085',
  BASE_60: '#98a2b3',
  BASE_50: '#b4bcc8',
  BASE_40: '#d0d5dd',
  BASE_30: '#eaecf0',
  BASE_20: '#f2f4f7',
  BASE_10: '#f9fafb',
  BASE_0: '#ffffff'
};

export const ACCENTS = {
  ACCENT_0: '#A82AAB',
  ACCENT_32: '#812083',
  ACCENT_52: '#CB3ACF',
  ACCENT_62: '#D663D9',
  ACCENT_72: '#E18CE3',
  ACCENT_92: '#F6DEF7'
};

export function hexWithTransparencyPercentage(
  color: string,
  percentage = 100
): string {
  const decimal = `0${Math.round(255 * (percentage / 100)).toString(16)}`
    .slice(-2)
    .toUpperCase();
  return color + decimal;
}
