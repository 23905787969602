import React from 'react';
import { Box } from '@mui/material';
import 'common/Common.scss';

const mergeComponentClass = (
  element: JSX.Element,
  additionalClass: string
): JSX.Element => {
  let mergedClassName = additionalClass;
  if (element?.props !== undefined && element.props.className !== undefined) {
    mergedClassName = `${
      element.props.className as string
    } ${additionalClass}`.trim();
  }
  return React.cloneElement(element, { className: mergedClassName });
};

const RenderViewComposed = ({
  id,
  className,
  style = {},
  header,
  body,
  footer
}: {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  header?: JSX.Element | null;
  body?: JSX.Element | null;
  footer?: JSX.Element;
}): JSX.Element => {
  const hasHead =
    header !== undefined && header !== null
      ? mergeComponentClass(header, 'render-view__header')
      : null;

  const hasBody =
    body !== undefined && body !== null
      ? mergeComponentClass(body, 'render-view__body')
      : null;

  const hasFooter =
    footer !== undefined && footer !== null
      ? mergeComponentClass(footer, 'render-view__footer')
      : null;
  return (
    <Box
      id={id ?? ''}
      className={`render-view ${className ?? ''}`}
      style={{ ...style }}
    >
      {hasHead}
      {hasBody}
      {hasFooter}
    </Box>
  );
};

export default RenderViewComposed;
