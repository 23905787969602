import { Box } from '@mui/material';
import { TitleTypography } from 'common/TitleTypography';
import CustomButton from 'common/button/CustomButton';
import {
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type ReactElement
} from 'react';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { Cookie } from 'utils/cookie';
import { GAUserEvent } from '../../utils/utils';
import { generateInput, triggerSetText } from './common/common';
import ModalComposed from 'common/modal/ModalComposed';
import { useSetRecoilState } from 'recoil';
import { modalControllerAtom } from 'atoms/atomModalController';
import { MODAL_TYPES } from 'common/interfaces/enums';
import 'common/Common.scss';
import { chatActiveAtom } from 'home/atoms/AtomChatActive';

const Login = (): ReactElement => {
  const authenticationService = AuthenticationService.getInstance();
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const [email, setEmail] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [enableLoginButton, setEnableLoginButton] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const setModalController = useSetRecoilState(modalControllerAtom);
  const setChatActive = useSetRecoilState(chatActiveAtom);

  const emailRef = useRef<HTMLInputElement>(null);
  const EMAIL_REGEX =
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(_[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$';
  const GREY_COLOR = '#667085';

  useEffect(() => {
    if (enableLoginButton) {
      const keyDownHandler = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
          event.preventDefault();
          loginAction();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [enableLoginButton]);

  useEffect(() => {
    setInvalidData(false);
    if (email !== undefined && email.trim() !== '' && isPassword) {
      setEnableLoginButton(true);
    } else {
      setEnableLoginButton(false);
    }
  }, [email, isPassword]);

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  const generateTitleTypography = (
    text: string,
    style?: CSSProperties
  ): ReactElement => {
    return (
      <TitleTypography
        key={`title-${Math.random()}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...{ ...style }
        }}
      >
        {text}
      </TitleTypography>
    );
  };

  const generateSubTitleTypography = (
    text: string,
    color?: string
  ): ReactElement => {
    const style = {
      justifyContent: 'flex-start',
      fontFamily: 'ft-system-regular',
      fontSize: '14px',
      color: color ?? '#344054',
      fontWeight: '400'
    };
    return generateTitleTypography(text, style);
  };

  const generateErrorMessage = (errorMessage: string): ReactElement => {
    return generateTitleTypography(errorMessage, {
      justifyContent: 'flex-start',
      fontFamily: 'ft-system-regular',
      fontSize: '14px',
      color: '#F04438',
      fontWeight: '400'
    });
  };
  const loginAction = (): void => {
    const inputPassword = passwordRef?.current?.value.trim();
    if (email !== '' && inputPassword !== undefined && inputPassword !== '') {
      GAUserEvent('LOGIN');
      authenticationService
        .authenticate(email, inputPassword)
        .then(() => {
          assistantApiSocketClient.setAuth(authenticationService.authToken);
          Cookie.set('token', authenticationService.authToken);
          setModalController({ type: MODAL_TYPES.CLOSE });
          setChatActive(true);
        })
        .catch((error: Error) => {
          setInvalidData(true);
          console.log(error);
        });
    }
  };

  return (
    <ModalComposed
      className="md-modal-wrapper"
      open={true}
      body={
        <Box style={{ height: '100%', width: '100%' }}>
          {generateTitleTypography("Let's get started")}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              gap: '20px'
            }}
          >
            <Box
              style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}
            >
              {generateSubTitleTypography('Email')}
              {generateInput(
                'text',
                'test@aizwei.com',
                (e: string) => {
                  triggerSetText(
                    typeof e === 'string' ? e : '',
                    setEmail,
                    'email',
                    setInvalidEmail,
                    EMAIL_REGEX
                  );
                },
                emailRef
              )}
              {invalidEmail &&
                generateErrorMessage('Please enter a valid email address')}
            </Box>
            <Box
              style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}
            >
              {generateSubTitleTypography('Password')}
              {generateInput(
                'password',
                'Password',
                (e: string) => {
                  if (typeof e === 'string' && e !== '' && !isPassword) {
                    setIsPassword(true);
                  } else if (e === '' && isPassword) {
                    setIsPassword(false);
                  }
                },
                passwordRef,
                () => {
                  loginAction();
                }
              )}
            </Box>
            <Box
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <a
                onClick={(e) => {
                  setModalController({ type: MODAL_TYPES.FORGOT_PASSWORD });
                }}
                style={{ color: GREY_COLOR }}
                href="#0"
              >
                {generateSubTitleTypography('Forgot Password?', GREY_COLOR)}
              </a>
              {invalidData &&
                generateSubTitleTypography(
                  'Some of the fields are not correct. Please try again.',
                  '#F04438'
                )}
              <CustomButton
                disabled={!enableLoginButton}
                focusRipple={false}
                onClick={() => {
                  loginAction();
                }}
                style={{
                  maxWidth: 'none'
                }}
                data-cy="login-button"
              >
                Login
              </CustomButton>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default Login;
