import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState, type ReactElement } from 'react';

import {
  ACCESS_TYPES,
  HOME_VIEW_TYPES,
  MODAL_TYPES,
  ModalSize,
  RESTRICTIONS
} from 'common/interfaces/enums';

import ModalComposed from 'common/modal/ModalComposed';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import { modalControllerAtom } from 'atoms/atomModalController';
import {
  USER_TRACKING_DETAILS_ACTIONS,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ExposeService } from 'services/ExposeService';

import type {
  Dataset,
  Model,
  NotifyBoxTypes,
  SaveUploadContext,
  SaveUploadedTypes
} from 'common/interfaces/interfaces';
import NotifyBox from 'common/notifyBox/NotifyBox';
import { GAUserEvent } from 'utils/utils';

import 'common/Common.scss';
import type { AssetsFormProps } from 'mySpace/components/assetsInputsByIntent/AssetsForm';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import './UploadManager.scss';
import { contextSelectionAtom } from './atoms/atomContextSelections';
import UploadAlert from './components/uploadAlert/UploadAlert';
import UploadContext from './components/uploadContext/UploadContext';
import UploadSave from './components/uploadSave/UploadSave';
import Stepper from './stepper/Stepper';
import { checkValidityOfSchemaContext } from './utils/utils';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';

//  TODO: Track the status of the uploaded file with the runId

export type ContextData = HTMLTextAreaElement[];

export interface WarrningUpload {
  title: string;
  body: string[];
  allowContinue: boolean;
}

const UploadManager = ({
  fileId,
  uri,
  runId
}: {
  fileId: string;
  uri: string;
  runId: string;
}): ReactElement => {
  const exposeService = ExposeService.getInstance();
  const authenticationService = AuthenticationService.getInstance();

  const modalController = useRecoilValue(modalControllerAtom);
  const resetAtomModalController = useResetRecoilState(modalControllerAtom);

  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const resetContextSelection = useResetRecoilState(contextSelectionAtom);
  const contextSelection = useRecoilValue(contextSelectionAtom);
  const access = authenticationService.getAccess();
  const sharedResources = authenticationService.accountRestrictionByKey(
    RESTRICTIONS.SHARED_RESOURCES
  );

  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [counter, setCounter] = useState(0);
  const valueCounter = useRef(0);

  const [uploadWarning, setUploadWarning] = useState<WarrningUpload>();
  const [steppTrackker, setSteppTrackker] = useState(0);
  const contextData = useRef<ContextData>([]);

  const [messageError, setMessageError] = useState<null | NotifyBoxTypes>(null);
  const [asset, setAsset] = useState<Model | Dataset | undefined>();

  const [name, setName] = useState<string>('');
  const nameRef = useRef<HTMLInputElement>(null);
  const [validName, setValidName] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<ACCESS_TYPES>(
    ACCESS_TYPES.PRIVATE
  );
  const [tags, setTags] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const filteredTags = tags.filter(
    (tag) => tag !== ACCESS_TYPES.TEMPORARILY_STORED
  );

  const collectedSaveData: SaveUploadedTypes = {
    name,
    description,
    visibility,
    tags: filteredTags
  };

  const approveSaveUpload = name !== '' && validName;
  const isDataset = userLocationVariable.current.includes(
    USER_TRACKING_LOCATION_NAMES.UPLOAD_DATASET
  );

  const assetsFormControllers: AssetsFormProps['inputControllers'] = {
    stateName: {
      name,
      setName,
      nameRef,
      validName,
      setValidName
    },
    stateDescription: {
      description,
      setDescription,
      descriptionRef
    },
    stateAccess: {
      access,
      visibility,
      setVisibility
    },
    stateTags: [tags, setTags],
    shared: Boolean(sharedResources?.defaultValue)
  };

  useEffect(() => {
    const statusInterval: NodeJS.Timer = setInterval(() => {
      valueCounter.current++;
      setCounter(valueCounter.current);
      (isDataset
        ? exposeService.getUserDatasets()
        : exposeService.getUserModels()
      )
        .then((response) => {
          if (response?.data !== undefined) {
            const found = response.data.find((asset) => {
              if (uri === undefined) {
                return fileId.includes(asset.name) && runId.includes(asset.id);
              }
              const start = uri.indexOf('temp/');
              const end = uri.indexOf('.csv');
              return uri
                .slice(start + 'temp/'.length, end)
                .includes(asset.name);
            });
            const foundSchema: Dataset['schema'] = (found as Dataset).schema;
            if (
              found !== undefined &&
              (found.context.length > 0 ||
                (foundSchema !== undefined && foundSchema.length > 0))
            ) {
              setName(found.name);
              setDisableContinueButton(false);
              setAsset(found);
              clearInterval(statusInterval);
            }
          }
        })
        .catch((error) => {
          clearInterval(statusInterval);
          setMessageError({ type: 'failed', message: error.message });
        });

      if (counter > 12) {
        clearInterval(statusInterval);
        setMessageError({
          type: 'failed',
          message:
            'Something went wrong when uploading your data, plase try again later'
        });
      }
    }, 10000);
    return () => {
      clearInterval(statusInterval);
      resetContextSelection();
    };
  }, []);

  const onSaveEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (steppTrackker === 2 && approveSaveUpload) {
        handleContinue();
      }
    }
  };

  const handleSave = (
    collectedSaveData:
      | SaveUploadedTypes
      | (SaveUploadedTypes & SaveUploadContext)
  ): void => {
    if (isDataset && asset !== undefined) {
      exposeService
        .updateDataset(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            GAUserEvent(
              `${userLocationVariable.current}_${
                'DATASET_' + USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
            resetAtomModalController();
            setViewRender({
              ...viewRender,
              type: HOME_VIEW_TYPES.LOAD_DATASETS,
              stored: viewRender.stored
            });
          } else {
            setMessageError({
              type: 'error',
              message: 'Dataset data not found'
            });
          }
        })
        .catch((error) => {
          const message =
            error.message !== '' ? error.message : 'Error saving dataset';
          setMessageError({ type: 'error', message });
        });
    } else if (asset !== undefined) {
      exposeService
        .updateModel(collectedSaveData, asset.id)
        .then(({ data }) => {
          if (data !== undefined) {
            GAUserEvent(
              `${userLocationVariable.current}_${
                'MODEL_' + USER_TRACKING_DETAILS_ACTIONS.SAVED
              }`
            );
            previousModal({ uploadedId: asset.id });
          } else {
            setMessageError({ type: 'error', message: 'Model data not found' });
          }
        })
        .catch((error) => {
          const message =
            error.message !== '' ? error.message : 'Error saving dataset';
          setMessageError({ type: 'error', message });
        });
    }
  };

  const handleContinue = (): void => {
    if (steppTrackker === 2 && approveSaveUpload) {
      const outputColumns: string[] = [];
      const descriptionColumns = contextSelection.map((existingSelection) => {
        const { checked, ...restSelection } = existingSelection;
        if (checked) {
          outputColumns.push(existingSelection.name);
        }
        restSelection.description = restSelection.description.trim();
        return restSelection;
      }, []);
      handleSave({
        ...collectedSaveData,
        outputColumns,
        descriptionColumns
      });
    } else if (asset !== undefined) {
      if (steppTrackker === 0) {
        setSteppTrackker(steppTrackker + 1);
        return;
      }
      const isMissing = checkValidityOfSchemaContext(
        contextSelection,
        setUploadWarning
      );
      if (!isMissing) {
        setSteppTrackker(steppTrackker + 1);
      }
    }
  };

  const closeModal = (): void => {
    if (!disableContinueButton) {
      resetAtomModalController();
    }
  };

  const previousModal = (addPayload = {}): void => {
    if (steppTrackker === 0) {
      setUserLocationVariable({
        ...userLocationVariable,
        current: userLocationVariable.previous,
        previous: userLocationVariable.current
      });
      resetAtomModalController();
      setViewRender({
        type: isDataset
          ? HOME_VIEW_TYPES.LOAD_DATASETS
          : HOME_VIEW_TYPES.LOAD_MODELS,
        stored: viewRender.stored
      });
    } else {
      if (messageError !== null) {
        setMessageError(null);
      }
      setSteppTrackker(steppTrackker - 1);
    }
  };

  const renderByStep = (): JSX.Element | null => {
    switch (steppTrackker) {
      case 0:
        return (
          <>
            <Box className="upload__content__title">
              Processing your dataset
              <Typography className="upload__content__description">
                Thank you for uploading your dataset. We are now proceeding with
                the process of loading and cleaning it before saving.
              </Typography>
            </Box>
            <Box className="upload__loading">
              <Box className="upload__loading__progress">
                {counter > 1 ? (
                  <CheckCircleOutlineIcon color="secondary" />
                ) : messageError !== null ? (
                  <CancelOutlinedIcon />
                ) : (
                  <LoadingDots />
                )}
                <Box>
                  <Typography>Link or Upload file</Typography>
                  <Box className="upload__loading__progress__details">
                    <Typography>Formatting file</Typography>|
                    <Typography>Removing empty columns</Typography>|
                    <Typography>Saving to cloud</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="upload__loading__progress">
                {counter > 2 ? (
                  <CheckCircleOutlineIcon color="secondary" />
                ) : messageError !== null ? (
                  <CancelOutlinedIcon />
                ) : (
                  <LoadingDots />
                )}
                <Box>
                  <Typography>Reading dataset</Typography>
                  <Box className="upload__loading__progress__details">
                    <Typography>Reviewing column values</Typography>|
                    <Typography>Statistically analysing data types</Typography>|
                    <Typography>Analysing missing data percentage</Typography>|
                    <Typography>Choosing imputation method</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="upload__loading__progress">
                {counter > 3 && asset !== undefined ? (
                  <CheckCircleOutlineIcon color="secondary" />
                ) : messageError !== null ? (
                  <CancelOutlinedIcon />
                ) : (
                  <LoadingDots />
                )}
                <Box>
                  <Typography>Cleaning Data</Typography>
                  <Box className="upload__loading__progress__details">
                    <Typography>
                      Extract features from date/time columns
                    </Typography>
                    |
                    <Typography>
                      Extract categories from date/time columns
                    </Typography>
                    |
                    <Typography>Format numerical columns to decimal</Typography>
                    |<Typography>Analysing and extract categories</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      case 1: {
        const preview = (asset as Dataset).schema;
        if (preview !== undefined) {
          return (
            <UploadContext
              title="Dataset context"
              preview={preview}
              contextData={contextData}
            />
          );
        }
        return null;
      }
      case 2:
        return (
          <UploadSave
            assetsFormControllers={assetsFormControllers}
            handleSave={() => {
              handleSave(collectedSaveData);
            }}
            saveOnEnter={onSaveEnter}
          />
        );
      default:
        return null;
    }
  };

  const headerComponent = (
    <Box
      style={{
        display: 'flex'
      }}
    >
      <Typography className="sm-modal-title-header">
        Adding new dataset
      </Typography>
      <CloseIcon
        style={{
          marginLeft: 'auto',
          cursor: 'pointer',
          opacity: disableContinueButton ? 0.5 : 1
        }}
        onClick={closeModal}
      />
    </Box>
  );

  const bodyComponent = (
    <>
      <Box className="upload">
        <Stepper
          active={steppTrackker}
          elements={['Preparing data', 'Context', 'Saving']}
        />
        <Box className="upload__content">{renderByStep()}</Box>
        {messageError !== null ? (
          <Box
            sx={{
              width: '50%',
              margin: '1em 1em 2em 0em'
            }}
          >
            <NotifyBox
              type={messageError.type}
              message={messageError.message}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );

  const footerComponent = (
    <Box id="upload-footer">
      <CustomButton variant="secondary" onClick={previousModal}>
        {steppTrackker !== 0 ? 'Previous' : 'Cancel'}
      </CustomButton>
      <CustomButton
        variant="primary"
        disabled={
          disableContinueButton || (steppTrackker === 2 && !approveSaveUpload)
        }
        onClick={handleContinue}
      >
        {steppTrackker === 2 ? 'Save' : 'Continue'}
      </CustomButton>
    </Box>
  );

  return (
    <>
      <ModalComposed
        open={modalController.type === MODAL_TYPES.UPLOAD_MANAGER}
        id="upload-manager-wrapper"
        size={ModalSize.COMPOSED_FULL}
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      {uploadWarning !== undefined ? (
        <UploadAlert
          title={uploadWarning.title}
          body={[...uploadWarning.body]}
          allowContinue={uploadWarning.allowContinue}
          continueUpload={() => {
            setUploadWarning(undefined);
            setSteppTrackker(steppTrackker + 1);
          }}
          returnToProcess={() => {
            setUploadWarning(undefined);
          }}
        />
      ) : null}
    </>
  );
};

export default UploadManager;
