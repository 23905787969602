import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  type CSSProperties,
  type ComponentType,
  type ReactElement,
  type ReactNode
} from 'react';

import type {
  AddNewMessageType,
  Blocks,
  BlocksAccessory,
  ChatComponent,
  CustomMessage,
  DataUrlResponse,
  InsightsMessage,
  MessageMetaData,
  ReceivedMessage,
  TypeAddNewMessage
} from './interfaces/messages';

import {
  BOT_COMPONENT,
  BOT_MESSAGE,
  COMPONENT_TYPE,
  LOADING_STATE,
  TYPES,
  USER_MESSAGE
} from './interfaces/enums';

import type { RegularComponentTypes } from 'common/interfaces/interfaces';

import {
  GAUserEvent,
  deepCopy,
  downloadDocumentFromUrl,
  formatBytes,
  includeURL
} from 'utils/utils';

import {
  generateBotMessageWithLink,
  getLinkedComponent,
  payloadParser
} from './utils/payloadUtils';

import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import Image from './components/Image/Image';
import LinkPreview from './components/LinkPreview';
import ProgressBar from './components/ProgressBar';
import ShowMore from './components/ShowMore';
import CustomTransition from './components/customTransition/CustomTransition';
import { MagicIsland } from './magicIsland/MagicIsland';

import { modalControllerAtom } from 'atoms/atomModalController';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { AssistantApiService } from 'chat/services/AssistantApiService';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import { getFileSize } from 'services/DownloadService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';

import audioSrc from 'assets/music/bellRing.mp3';
import useSound from 'use-sound';
import type { PlayFunction } from 'use-sound/dist/types';

import {
  TRACKED_USER_ACTION_OPTIMISE_FLOW,
  TRACKED_USER_ACTION_PREDICT_FLOW,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import {
  BACKEND_STRINGS,
  HOME_VIEW_TYPES,
  MODAL_TYPES
} from 'common/interfaces/enums';
import JSON5 from 'json5';
import { lastResponseAtom } from './atoms/atomLastResponse';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import './Chat.scss';

export interface BotComponentSubmit {
  addNewMessages: TypeAddNewMessage;
}

const catchAnimationFailed = (): void => {
  throw new Error('MessagesWithAnimation failed');
};

const MESSAGES: CustomMessage[] = [
  {
    id: uuidv4(),
    from: BOT_MESSAGE.MESSAGE,
    visible: true,
    message: 'Hi! 👋 How can I help you today?​'
  }
];

const pendingMessagesStack: MessageMetaData[] = [];
const messageAnimationTime = 800;

const Chat = (): ReactElement => {
  const [lastResponse, setLastResponse] = useRecoilState(lastResponseAtom);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);
  const setModalController = useSetRecoilState(modalControllerAtom);
  const setMagicMessage = useSetRecoilState(magicMessageDerived);

  const authenticationService = AuthenticationService.getInstance();
  const assistantApiSocketClient = AssistantApiSocketClient.getInstance();
  const assistantApiService = AssistantApiService.getInstance();

  const [messages, setMessages] = useState<CustomMessage[]>(MESSAGES);
  const [loading, setLoading] = useState<LOADING_STATE>(LOADING_STATE.IDLE);

  const timeoutIDs: NodeJS.Timeout[] = useMemo(() => [], []);

  const isMounted = useRef<boolean>(false);
  const animationInProgress = useRef<boolean>(false);
  const lastMessageIsCheckBox = useRef<boolean>(false);
  const messageBoxRef = useRef<HTMLDivElement>(null);
  const progressBarVisible = useRef({});
  const lastMessageDuplicate = useRef<boolean>(false);
  const messagesReceived = useRef<Array<Blocks | CustomMessage>>([]);
  const messagesProcessed = useRef<number[]>([]);

  const [play] = useSound(audioSrc);

  useEffect(() => {
    if (messageBoxRef?.current !== null) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      animationInProgress.current = false;
      if (timeoutIDs.length > 0) {
        timeoutIDs.forEach((element) => {
          clearTimeout(element);
        });
      }
      authenticationService.unauthenticate();
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (messages?.length === 1) {
      if (authenticationService.isAuthenticated()) {
        assistantApiService.startConversation();
      } else if (!authenticationService.isAuthenticated()) {
        assistantApiService.endConversation();
        authenticationService.unauthenticate();
      }
    }

    const lastMessage = messages[messages.length - 1];
    const preLastMessage = messages[messages.length - 2];

    if (messages.length > 2) {
      const preLastMessageBool = preLastMessage?.message === 'End analysis';
      const lastMessageBool =
        lastMessage?.component?.action_id === 'Start analysis';
      if ((preLastMessageBool && lastMessageBool) || lastMessageBool) {
        authenticationService.unauthenticate();
        assistantApiService.endConversation();
      }
    }

    if (
      lastMessageDuplicate.current &&
      compareLastReceivedResponses(
        messagesReceived.current[messagesReceived.current.length - 1]
      )(true)
    ) {
      // in this part of the code we are checking if the last message received from the backend was flagged as duplicate
      // in case it was flagged we will check the context of the user interaction to determine the legitimacy of the duplicate
      const lastUserMessages = messages.filter(
        (msg) => msg.from === USER_MESSAGE.MESSAGE
      );
      if (lastUserMessages.length > 2) {
        const lastUserMessage = lastUserMessages[lastUserMessages.length - 1];
        const preLastUserMessage =
          lastUserMessages[lastUserMessages.length - 2];
        if (lastUserMessage.message === preLastUserMessage.message) {
          lastMessageDuplicate.current = false;
          processReceivedBlocks({ response: lastResponse });
        }
      }
    }

    finishedProgressBar(preLastMessage, lastMessage);
    assistantApiSocketClient.addInteractiveMessageListener(
      receivedBlocksHandler
    );
    return () => {
      assistantApiSocketClient.removeInteractiveMessageListener(
        receivedBlocksHandler
      );
    };
  }, [messages]);

  const finishedProgressBar = (
    preLastMessage: CustomMessage,
    lastMessage: CustomMessage
  ): void => {
    // We disable the the previous response when it was a progress bar type response and it changes for the current message which means the prediction has finished.
    if (
      preLastMessage?.from === BOT_COMPONENT.PROGRESS_BAR &&
      lastMessage?.from === BOT_MESSAGE.MESSAGE &&
      preLastMessage?.component !== undefined
    ) {
      preLastMessage.component.disable = true;
    }
  };

  const createAnimatedMessages = async (
    messageData: MessageMetaData
  ): Promise<void> => {
    const { isMagicMessage, _messages, delay } = messageData;
    if (_messages.length === 0) return;
    if (animationInProgress.current) {
      pendingMessagesStack.push(messageData);
      return;
    }
    animationInProgress.current = true;
    let index = 0;
    for (const _message of _messages) {
      await new Promise(() => {
        const timeoutID: NodeJS.Timeout = setTimeout(() => {
          index++;
          animationInProgress.current = false;
          if (isMagicMessage !== undefined && isMagicMessage) {
            setMagicMessage(_message);
          } else {
            if (BOT_COMPONENT.PROGRESS_BAR === _message.component?.type) {
              setLoading(LOADING_STATE.PROCESSING_DATA);
            } else {
              setLoading(LOADING_STATE.WAITING_MESSAGE);
            }
            setMessages((prevMessages: CustomMessage[]) => {
              const oldMessages = prevMessages;
              if (
                _message.component !== undefined &&
                _message.component.type === BOT_COMPONENT.PROGRESS_BAR &&
                !Array.isArray(_message.component.options) &&
                _message.component.options !== undefined &&
                _message.component.options.progress !== '0'
              ) {
                oldMessages.pop();
              } else if (
                _message.complement?.showMore !== undefined &&
                Array.isArray(_message.complement?.showMore) &&
                _message.complement?.showMore.length > 0
              ) {
                const lastMessage = oldMessages.pop();
                if (lastMessage !== undefined) {
                  lastMessage.showMore = _message.complement.showMore;
                  return [...oldMessages, lastMessage];
                }
              }
              return [...oldMessages, _message];
            });
          }
          while (
            !animationInProgress.current &&
            pendingMessagesStack.length > 0 &&
            _messages.length === index
          ) {
            const pending = pendingMessagesStack.shift();
            if (pending !== undefined) {
              createAnimatedMessages(pending).catch(catchAnimationFailed);
            }
          }
        }, delay ?? messageAnimationTime);
        timeoutIDs.push(timeoutID);
      });
    }
  };

  const saveReceivedBlocks = (blocks: Blocks['blocks']): void => {
    for (let index = 0; index < blocks.length; index++) {
      const block = blocks[index];
      blocks[index] = payloadParser(
        block,
        {
          messagesProcessed,
          lastMessageIsCheckBox,
          setModalController,
          viewRenderController: [viewRender, setViewRender],
          userLocationController: [
            userLocationVariable,
            setUserLocationVariable
          ]
        },
        { blocks, index }
      );
    }

    if (Array.isArray(blocks) && blocks.length > 0) {
      const flatenedBlocks = blocks.flat(2);
      const foundExplanation = flatenedBlocks.find(
        (fl: { component: { alt_text: string } }) => {
          if (fl?.component?.alt_text !== undefined) {
            return fl.component.alt_text.startsWith('explanation');
          }
          return false;
        }
      );

      if (flatenedBlocks.length > 0 && foundExplanation === undefined) {
        const lastBlock = {
          isMagicComponent: false,
          component: flatenedBlocks[flatenedBlocks.length - 1]
        };

        if (lastBlock.component?.from === BOT_COMPONENT.COMPONENT) {
          lastBlock.isMagicComponent = true;
          flatenedBlocks.pop();
        }

        createAnimatedMessages({ _messages: flatenedBlocks }).catch(
          catchAnimationFailed
        );

        if (lastBlock.isMagicComponent) {
          createAnimatedMessages({
            isMagicMessage: true,
            _messages: [lastBlock.component]
          }).catch(catchAnimationFailed);
        }
      }
    }
  };

  const categorizeShowMessagesInBlocks = (
    blocks: Blocks['blocks']
  ): boolean => {
    let isShowMessage = false;
    if (Array.isArray(blocks)) {
      isShowMessage = blocks.every(
        (message: {
          accessory?: BlocksAccessory;
          visible: boolean;
          block_id?: string;
        }) => {
          if (
            message.accessory?.alt_text !== undefined &&
            message.accessory?.type === BOT_COMPONENT.IMAGE.toLowerCase()
          ) {
            message.visible = false;
            return true;
          }
          if (
            message.accessory?.action_id ===
              BACKEND_STRINGS.PRESENT_TIMEOUT_1_BUTTONS ||
            message?.block_id === BACKEND_STRINGS.START_ANALYSIS_MENU_OPTION
          ) {
            setModalController({ type: MODAL_TYPES.CLOSE });
          }
          return false;
        }
      );
    }
    if (isShowMessage) {
      const payloadMessage = {
        type: TYPES.CONTEXT,
        [COMPONENT_TYPE.COMPLEMENT]: true,
        showMore: blocks
      };
      const parsedBlock = payloadParser(payloadMessage, {
        messagesProcessed,
        lastMessageIsCheckBox,
        setModalController,
        viewRenderController: [viewRender, setViewRender],
        userLocationController: [userLocationVariable, setUserLocationVariable]
      });
      createAnimatedMessages({ _messages: parsedBlock }).catch(
        catchAnimationFailed
      );
    }
    return isShowMessage;
  };

  const saveReceivedMessage = (
    response: Blocks,
    newMessage?: CustomMessage
  ): void => {
    const messageReceived = newMessage ?? response;
    localStorage.setItem('file_correlations', 'FALSE');
    setLastResponse(response);
    messagesReceived.current = [...messagesReceived.current, messageReceived];
    if (messagesReceived.current.length > 0) {
      messagesReceived.current.forEach((element, index: number) => {
        const messageProcessed: boolean =
          messagesProcessed.current.includes(index);
        if (!messageProcessed && element !== undefined) {
          messagesProcessed.current = [...messagesProcessed.current, index];
          if (
            'blocks' in element &&
            Array.isArray(element.blocks) &&
            element.blocks.length > 0
          ) {
            const blocks = deepCopy(element.blocks);
            const isShowMessage = categorizeShowMessagesInBlocks(blocks);
            if (!isShowMessage) {
              saveReceivedBlocks(blocks);
            }
          } else if ('base64' in element) {
            createAnimatedMessages({ _messages: [element] }).catch(
              catchAnimationFailed
            );
          }
        }
      });
    }
  };

  const saveReceivedUrl = async (message: ReceivedMessage): Promise<void> => {
    const messageResponse = message.response as DataUrlResponse;
    let fileName: string = messageResponse.data_link.split('/').pop() ?? '';
    const documentUrl = messageResponse.data_link;
    const description = messageResponse.description ?? '';
    const modelName = messageResponse.modelName ?? '';
    const targetVariable = messageResponse.targetVariable ?? '';
    const features = messageResponse.features ?? '';

    const fileSizeString = await getFileSize(messageResponse.data_link)
      .then((fileSize) => {
        if (fileSize >= 0) {
          return formatBytes(fileSize);
        }

        return 'Unknown size';
      })
      .catch((error) => {
        console.log(error);
        return 'Unknown size';
      });

    if (targetVariable !== '') {
      const bulkPredictionMessage: CustomMessage = {
        id: uuidv4(),
        from: BOT_MESSAGE.OPEN_MODAL,
        title: 'Open the insights to view your predictions',
        message: 'Analysis Predictions',
        visible: true,
        style: {},
        disable: false,
        icon: BarChartOutlinedIcon,
        base64: false,
        action: async () => {
          GAUserEvent(
            `${userLocationVariable.current}_${TRACKED_USER_ACTION_PREDICT_FLOW.BULK_PREDICTIONS}`
          );
          setViewRender({
            type: HOME_VIEW_TYPES.BULK_PREDICTIONS,
            payload: {
              asset: {
                description,
                targetVariable,
                modelName,
                documentUrl,
                features
              }
            },
            stored: viewRender.stored
          });
        }
      };
      saveReceivedMessage({ blocks: [] }, bulkPredictionMessage);
    }

    fileName =
      fileName.length > 23 ? `${fileName.substring(0, 20)}...` : fileName;
    const fileMessage: CustomMessage = {
      id: uuidv4(),
      from: BOT_MESSAGE.FILE,
      message: fileSizeString.toString(),
      visible: true,
      style: {},
      title: fileName,
      disable: false,
      icon: InsertDriveFileOutlinedIcon,
      base64: false,
      action: async () => {
        downloadDocumentFromUrl(documentUrl, fileName);
      }
    };
    saveReceivedMessage({ blocks: [fileName] }, fileMessage);
  };

  // Marked as any type because this message doesn't follow the blocks format we had
  const saveInsightsMessage = (insights: InsightsMessage): void => {
    const insightsMessage: CustomMessage = {
      id: uuidv4(),
      from: BOT_MESSAGE.OPEN_MODAL,
      title: 'Your optimise insights are ready',
      message: 'Open Insights',
      visible: true,
      style: {},
      disable: false,
      icon: BarChartOutlinedIcon,
      base64: false,
      action: async () => {
        GAUserEvent(
          `${userLocationVariable.current}_${TRACKED_USER_ACTION_OPTIMISE_FLOW.OPEN_INSIGHTS}`
        );
        setUserLocationVariable({
          ...userLocationVariable,
          current:
            userLocationVariable.current +
            '_' +
            USER_TRACKING_LOCATION_NAMES.INSIGHTS,
          previous: userLocationVariable.current
        });
        setViewRender({
          type: HOME_VIEW_TYPES.INSIGHTS,
          payload: insights,
          stored: viewRender.stored
        });
      }
    };
    saveReceivedMessage({ blocks: [] }, insightsMessage);
  };

  const processReceivedBlocks = (msg: ReceivedMessage): void => {
    if (msg.mimetype === 'url') {
      void saveReceivedUrl(msg);
    } else if (msg.mimetype === 'insights' && 'insights' in msg.response) {
      saveInsightsMessage(msg.response.insights);
    } else if ('blocks' in msg.response && msg.response.blocks.length > 0) {
      saveReceivedMessage(msg.response);
    } else if (
      'accessory' in msg.response &&
      msg.response.accessory?.type ===
        BOT_COMPONENT.MULTI_SELECT.toLowerCase() &&
      typeof msg.response.accessory?.options === 'string'
    ) {
      const parsedOptions: ChatComponent['options'] = JSON5.parse(
        msg.response.accessory.options
      );
      msg.response.accessory.options = parsedOptions;
      if (typeof msg.response.accessory.options !== 'string') {
        const multiSelectMessage = {
          id: uuidv4(),
          from: BOT_COMPONENT.COMPONENT,
          visible: true,
          component: msg.response.accessory,
          title: msg.response.accessory.text
        };
        setMagicMessage(multiSelectMessage as CustomMessage);
      }
    }
  };

  const compareLastReceivedResponses = (
    receivedResponse: ReceivedMessage['response'] | CustomMessage
  ): ((notExtend?: boolean) => boolean) => {
    const lastMessageStorage =
      localStorage.getItem('lastMessageStorage') ??
      JSON.stringify(lastResponse);
    const messageString = JSON.stringify(receivedResponse);
    let isEqualMessage = messageString === lastMessageStorage;
    return (notExtend = false) => {
      if (!notExtend) {
        if (isEqualMessage && receivedResponse instanceof ArrayBuffer) {
          const dataStorage =
            localStorage.getItem('file_correlations') ?? 'FALSE';
          isEqualMessage = !(dataStorage === 'FALSE');
        }

        if (isEqualMessage && messageString.includes('link')) {
          isEqualMessage = false;
        }
        return isEqualMessage;
      }
      return isEqualMessage;
    };
  };

  const receivedBlocksHandler = (msg: ReceivedMessage): void => {
    const isLastAckedMessage: boolean =
      assistantApiService.isLastAckedMessage(msg);
    assistantApiService.sendAckMessage(msg);
    if (
      !isLastAckedMessage &&
      msg.response !== null &&
      !('result' in msg.response)
    ) {
      lastMessageDuplicate.current =
        lastResponse.blocks.length > 0
          ? compareLastReceivedResponses(msg.response)()
          : false;
      localStorage.setItem('lastMessageStorage', JSON.stringify(msg.response));
      if (!lastMessageDuplicate.current && msg.code === 200) {
        processReceivedBlocks(msg);
      }
    }
  };

  const addNewMessages: TypeAddNewMessage = (
    newMessage: string | AddNewMessageType,
    type: USER_MESSAGE,
    visibleMessage?: boolean
  ): void => {
    const lMessages: CustomMessage[] = [];
    const messageIsNotObj = typeof newMessage === 'string';
    let _newMessage;
    let visible = true;

    if (messageIsNotObj) {
      _newMessage = newMessage;
    } else {
      _newMessage = newMessage.messages ?? newMessage?.value;
    }

    if (!messageIsNotObj && newMessage.visible !== undefined) {
      visible = !!newMessage.visible;
    } else if (visibleMessage !== undefined) {
      visible = visibleMessage;
    }

    if (
      type === USER_MESSAGE.MESSAGE &&
      !messageIsNotObj &&
      newMessage.valueObject !== undefined
    ) {
      lMessages.push({
        id: uuidv4(),
        from: USER_MESSAGE.FILE,
        message: _newMessage,
        visible,
        icon: InsertDriveFileOutlinedIcon,
        title: newMessage?.title
      });
    } else if (type === USER_MESSAGE.MESSAGE) {
      lMessages.push({
        id: uuidv4(),
        from: USER_MESSAGE.MESSAGE,
        message: _newMessage,
        visible
      });
      if (
        !messageIsNotObj &&
        Array.isArray(newMessage.messages) &&
        newMessage.messages.length > 0
      ) {
        newMessage.messages.forEach((ms: CustomMessage) => {
          lMessages.push({
            id: uuidv4(),
            from: ms.from,
            message: ms.message,
            visible: ms.visible,
            title: ms?.title,
            icon: ms?.icon,
            component: ms?.component
          });
        });
      }
    } else {
      lMessages.push({
        id: uuidv4(),
        from: BOT_MESSAGE.MESSAGE,
        message: _newMessage,
        visible
      });
    }
    if (lMessages.length > 0) {
      createAnimatedMessages({ _messages: lMessages }).catch(
        catchAnimationFailed
      );
    }
  };

  const generateWrappedModalMessage = (
    WrapperComponent: ComponentType<RegularComponentTypes>,
    message: CustomMessage
  ): ReactElement => {
    const CUSTOM_ICON: undefined | ComponentType<{ style: CSSProperties }> =
      message.icon;
    let iconComponent = <></>;

    if (CUSTOM_ICON !== undefined) {
      iconComponent = (
        <div className="message-cicrled-iconComponent">
          <CUSTOM_ICON style={{}} />
        </div>
      );
    }

    return (
      <WrapperComponent
        key={uuidv4()}
        style={{ marginTop: '-10px', alignItems: 'flex-start' }}
      >
        {iconComponent}
        <div
          style={{
            flexDirection: 'column-reverse',
            paddingLeft: '10px',
            alignItems: 'flex-start'
          }}
        >
          <div className="user-message-2body">{message.title}</div>
          <CustomButton onClick={message.action}>
            {message.message}
          </CustomButton>
        </div>
      </WrapperComponent>
    );
  };

  const generateWrappedMessage = (
    WrapperComponent: ComponentType<RegularComponentTypes>,
    message: CustomMessage,
    messageType: 'link' | 'file'
  ): ReactElement => {
    const CUSTOM_ICON: undefined | ComponentType<{ style: CSSProperties }> =
      message.icon;
    let iconComponent = <></>;
    const isFileType = messageType === 'file';

    if (CUSTOM_ICON !== undefined) {
      iconComponent = (
        <div style={{ flexDirection: 'row' }}>
          {isFileType ? (
            <CustomButton onClick={message.action}>
              <div
                style={{
                  width: '35px',
                  height: '35px',
                  left: '0px',
                  top: '8.5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255, 255, 255, 0.4)',
                  border: '4px solid rgba(255, 255, 255, 0.2)',
                  borderRadius: '28px'
                }}
              >
                <CUSTOM_ICON
                  style={{
                    transform: 'scale(0.9)',
                    color: '#ffffff'
                  }}
                />
              </div>
            </CustomButton>
          ) : (
            <CUSTOM_ICON style={{ transform: 'rotate(-45deg) scale(1.25)' }} />
          )}
        </div>
      );
    }

    return (
      <WrapperComponent key={uuidv4()} style={{ marginTop: '-10px' }}>
        {iconComponent}
        <div style={{ flexDirection: 'column' }}>
          <div
            className={isFileType ? 'user-message-2body' : 'bot-message-2body'}
          >
            {message.title}
          </div>
          {isFileType ? (
            <div
              className="user-message-2body"
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                textDecorationColor: '#ffffff'
              }}
            >
              {message.message}
            </div>
          ) : (
            <Link
              className="bot-message-2link"
              style={{
                fontSize: '12px',
                lineHeight: '18px',
                textDecorationColor: '#ffffff'
              }}
              href={message.message}
              target="_blank"
            >
              {message.message}
            </Link>
          )}
        </div>
      </WrapperComponent>
    );
  };

  const generateWrappedComponentByMessage = (
    WrapperComponent: ComponentType<RegularComponentTypes> | null,
    message: CustomMessage,
    index: number
  ): ReactElement => {
    let _finalComponent: ReactNode | null = <></>;
    const messageFrom = message.from ?? '';
    if (
      messageFrom === BOT_COMPONENT.PROGRESS_BAR &&
      message.component?.type !== undefined &&
      WrapperComponent !== null
    ) {
      const customComp = generateCustomComponent(
        ProgressBar,
        message.component,
        index,
        {
          options: message.component?.options,
          sound: play,
          style: { minWidth: '50%' },
          componentprogressBarIndexes: progressBarVisible.current,
          visibleMessage: false
        }
      );
      _finalComponent = (
        <WrapperComponent keys={uuidv4()}>{customComp}</WrapperComponent>
      );
    } else if (
      messageFrom === BOT_COMPONENT.IMAGE &&
      message.component?.type !== undefined &&
      WrapperComponent !== null
    ) {
      const customComp = generateCustomComponent(
        Image,
        message.component,
        index,
        {
          options: message.component?.options,
          style: {
            minWidth: '50%',
            marginTop: '-15px'
          }
        }
      );
      _finalComponent = (
        <WrapperComponent keys={uuidv4()}>{customComp}</WrapperComponent>
      );
    } else if (
      messageFrom === BOT_MESSAGE.MESSAGE_2 &&
      message.title !== undefined &&
      WrapperComponent !== null
    ) {
      _finalComponent = generateWrappedMessage(
        WrapperComponent,
        message,
        'link'
      );
    } else if (
      (messageFrom === USER_MESSAGE.FILE || messageFrom === BOT_MESSAGE.FILE) &&
      message.title !== undefined &&
      WrapperComponent !== null
    ) {
      _finalComponent = generateWrappedMessage(
        WrapperComponent,
        message,
        'file'
      );
    } else if (
      messageFrom === BOT_MESSAGE.OPEN_MODAL &&
      message.title !== undefined &&
      WrapperComponent !== null
    ) {
      _finalComponent = generateWrappedModalMessage(WrapperComponent, message);
    } else if (
      messageFrom === BOT_MESSAGE.LINK_PREVIEW &&
      message.message !== undefined &&
      WrapperComponent !== null
    ) {
      const messageTextBool: boolean = message.message.includes(
        'preparing-your-data'
      );
      const orientation = messageTextBool ? 'horizontal' : 'vertical';
      _finalComponent = (
        <WrapperComponent keys={uuidv4()} style={message.style}>
          <LinkPreview url={message.message} orientation={orientation} />
        </WrapperComponent>
      );
    } else if (
      messageFrom === BOT_MESSAGE.MESSAGE &&
      message.message !== undefined &&
      WrapperComponent !== null
    ) {
      if (message.showMore !== undefined) {
        _finalComponent = (
          <ShowMore
            CustomComponent={WrapperComponent}
            message={message}
            showMore={message.showMore}
          />
        );
      } else {
        const newMessages: string[] = generateBotMessageWithLink(
          message.message
        );
        _finalComponent = (
          <WrapperComponent
            keys={uuidv4()}
            style={{ display: includeURL(message.message) ? 'block' : 'flex' }}
          >
            {newMessages.map((element: string) => {
              if (!includeURL(element)) {
                return (
                  <React.Fragment key={uuidv4()}>{element}</React.Fragment>
                );
              } else {
                return (
                  <Link
                    className="bot-message-2link"
                    href={element}
                    key={uuidv4()}
                    {...(message.action !== undefined
                      ? {
                          onClick: (e: React.MouseEvent) => {
                            e.preventDefault();
                            if (message.action !== undefined) {
                              message.action();
                            }
                          }
                        }
                      : { target: '_blank' })}
                  >
                    {element}
                  </Link>
                );
              }
            })}
          </WrapperComponent>
        );
      }
    } else if (WrapperComponent !== null) {
      _finalComponent = (
        <WrapperComponent keys={uuidv4()} style={message?.style}>
          {message.message}
        </WrapperComponent>
      );
    }
    return _finalComponent;
  };

  const generateCustomComponent = (
    CustomComponent: ComponentType<RegularComponentTypes>,
    componentData: ChatComponent,
    index: number,
    passedProps?: {
      keys?: string;
      options?: ChatComponent['options'];
      sound?: PlayFunction;
      style?: CSSProperties;
      componentprogressBarIndexes?: object;
      visibleMessage?: boolean;
    }
  ): ReactElement => {
    return (
      <CustomComponent
        key={passedProps?.keys ?? `${componentData.type}-${index}`}
        title={componentData.title}
        disabled={componentData.disable}
        index={index}
        {...passedProps}
      />
    );
  };

  const generateMessages = (): ReactElement => {
    let lastMessage: ReactElement | undefined;
    const _messagesVisibles = deepCopy(messages);
    const retriveMessageLinkedComponent = (
      message: CustomMessage,
      index: number
    ): ReactElement => {
      const WrapperComponent = getLinkedComponent(message.from);
      return (
        <div key={uuidv4()}>
          {generateWrappedComponentByMessage(WrapperComponent, message, index)}
        </div>
      );
    };

    if (_messagesVisibles.length > 1) {
      const messagesLastIndex = _messagesVisibles.length - 1;
      lastMessage = retriveMessageLinkedComponent(
        _messagesVisibles[messagesLastIndex],
        messagesLastIndex
      );
      _messagesVisibles.pop();
    }

    const lMessages2 = _messagesVisibles.map((ms, index: number) => {
      return retriveMessageLinkedComponent(ms, index);
    });

    return (
      <TransitionGroup>
        <CustomTransition
          component={lMessages2}
          duration={messageAnimationTime}
          key={`${_messagesVisibles.length}-${messageAnimationTime}`}
          animation={'move-up'}
        />
        {lastMessage !== undefined && (
          <CustomTransition
            component={lastMessage}
            key={`${_messagesVisibles.length}`}
            animation={'fade-in'}
            styled={{ opacity: 0 }}
            transitionStyles={{}}
            delay={messageAnimationTime}
          />
        )}
      </TransitionGroup>
    );
  };

  return (
    <>
      <div id="chat-column">
        <div
          id="message-box"
          ref={messageBoxRef}
          style={{
            marginBottom: '12px'
          }}
        >
          {messages?.length > 0 && generateMessages()}
        </div>
        <MagicIsland addNewMessages={addNewMessages} loading={loading} />
      </div>
    </>
  );
};

export default Chat;
