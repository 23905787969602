import { ChevronLeft } from '@mui/icons-material';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  type SelectChangeEvent
} from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import type {
  NumericalTransformationRange,
  SendTransformation
} from 'common/interfaces/interfaces';
import {
  MENU,
  type MutableNumericalRefs,
  SUB_MENU,
  TEXTUAL_GROUPING,
  TEXTUAL_GROUPING_TYPE,
  columnNameRegex,
  numberRegex,
  type IColumnSettingsProps,
  type NumericalColumn,
  type NumericalType
} from 'featureEngineering/featureEngineeringInterface';
import { FeatureType } from 'playground/interfaces/playground';
import { createRef, useEffect, useRef, useState } from 'react';
import { nameRegex } from 'utils/utils';
import TextualAgrupationExamples from './components/TextualAgrupationExamples';

import 'common/Common.scss';
import CustomButton from 'common/button/CustomButton';
import { ICON_TYPE } from 'common/interfaces/enums';
import '../SubmenusCommon.scss';
import './GroupCategories.scss';

const buildPlaceHolderSampleData = (
  sampleData: NumericalTransformationRange
): NumericalType['placeHolder'] => {
  if ('min' in sampleData && 'max' in sampleData) {
    return {
      first: `${sampleData.min}`,
      second: `${sampleData.max}`,
      colName: `colname_bing_${sampleData.min}-${sampleData.max}`
    };
  }
  return {
    first: '',
    second: '',
    colName: 'colname'
  };
};

const GroupCategories = ({
  rowsNames,
  selectedMenu,
  setSelectedMenu,
  updateTransformState
}: IColumnSettingsProps): JSX.Element | null => {
  const transformColumnData = selectedMenu.columnData.data;
  const columnsType: FeatureType = transformColumnData.dataValue;

  const [categoryBySelected, setCategoryBySelected] = useState<NumericalType[]>(
    []
  );
  const [name, setName] = useState<string>('');
  const [validName, setValidName] = useState<boolean>(false);
  const [validTextualData, setValidTextualData] = useState<
    Record<string, boolean>
  >({});
  const [numericalMissingData, setNumericalMissingData] =
    useState<boolean>(true);

  const inputTextualAgrupationReach = useRef({ value: '' });
  const [textualAgrupationReach, setTextualAgrupationReach] =
    useState<string>('');

  const [textualAgrupation, setTextualAgrupation] = useState<TEXTUAL_GROUPING>(
    TEXTUAL_GROUPING.FIRST
  );
  const [textualAgrupationType, setTextualAgrupationType] =
    useState<TEXTUAL_GROUPING_TYPE>(TEXTUAL_GROUPING_TYPE.WORDS);

  const isError = name.length > 0 && !validName;

  const hasInvalidTextual = Boolean(
    Object.keys(validTextualData).find((key) => {
      if (!validTextualData[key]) {
        return true;
      }
      return false;
    })
  );

  const allowSaving =
    (columnsType === FeatureType.NUMERICAL && numericalMissingData) ||
    !validName ||
    (columnsType === FeatureType.TEXTUAL && textualAgrupationReach === '');

  const minValue = Number(
    (selectedMenu.columnData.data.sampleData as NumericalTransformationRange)
      .min ?? 0
  );
  const maxValue = Number(
    (selectedMenu.columnData.data.sampleData as NumericalTransformationRange)
      .max ?? 0
  );

  useEffect(() => {
    if (
      columnsType === FeatureType.NUMERICAL &&
      typeof transformColumnData.sampleData === 'object' &&
      !Array.isArray(transformColumnData.sampleData)
    ) {
      setCategoryBySelected([
        {
          first: createRef(),
          second: createRef(),
          colName: createRef(),
          placeHolder: buildPlaceHolderSampleData(
            transformColumnData.sampleData
          )
        }
      ]);
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (columnsType === FeatureType.NUMERICAL) {
      let updated = false;
      // Check if the values are between the min and max values and update them if they are not
      const mappedCategory = categoryBySelected.map(
        (numerical: NumericalType) => {
          const { first, second } = numerical;
          if (
            first?.current?.value !== '' &&
            first?.current?.value !== undefined
          ) {
            updated = checkMinMaxValues(first);
          }
          if (
            second?.current?.value !== '' &&
            second?.current?.value !== undefined
          ) {
            updated = checkMinMaxValues(second);
          }
          return numerical;
        }
      );
      if (updated) {
        setCategoryBySelected(mappedCategory);
      }
    }
  }, [categoryBySelected]);

  const onEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!allowSaving) saveGroupinginState();
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
    if (
      rowsNames !== undefined &&
      rowsNames?.length > 0 &&
      rowsNames?.includes(event.target.value)
    ) {
      setValidName(false);
    } else {
      setValidName(
        event.target.value !== '' ? nameRegex.test(event.target.value) : false
      );
    }
  };

  const saveGroupinginState = (): void => {
    const values: SendTransformation = {
      action: MENU.GROUP_CATEGORIES.toLowerCase(),
      type: selectedMenu.columnData.data.dataValue,
      column: selectedMenu.columnData.name,
      params: {
        new_column_name: name ?? selectedMenu.columnData.name,
        numerical: columnsType === FeatureType.NUMERICAL,
        update_column: false
      }
    };
    if (selectedMenu !== undefined && values.params !== undefined) {
      if (columnsType === FeatureType.TEXTUAL) {
        values.params.length = Number(textualAgrupationReach);
        values.params.position = textualAgrupation;
        values.params.type = textualAgrupationType;
      } else if (columnsType === FeatureType.NUMERICAL) {
        categoryBySelected.forEach((numerical: NumericalType) => {
          const { colName, first, second } = numerical;
          if (
            colName?.current?.value !== undefined &&
            Boolean(colName.current.value) &&
            first?.current?.value !== undefined &&
            second?.current?.value !== undefined
          ) {
            const numberFirst = Number(first.current.value);
            const numberSecond = Number(second.current.value);
            if (
              !isNaN(numberFirst) &&
              !isNaN(numberSecond) &&
              values.params !== undefined
            ) {
              values.params[colName.current.value] = [
                numberFirst,
                numberSecond
              ];
            }
          }
        });
      }
      updateTransformState(
        selectedMenu.selected,
        SUB_MENU.ADD_GROUP_CATEGORIES,
        values
      );
    }
  };

  const checkMinMaxValues = (refValue: MutableNumericalRefs): boolean => {
    if (
      refValue?.current?.value !== undefined &&
      Number(refValue.current.value) < minValue
    ) {
      refValue.current.value = String(minValue);
      return true;
    } else if (
      refValue?.current?.value !== undefined &&
      Number(refValue.current.value) > maxValue
    ) {
      refValue.current.value = String(maxValue);
      return true;
    }
    return false;
  };

  const updateNumericalColumns = (
    localGroupingData: NumericalType,
    index: number,
    columnIsNotMissingValues: boolean,
    remove = false
  ): void => {
    const numericalColumns = [...categoryBySelected];
    if (!remove && !columnIsNotMissingValues && !numericalMissingData) {
      setNumericalMissingData(true);
    }
    const firstHalf = numericalColumns.slice(0, index);
    if (!remove) {
      firstHalf.push(localGroupingData);
    }
    const secondHalf = numericalColumns.slice(index + 1);
    setCategoryBySelected([...firstHalf, ...secondHalf]);
  };

  const handleUpdateColumns = (
    currentColumnRef: { value: string } | null,
    isNumerical = true
  ): boolean => {
    // This function will check if the value of the current column is valid
    if (
      typeof currentColumnRef?.value === 'string' &&
      currentColumnRef.value !== ''
    ) {
      let { value } = currentColumnRef;

      const checkByRegexes = (valueToTest: string): boolean => {
        if (isNumerical) {
          return numberRegex.test(valueToTest);
        } else {
          return columnNameRegex.test(valueToTest);
        }
      };

      const valueIsValid = checkByRegexes(value);

      if (!valueIsValid && isNumerical) {
        // If the updated column is numerical it will automatically apply the regex to the value
        let newValue = value.slice(0, value.length - 1);
        if (!checkByRegexes(newValue)) {
          newValue = '';
          for (let i = 0; i < value.length; i++) {
            if (checkByRegexes(value[i])) {
              newValue += value[i];
            }
          }
        }
        value = newValue;
      }
      if (valueIsValid) {
        currentColumnRef.value = value;
      }
      return valueIsValid;
    }
    return false;
  };

  const handleTextualFormUpdates = (
    event: SelectChangeEvent<TEXTUAL_GROUPING | TEXTUAL_GROUPING_TYPE>
  ): void => {
    const { value } = event.target;
    if (TEXTUAL_GROUPING.FIRST === value || TEXTUAL_GROUPING.LAST === value) {
      setTextualAgrupation(value);
    } else if (
      TEXTUAL_GROUPING_TYPE.WORDS === value ||
      TEXTUAL_GROUPING_TYPE.LETTERS === value
    ) {
      setTextualAgrupationType(value);
    }
  };

  const renderInputsByColumnDataType = (): JSX.Element => {
    switch (columnsType) {
      case FeatureType.NUMERICAL: {
        const numericalCategory = [...categoryBySelected];
        return (
          <>
            <p>Create column bins, add col ranges and category name</p>
            {numericalCategory.map(
              (groupingColumn: NumericalType, index: number) => {
                const columnIsNotMissingValues =
                  Boolean(groupingColumn.first?.current?.value) &&
                  Boolean(groupingColumn.second?.current?.value) &&
                  Boolean(groupingColumn.colName?.current?.value);

                const isIndexInValid =
                  validTextualData[index] !== undefined &&
                  !validTextualData[index];

                if (
                  numericalMissingData &&
                  columnIsNotMissingValues &&
                  index === numericalCategory.length - 1
                ) {
                  setNumericalMissingData(false);
                } else if (
                  !numericalMissingData &&
                  !columnIsNotMissingValues &&
                  index === numericalCategory.length - 1
                ) {
                  setNumericalMissingData(true);
                }

                return (
                  <Box key={`${index}-numerical`} className="numerical-columns">
                    <TextField
                      className="numerical-columns-first-child"
                      type="number"
                      inputRef={groupingColumn.first}
                      placeholder={String(
                        groupingColumn.placeHolder.first ?? minValue
                      )}
                      InputProps={{
                        inputProps: {
                          min: groupingColumn.placeHolder.first ?? minValue,
                          max: groupingColumn.placeHolder.second ?? maxValue
                        }
                      }}
                      onBlur={() => {
                        updateNumericalColumns(
                          groupingColumn,
                          index,
                          columnIsNotMissingValues
                        );
                      }}
                      onChange={() => {
                        if (groupingColumn.first?.current !== undefined) {
                          handleUpdateColumns(groupingColumn.first.current);
                        }
                      }}
                    />
                    <TextField
                      className="numerical-columns-second-child"
                      type="number"
                      inputRef={groupingColumn.second}
                      placeholder={String(
                        groupingColumn.placeHolder.second ?? maxValue
                      )}
                      InputProps={{
                        inputProps: {
                          min: groupingColumn.placeHolder.first ?? minValue,
                          max: groupingColumn.placeHolder.second ?? maxValue
                        }
                      }}
                      onBlur={() => {
                        updateNumericalColumns(
                          groupingColumn,
                          index,
                          columnIsNotMissingValues
                        );
                      }}
                      onChange={() => {
                        if (groupingColumn.second?.current !== undefined) {
                          handleUpdateColumns(groupingColumn.second.current);
                        }
                      }}
                    />
                    <TextField
                      className={`numerical-columns-third-child ${
                        isIndexInValid ? 'input-error' : ''
                      }`}
                      type="text"
                      inputRef={groupingColumn.colName}
                      placeholder={groupingColumn.placeHolder.colName}
                      value={groupingColumn.colName?.current?.value ?? ''}
                      onBlur={() => {
                        updateNumericalColumns(
                          groupingColumn,
                          index,
                          columnIsNotMissingValues
                        );
                      }}
                      onChange={() => {
                        if (groupingColumn.colName?.current !== undefined) {
                          const textualValidity = handleUpdateColumns(
                            groupingColumn.colName.current,
                            false
                          );
                          if (
                            !textualValidity &&
                            groupingColumn.colName.current?.value !== ''
                          ) {
                            setValidTextualData({
                              ...validTextualData,
                              [index]: false
                            });
                          } else {
                            setValidTextualData({
                              ...validTextualData,
                              [index]: true
                            });
                          }
                        }
                      }}
                    />
                    <CustomButton
                      disabled={numericalCategory.length === 1}
                      variant="secondary"
                      small={true}
                      style={{ alignSelf: 'center' }}
                      onClick={() => {
                        if (validTextualData[index] !== undefined) {
                          const { [index]: removedindex, ...rest } =
                            validTextualData;
                          setValidTextualData(rest);
                        }
                        updateNumericalColumns(
                          groupingColumn,
                          index,
                          columnIsNotMissingValues,
                          true
                        );
                      }}
                      icon={{
                        type: ICON_TYPE.MINUS,
                        position: 'left'
                      }}
                    />
                  </Box>
                );
              }
            )}
            <CustomButton
              variant="secondary"
              small={true}
              onClick={() => {
                const lastnumerical =
                  numericalCategory[numericalCategory.length - 1];
                if (
                  (lastnumerical.first?.current?.value !== '' ||
                    lastnumerical.placeHolder.first !== undefined) &&
                  (lastnumerical.second?.current?.value !== '' ||
                    lastnumerical.placeHolder.second !== undefined)
                ) {
                  let highestValue = Number(
                    lastnumerical.second?.current?.value ?? 0
                  );
                  if (Number(lastnumerical.placeHolder.second) > highestValue) {
                    highestValue = Number(lastnumerical.placeHolder.second);
                  }

                  let numberFirst =
                    Number(
                      lastnumerical.second?.current?.value ??
                        lastnumerical.placeHolder.second
                    ) + 1;
                  numberFirst =
                    numberFirst > maxValue ? highestValue : numberFirst;
                  if (numberFirst < minValue) {
                    numberFirst = minValue;
                  } else if (numberFirst > maxValue) {
                    numberFirst = maxValue;
                  }
                  let numberSecond =
                    highestValue > numberFirst ? highestValue : numberFirst + 1;

                  if (numberSecond < minValue) {
                    numberSecond = minValue;
                  } else if (numberSecond > maxValue) {
                    numberSecond = maxValue;
                  }

                  const placeHolder = {
                    first: `${numberFirst}`,
                    second: `${numberSecond}`,
                    colName: `colname_bing_${numberFirst}-${numberSecond}`
                  };

                  const inputReferences: NumericalColumn = {
                    first: createRef(),
                    second: createRef(),
                    colName: createRef()
                  };

                  setCategoryBySelected([
                    ...numericalCategory,
                    {
                      ...inputReferences,
                      placeHolder
                    }
                  ]);
                }
              }}
              icon={{
                type: ICON_TYPE.PLUS,
                position: 'left'
              }}
            />
          </>
        );
      }
      case FeatureType.TEXTUAL:
        return (
          <>
            <p>
              Create column bins, select the matching list of words or letters
              to create categories from
            </p>
            <Box className="grouping-textual-input">
              <TextField
                className="numerical-columns-third-child"
                type="text"
                variant="standard"
                InputProps={{
                  disableUnderline: true
                }}
                placeholder={'0'}
                inputRef={inputTextualAgrupationReach}
                onChange={() => {
                  handleUpdateColumns(inputTextualAgrupationReach.current);
                  setTextualAgrupationReach(
                    inputTextualAgrupationReach.current.value
                  );
                }}
              />
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  defaultValue={TEXTUAL_GROUPING.FIRST}
                  disableUnderline={true}
                  style={{ marginLeft: '10px' }}
                  onChange={handleTextualFormUpdates}
                >
                  <MenuItem key={'option-first'} value={TEXTUAL_GROUPING.FIRST}>
                    First
                  </MenuItem>
                  <MenuItem key={'option-last'} value={TEXTUAL_GROUPING.LAST}>
                    Last
                  </MenuItem>
                </Select>
                <Select
                  defaultValue={TEXTUAL_GROUPING_TYPE.WORDS}
                  disableUnderline={true}
                  style={{ marginLeft: '10px' }}
                  onChange={handleTextualFormUpdates}
                >
                  <MenuItem
                    key={'option-words'}
                    value={TEXTUAL_GROUPING_TYPE.WORDS}
                  >
                    Words
                  </MenuItem>
                  <MenuItem
                    key={'option-letters'}
                    value={TEXTUAL_GROUPING_TYPE.LETTERS}
                  >
                    Letters
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="grouping-example">
              <p>Example of grouping by type {textualAgrupationType}:</p>
              {TextualAgrupationExamples(textualAgrupationType)}
            </Box>
          </>
        );
      default:
        return <></>;
    }
  };

  return selectedMenu !== undefined ? (
    <>
      <Box className="transformation-selected">
        <Box>
          <CustomIcon type={selectedMenu.icon} />
          <p>{selectedMenu.text}</p>
        </Box>
        <ChevronLeft
          className="cursor-pointer"
          onClick={() => {
            setSelectedMenu({
              ...selectedMenu,
              selected: MENU.DATA_TRANSFORMATION
            });
          }}
        />
      </Box>
      <Box className="submenu-regular-wrapp">
        <Box className="submenu-regular-header">
          <Box className="submenu-regular-name">
            <TextField
              id="saveGroupingName"
              name="saveGroupingName"
              className={`${isError ? 'input-error' : ''}`}
              value={name}
              inputProps={{
                maxLength: 25
              }}
              onChange={onNameChange}
              onKeyUp={onEnter}
              variant="outlined"
              type="text"
              placeholder={'New column name'}
              autoComplete="off"
              data-cy="grouping-categories-save-name-input"
            />
          </Box>
        </Box>
        <Box className="grouping-body">{renderInputsByColumnDataType()}</Box>
      </Box>
      <Box className="submenu-regular-footer">
        {hasInvalidTextual || isError ? (
          <Typography className="errorMsg">Some columns are wrong</Typography>
        ) : (
          <></>
        )}
        <CustomButton
          disabled={hasInvalidTextual || allowSaving}
          variant="tertiary"
          onClick={saveGroupinginState}
        >
          Save
        </CustomButton>
      </Box>
    </>
  ) : null;
};

export default GroupCategories;
