import Input from 'common/Input';
import type { Dispatch, ReactElement, RefObject, SetStateAction } from 'react';

export const triggerSetText = (
  newText: string,
  setText: Dispatch<SetStateAction<string>>,
  type = 'email',
  setInvalidEmail: Dispatch<SetStateAction<boolean>>,
  regex?: string
): void => {
  if (regex !== undefined && regex !== '') {
    const newRegex = new RegExp(regex);
    if (newRegex.test(newText)) {
      if (type === 'email') {
        setInvalidEmail(false);
      }
      setText(newText);
    } else {
      if (type === 'email') {
        setInvalidEmail(true);
      }
      setText('');
    }
  } else {
    setText(newText?.trim());
    if (type === 'email') {
      setInvalidEmail(true);
    }
  }
};

export const generateInput = (
  inputType: 'number' | 'text' | 'password',
  placeholder: string,
  action: (e: string) => void,
  inputRef: RefObject<HTMLInputElement> | null,
  enterAction?: (e: React.KeyboardEvent<HTMLDivElement>) => void
): ReactElement => {
  return (
    <Input
      type={inputType}
      placeholder={placeholder}
      onChange={action}
      ref={inputRef}
      enterAction={enterAction}
    />
  );
};
