import { Box, Collapse, Typography } from '@mui/material';
import { useEffect, useRef, useState, type ReactElement } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';

import {
  ACCESS_TYPES,
  HOME_VIEW_TYPES,
  ICON_TYPE,
  MODAL_INTENTS,
  NUMBERS_STRINGS
} from 'common/interfaces/enums';

import type {
  Dataset,
  DatasetSchema,
  SaveUploadedTypes,
  SendCombineColumns,
  SendTransformation,
  TableData,
  TransformedData
} from 'common/interfaces/interfaces';

import { activeTransformViewAtom } from 'featureEngineering/atoms/atomTransformFeature';
import { transformFeatureStateAtom } from './atoms/atomTransformFeature';
import {
  TRANSFORM_COLUMN,
  type SelectedTransformationColumn
} from './featureEngineeringInterface';

import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';

import {
  createErrorNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import PreviewDataset, {
  type PreviewClickAction
} from 'featureEngineering/previewDataset/PreviewDataset';
import PreviewRows from 'featureEngineering/previewRows/PreviewRows';
import SwitchTransformer from 'mySpace/components/switchTransformer/SwitchTransformer';
import Save from 'mySpace/save/Save';
import { ExposeService } from 'services/ExposeService';
import { GAUserEvent } from 'utils/utils';
import TransformationSelectedMenu from './TransformationSelectedMenu';

import {
  USER_TRACKING_FENG_ACTIONS,
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import DefaultView from './SubMenus/DefaultView';
import NewColumn from './newColumn/NewColumn';
import { getTransformationState } from './utils/transformationUtils';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import 'common/Common.scss';
import './Transformation.scss';
import RenderViewComposed from 'home/components/renderView/RenderView';

const Transformation = (): ReactElement => {
  const transformationState = useRecoilValue(transformFeatureStateAtom);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const resetAtomTransform = useResetRecoilState(transformFeatureStateAtom);
  const [activeTransformView, setActiveTransformView] = useRecoilState(
    activeTransformViewAtom
  );
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);

  const transformedColumns = Object.keys(transformationState);
  const isModifyied = Boolean(transformedColumns.length);

  const payload = viewRender.payload;
  const assistantService = AssistantApiService.getInstance();
  const exposeService = ExposeService.getInstance();

  const [preview, setPreview] = useState<DatasetSchema[]>();
  const [previewError, setPreviewError] = useState<boolean>(false);
  const [rowsPreview, setRowsPreview] = useState<TableData>();
  const [previewLoading, setPreviewLoading] = useState<boolean>(true);
  const [datasetManagerOpen, setDatasetManagerOpen] = useState<boolean>(true);
  const [detailPreview, setDetailPreview] = useState<string>(
    NUMBERS_STRINGS.FIRST
  );
  const [selectedTransform, setSelectedTransform] =
    useState<SelectedTransformationColumn>();
  const [openSavingTransform, setOpenSavingTransform] = useState(false);
  const [newTransformationState, setNewTransformationState] = useState<
    Array<SendTransformation | SendCombineColumns>
  >([]);

  const dataSetData = useRef({
    datasetId: payload?.datasetId,
    originalDatasetId: payload?.originalDatasetId,
    datasetUrl: payload?.datasetUrl,
    dataManagementFlow: (payload?.dataManagementFlow as boolean) ?? false
  });

  const originalDataPreview = useRef<{
    rowsPreview: TableData | undefined;
    preview: DatasetSchema[] | undefined;
  }>({ rowsPreview: undefined, preview: undefined });

  useEffect(() => {
    if (
      !userLocationVariable.current.includes(
        USER_TRACKING_LOCATION_NAMES.FEATURE_ENGINEERING
      )
    ) {
      setUserLocationVariable({
        ...userLocationVariable,
        current: `${userLocationVariable.current}_${USER_TRACKING_LOCATION_NAMES.FEATURE_ENGINEERING}`,
        previous: userLocationVariable.current
      });
    }
    setPreviewLoading(true);
    // On regular datasets, the originalDatasetId is the same as the datasetId
    // On transformed datasets, the originalDatasetId is the id of the original dataset
    // and the datasetId is the id of the transformed dataset (view)
    const isView = payload?.originalDatasetId !== payload?.datasetId;

    void getPreviewData(isView);
    void getDatasetRows(isView);
    return () => {
      resetAtomTransform();
    };
  }, []);

  useEffect(() => {
    if (detailPreview === NUMBERS_STRINGS.SECOND && rowsPreview === undefined) {
      setPreviewLoading(false);
      createErrorNotification(
        'Dataset preview not available',
        setNotifyMessage
      );
    } else if (
      detailPreview === NUMBERS_STRINGS.FIRST &&
      preview === undefined &&
      previewError
    ) {
      setPreviewLoading(false);
      createErrorNotification(
        'Dataset preview not available',
        setNotifyMessage
      );
    }
  }, [detailPreview, previewError]);

  const getPreviewData = async (isView: boolean): Promise<void> => {
    const previewResponse = (
      isView
        ? await exposeService.getViewById(payload?.datasetId)
        : await exposeService.getDatasetById(payload?.datasetId)
    ) as Dataset;
    if (
      previewResponse?.schema !== undefined &&
      Array.isArray(previewResponse?.schema) &&
      previewResponse?.schema.length > 0
    ) {
      setPreview(previewResponse?.schema);
      originalDataPreview.current.preview = previewResponse?.schema;
      setPreviewLoading(false);
    } else {
      setPreview(undefined);
      setPreviewLoading(false);
      setPreviewError(true);
    }
  };

  const getDatasetRows = async (isView: boolean): Promise<void> => {
    const previewResponse = isView
      ? await exposeService.getViewRows(
          payload?.originalDatasetId,
          payload?.datasetId
        )
      : await exposeService.getDatasetRows(payload?.datasetId);

    if (
      previewResponse?.rows !== undefined &&
      previewResponse.rows.length > 0
    ) {
      originalDataPreview.current.rowsPreview = previewResponse;
      setRowsPreview({
        rows: previewResponse.rows,
        keys: previewResponse.keys
      });
    } else {
      setRowsPreview(undefined);
    }
  };

  const updateNewTransformState = (): void => {
    const newTransfomed = getTransformationState(transformationState);
    if (newTransfomed.length > 0) {
      setNewTransformationState(newTransfomed);
    }
    setOpenSavingTransform(true);
  };

  const handlePreviewSelect = ({ value, index }: PreviewClickAction): void => {
    if (preview !== undefined && value !== undefined) {
      const indexNumber = Number(index);
      const { dataValue, sampleData, type } = preview[indexNumber];
      setSelectedTransform({
        name: value,
        index: indexNumber,
        type,
        data: {
          dataValue,
          sampleData
        }
      });
      setActiveTransformView(TRANSFORM_COLUMN.EDIT_COLUMN);
    }
  };

  const closeModal = (): void => {
    setUserLocationVariable({
      ...userLocationVariable,
      current: userLocationVariable.previous,
      previous: userLocationVariable.current
    });
    setViewRender({
      type: HOME_VIEW_TYPES.CHAT,
      stored: viewRender.stored
    });
  };

  const handleReset = (): void => {
    setPreview(originalDataPreview.current.preview);
    setRowsPreview(originalDataPreview.current.rowsPreview);
    setSelectedTransform(undefined);
    resetAtomTransform();
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.RESET}`
    );
  };

  const handleNoSaved = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_FENG_ACTIONS.NO_SAVE}`
    );
    if (
      payload?.originalDatasetId !== undefined &&
      payload?.datasetId !== undefined
    ) {
      const collectedSaveData: SaveUploadedTypes & TransformedData = {
        name: `TR_NO_SAVE_${Date.now()}`,
        tags: [],
        description: '',
        visibility: ACCESS_TYPES.HIDDEN,
        transformations: newTransformationState
      };
      if (payload?.originalDatasetId !== payload?.datasetId) {
        exposeService
          .saveTransformedView(
            payload.originalDatasetId,
            payload.datasetId,
            collectedSaveData
          )
          .then(saveTransformedAssetSuccess)
          .catch(saveTransformedAssetError);
      } else {
        exposeService
          .saveTransformedDataset(payload.originalDatasetId, collectedSaveData)
          .then(saveTransformedAssetSuccess)
          .catch(saveTransformedAssetError);
      }
    }
    closeModal();
  };

  const saveTransformedAssetSuccess = (transformedData: {
    data: { viewUrl: string; id: string };
  }): void => {
    const { data } = transformedData;
    assistantService.continueConversationAfterSaveModal(
      MODAL_INTENTS.INTRODUCED_DATASET,
      false,
      { dataset_url: data.viewUrl, dataset_id: data.id },
      true
    );
  };

  const saveTransformedAssetError = (): void => {
    createErrorNotification(
      'Transformed dataset could not be processed',
      setNotifyMessage
    );
    assistantService.continueConversationAfterPreviewEdit(
      MODAL_INTENTS.INTRODUCED_FEATURE_ENGINEERING
    );
  };

  const scrollToBottom = (query: string): void => {
    const element: Element | null = document.querySelector(query);
    if (element !== null) {
      // A small delay to ensure the element is rendered
      setTimeout(() => {
        const lastChild: Element | null = element.lastChild as Element;
        if (lastChild !== null) {
          lastChild.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
  };

  const activeTransformColumn = (): ReactElement => {
    switch (activeTransformView) {
      case TRANSFORM_COLUMN.NEW_COLUMN:
        return (
          <>
            <NewColumn
              scrollNewColumnIntoView={() => {
                scrollToBottom('.preview-table tbody');
              }}
              columns={preview?.filter(
                (data) => data.dataValue === 'numerical'
              )}
              previewController={[preview, setPreview]}
              rowsController={[rowsPreview, setRowsPreview]}
            />
          </>
        );
      case TRANSFORM_COLUMN.EDIT_COLUMN:
        if (selectedTransform !== undefined) {
          return (
            <Box className="display-column">
              <Box className="transform-manager-selected-row">
                <p>
                  Selected: <strong>{selectedTransform.name}</strong>
                </p>
                <CloseIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedTransform(undefined);
                  }}
                />
              </Box>
              <TransformationSelectedMenu
                previewController={[
                  preview,
                  setPreview,
                  originalDataPreview.current.preview
                ]}
                rowsController={[
                  rowsPreview,
                  setRowsPreview,
                  originalDataPreview.current.rowsPreview
                ]}
                selectedColumnController={{
                  selectedTransform,
                  setSelectedTransform
                }}
              />
            </Box>
          );
        }
        return <DefaultView />;
      default:
        return <DefaultView />;
    }
  };

  const headerComponent = (
    <Box id="feature-engieering__header">
      <Typography className="header-title color-dark">
        Preview your dataset
      </Typography>
      <CloseIcon
        style={{
          marginLeft: 'auto',
          cursor: 'pointer'
        }}
        onClick={() => {
          if (
            !userLocationVariable.current.includes(
              USER_TRACKING_LOCATION_NAMES.MYSPACE
            )
          ) {
            assistantService.continueConversationAfterPreviewEdit(
              MODAL_INTENTS.INTRODUCED_FEATURE_ENGINEERING
            );
          }
          closeModal();
        }}
      />
    </Box>
  );

  const bodyComponent = (
    <Box id="feature-engieering__body">
      <Box className="transformation-head">
        <Typography className="transformation-head-title color-dark">
          Explore your dataset&apos;s structure at a glance. Effortlessly
          customise, filter, transform data, and even create new columns from
          existing ones or combinations.
        </Typography>
        <SwitchTransformer
          detailPreview={detailPreview}
          setDetailPreview={setDetailPreview}
        />
      </Box>
      <Box className="transformation-block">
        <Box
          className={
            previewLoading
              ? 'transformation-block__loading'
              : 'transformation-block__asset'
          }
        >
          {previewLoading ? (
            <LoadingDots />
          ) : (
            <>
              {detailPreview === NUMBERS_STRINGS.FIRST &&
                preview !== undefined && (
                  <PreviewDataset
                    tableData={preview}
                    options={{
                      clickAction: handlePreviewSelect,
                      selectedRow: selectedTransform?.index
                    }}
                  />
                )}
              {detailPreview === NUMBERS_STRINGS.SECOND &&
                rowsPreview !== undefined && (
                  <PreviewRows
                    tableData={rowsPreview}
                    options={{
                      clickAction: handlePreviewSelect,
                      selectedRow: selectedTransform?.name
                    }}
                  />
                )}
            </>
          )}
        </Box>
        <Box className="transformation-block__manager">
          <CustomButton
            variant="secondary"
            style={{
              marginTop: '10px',
              marginLeft: '-30px',
              maxWidth: '30px',
              padding: '0px',
              boxShadow: 'none',
              zIndex: 2
            }}
            onClick={() => {
              setDatasetManagerOpen(!datasetManagerOpen);
            }}
          >
            {datasetManagerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </CustomButton>
          <Collapse in={datasetManagerOpen} orientation="horizontal">
            {activeTransformColumn()}
          </Collapse>
        </Box>
      </Box>
    </Box>
  );

  const footerComponent = (
    <Box id="feature-engieering__footer">
      <CustomButton
        disabled={Object.keys(transformationState).length === 0}
        variant="secondary"
        onClick={handleReset}
        data-cy="transformation-modal-cancel-button"
        icon={{
          type: ICON_TYPE.REFRESHCCW01,
          position: 'left'
        }}
      >
        Reset
      </CustomButton>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        {!dataSetData.current.dataManagementFlow && (
          <CustomButton
            onClick={handleNoSaved}
            variant="secondary"
            data-cy="transformation-modal-save-button"
          >
            Use without saving
          </CustomButton>
        )}
        <CustomButton
          disabled={!isModifyied}
          onClick={updateNewTransformState}
          data-cy="transformation-modal-save-button"
        >
          {dataSetData.current.dataManagementFlow
            ? 'Save modifications'
            : 'Save changes and use'}
        </CustomButton>
      </Box>
    </Box>
  );

  return (
    <>
      <RenderViewComposed
        id="feature-engieering"
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      {openSavingTransform && (
        <Save
          isTransform={[openSavingTransform, setOpenSavingTransform]}
          newTransformationState={{ transformData: newTransformationState }}
          datasetData={dataSetData.current}
        />
      )}
    </>
  );
};

export default Transformation;
