import axios, { type AxiosResponse } from 'axios';
import { AssistantApiSocketClient } from 'services/AssistantApiSocketClient';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { v4 as uuidv4 } from 'uuid';
import { transformDataToPlot } from './../../utils/utils';

import { TYPES } from 'chat/interfaces/enums';
import type { Block, ReceivedMessage } from 'chat/interfaces/messages';
import type {
  Dataset,
  Model,
  OptionBlock,
  OptionValue,
  SelectedMultiSelectOptions,
  TextType
} from 'common/interfaces/interfaces';
import type { FeatureValueType } from 'playground/atoms/atomPlayground';
import type {
  Feature,
  ModelMetadata,
  Plot,
  PredictResultsResponse
} from 'playground/interfaces/playground';
import { GAUserEvent, deepCopy } from '../../utils/utils';
import type {
  Action,
  BlockAny,
  LayoutPayload,
  LinkPayload,
  Options1ActionsGenerator,
  Options2ActionsGenerator,
  Options3ActionsGenerator,
  PendingInteractiveMsg,
  SlackLayoutPayload,
  StateValueGenerator
} from './interfaces';

const TOKEN = 'kazWzlTzYINT5GRhnXm7d1wy';
const TEAM_ID = 'T01QJ3CQSLS';
const API_APP_ID = 'A03MQP2U878';
const CHANNEL_ID = 'D03PL9S2CVB';
const USER_ID = 'U03L9MRNKFY';
const CLIENT_MSG_ID = uuidv4();
const BASE_URL = String(process.env.REACT_APP_MAIN_SERVICE_BASE_URL);
const PREFIX_URI = String(process.env.REACT_APP_MAIN_SERVICE_PREFIX_URI);

export class AssistantApiService {
  static instance: AssistantApiService;
  assistantApiSocketClient: AssistantApiSocketClient;
  private readonly authenticationService: AuthenticationService;

  PendingInteractiveMsg: PendingInteractiveMsg[];

  lastAckInteractiveMsg: ReceivedMessage | undefined;

  channelId: string;

  slack_layout_payload: SlackLayoutPayload = {
    channel_id: CHANNEL_ID,
    message: {
      ts: '1666792521.415359',
      blocks: [
        {
          type: 'actions',
          block_id: 'START_ANALYSIS_MENU_OPTION',
          elements: [
            {
              type: 'button',
              action_id: 'Start analysis',
              text: {
                type: 'plain_text',
                text: 'Start analysis'
              }
            }
          ]
        }
      ]
    },
    state: {
      values: {}
    },
    actions: [
      {
        action_id: 'Start analysis',
        block_id: 'START_ANALYSIS_MENU_OPTION',
        text: {
          type: 'plain_text',
          text: 'Start analysis',
          emoji: 'True'
        },
        style: 'primary',
        type: 'button',
        action_ts: '1666856832.548796'
      }
    ]
  };

  layout_payload = {
    channel_id: CHANNEL_ID,
    action_id: 'Start analysis',
    interactive: true,
    message: {}
  };

  link_payload: LinkPayload = {
    token: TOKEN,
    team_id: TEAM_ID,
    api_app_id: API_APP_ID,
    event: {
      client_msg_id: CLIENT_MSG_ID,
      type: 'message',
      text: '<https://storage.googleapis.com/aizwei_dataset/Mail.csv>',
      user: USER_ID,
      ts: '1668764472.212319',
      blocks: [
        {
          type: 'rich_text',
          block_id: 'txJx',
          elements: [
            {
              type: 'rich_text_section',
              elements: [
                {
                  type: 'link',
                  text: 'https://storage.googleapis.com/aizwei_dataset/Mail.csv'
                }
              ]
            }
          ]
        }
      ],
      team: TEAM_ID,
      channel: CHANNEL_ID,
      event_ts: '1668764472.212319',
      channel_type: 'im'
    },
    type: 'event_callback',
    event_id: 'Ev04B4CHGUNT',
    event_time: 1668764472,
    authorizations: [
      {
        enterprise_id: 'None',
        team_id: TEAM_ID,
        user_id: USER_ID,
        is_bot: 'True',
        is_enterprise_install: 'False'
      }
    ],
    is_ext_shared_channel: 'False',
    event_context:
      '4-eyJldCI6Im1lc3NhZ2UiLCJ0aWQiOiJUMDFRSjNDUVNMUyIsImFpZCI6IkEwM01RUDJVODc4IiwiY2lkIjoiRDA0NFNUNUNaUUYifQ'
  };

  public static getInstance(): AssistantApiService {
    if (AssistantApiService.instance === undefined) {
      AssistantApiService.instance = new AssistantApiService();
    }
    return AssistantApiService.instance;
  }

  constructor() {
    this.authenticationService = AuthenticationService.getInstance();
    this.assistantApiSocketClient = AssistantApiSocketClient.getInstance();
    this.PendingInteractiveMsg = [];
    this.channelId = uuidv4();
    this.assistantApiSocketClient.addIsConnectedListener(() => {
      console.log(
        'Socket connected.',
        this.assistantApiSocketClient.getSocket().id,
        this.channelId
      );
      this.assistantApiSocketClient.sendConnectionInfo({
        channel_id: this.channelId
      });
      const numberPendingInteractiveMsg = this.PendingInteractiveMsg.length;
      if (this.PendingInteractiveMsg.length > 0) {
        for (let i = 0; i < numberPendingInteractiveMsg; i++) {
          console.log('Sending pending InteractiveMsg');
          const pendingInteractiveMsg = this.PendingInteractiveMsg.shift();
          if (pendingInteractiveMsg !== undefined) {
            this.sendInteractiveMsg(pendingInteractiveMsg);
          }
        }
      }
    });
    this.assistantApiSocketClient.addIsDisconnectedListener(() => {
      console.log(
        'Socket disconnected.',
        this.assistantApiSocketClient.getSocket().id,
        this.channelId
      );
    });
  }

  public refreshChannelId(): void {
    this.channelId = uuidv4();
    console.log('Refreshed channel id', this.channelId);
    this.assistantApiSocketClient.sendConnectionInfo({
      channel_id: this.channelId
    });
  }

  public startConversation(): void {
    this.refreshChannelId();
    this.slack_layout_payload.channel_id = this.channelId;
    this.sendInteractiveMsg(this.slack_layout_payload);
    GAUserEvent('START_CONVERSATION');
  }

  public endConversation(): void {
    this.refreshChannelId();
    const keywordEnd = 'KEYWORD_END';
    const element: TextType = { type: 'text', text: keywordEnd };
    this.modifyPayload(keywordEnd, element);
    GAUserEvent('END_CONVERSATION');
  }

  public continueConversationAfterPreviewEdit(intent: string): void {
    const payload = {
      channel_id: this.channelId,
      interactive: false,
      action_id: '',
      message: {
        intent
      }
    };
    this.sendMessage(payload);
  }

  public continueConversationAfterSaveModal(
    intent: string,
    isSaved: boolean,
    datasetData?: { dataset_url: string; dataset_id: string },
    isTransformed = false
  ): void {
    const dataset = datasetData ?? {};
    const payload = {
      channel_id: this.channelId,
      interactive: false,
      action_id: '',
      message: {
        intent,
        saved: isSaved,
        ...dataset,
        is_transformed: isTransformed
      }
    };
    this.sendMessage(payload);
  }

  public continueConversationAfterLoadModal(
    actionId: string,
    intent: string,
    modelMetadata: Dataset | Model | Record<string, never>
  ): void {
    const payload: LayoutPayload = deepCopy(this.layout_payload);

    payload.channel_id = this.channelId;
    payload.interactive = true;
    payload.action_id = actionId ?? 'LOAD_MODELS';
    payload.message = {
      intent,
      metadata: modelMetadata
    };

    this.sendMessage(payload);
  }

  public startConnection(): void {
    this.refreshChannelId();
    this.slack_layout_payload.channel_id = this.channelId;
    this.layout_payload.channel_id = this.channelId;
  }

  public sendFileLink(link: string): void {
    const element: TextType = { type: 'link', text: link };
    this.modifyPayload(link, element);
    GAUserEvent('DATASET_UPLOADED');
  }

  public sendAckMessage(messageReceived: ReceivedMessage): void {
    console.log('Message received', messageReceived);
    this.assistantApiSocketClient.ackMessage({
      channel_id: this.channelId,
      ack_id: messageReceived.ts
    });
    this.lastAckInteractiveMsg = messageReceived;
  }

  public isLastAckedMessage(messageReceived: ReceivedMessage): boolean {
    return (
      this.lastAckInteractiveMsg !== undefined &&
      this.lastAckInteractiveMsg.ts === messageReceived.ts
    );
  }

  /**
   * Transform payload for checkbox
   * @param blocks Blocks
   * @param optionsSelected Options selected
   */
  public checkBoxInteraction(
    blocks: BlockAny[],
    optionsSelected: string[]
  ): void {
    const _blocks = deepCopy(blocks);
    const payload: SlackLayoutPayload = this.generateCheckBoxPayload(
      _blocks,
      optionsSelected
    );
    payload.channel_id = this.channelId;
    this.sendInteractiveMsg(payload);
    const gaActionId: string = payload.actions[0].action_id
      .toString()
      .toUpperCase();
    const gaSelectedOption: string =
      payload?.actions[0]?.value?.toString().replace(/ /g, '_').toUpperCase() ??
      '';
    GAUserEvent(gaActionId + '+' + gaSelectedOption);
  }

  /**
   * Transform payload for selected option
   * @param blocks Blocks
   * @param selectedOption Selected option
   */
  public selectOption(blocks: BlockAny[], selectedOption: string): void {
    const _blocks = deepCopy(blocks);
    const payload: SlackLayoutPayload = this.modifyLayoutPayload(
      _blocks,
      selectedOption
    );
    payload.channel_id = this.channelId;
    this.sendInteractiveMsg(payload);
    const gaActionId: string = payload.actions[0].action_id
      .toString()
      .toUpperCase();
    const gaSelectedOption: string =
      payload?.actions[0]?.selectedOption?.value
        .toString()
        .replace(/ /g, '_')
        .toUpperCase() ?? '';
    GAUserEvent(gaActionId + '+' + gaSelectedOption);
  }

  /**
   * Transform payload for selected option
   * @param actionId Action id
   * @param selectedOption Selected options for targetColumn,
   * optimizeVariant and optimizationType
   */
  public multiSelectOption(
    actionId: string,
    selectedOption: SelectedMultiSelectOptions
  ): void {
    const payload: LayoutPayload = deepCopy(this.layout_payload);

    payload.channel_id = this.channelId;
    payload.message = selectedOption;
    payload.action_id = actionId;

    this.sendMessage(payload);
    const gaActionId: string = payload.action_id.toUpperCase();
    GAUserEvent(gaActionId);
  }

  /**
   * Transform payload for input
   * @param blocks Blocks
   * @param inputValue Valur of the input
   */
  public inputInteraction(blocks: BlockAny[], inputValue: string): void {
    const _blocks = deepCopy(blocks);
    const payload: SlackLayoutPayload = this.modifyInputLayoutPayload(
      _blocks,
      inputValue
    );
    payload.channel_id = this.channelId;
    this.sendInteractiveMsg(payload);
    const gaActionId: string = payload.actions[0].action_id
      .toString()
      .toUpperCase();
    GAUserEvent('INPUT_' + gaActionId);
  }

  // TODO: mode to expose API service when it is ready
  public async getModelMetadata(): Promise<ModelMetadata> {
    const URL = `${BASE_URL}${PREFIX_URI}/assistant/model-metadata/${this.channelId}`;
    const headers = {
      Authorization: `Bearer ${this.authenticationService.authToken}`
    };
    return await axios
      .get(URL, { headers })
      .then((res) => {
        const modelMetadata = JSON.parse(res.data).modelMetadata;
        const parsedFeatures: Record<string, Feature | never> = {};
        const features = modelMetadata.featureImportances;
        for (const key in features) {
          const feature = features[key];
          parsedFeatures[key] = {
            type: feature.type,
            categoryVariants: feature.category_variants,
            scenario: {
              bestCase: '',
              worstCase: ''
            }
          };
        }
        modelMetadata.featureImportances = parsedFeatures;
        return modelMetadata;
      })
      .catch((err) => err);
  }

  public async getFeaturePlot(): Promise<Plot> {
    const URL = `${BASE_URL}${PREFIX_URI}/assistant/feature-importance-plot/${this.channelId}`;
    const headers = {
      Authorization: `Bearer ${this.authenticationService.authToken}`
    };
    const response = await axios
      .get(URL, { headers })
      .then((res) => {
        const parsedData = JSON.parse(res.data);
        const plot: Plot = transformDataToPlot(parsedData);
        return plot;
      })
      .catch((err) => err);
    return response;
  }

  public async predictResults(
    features: FeatureValueType
  ): Promise<PredictResultsResponse> {
    const URL = `${BASE_URL}${PREFIX_URI}/assistant/generate-inference/${this.channelId}`;
    const headers = {
      Authorization: `Bearer ${this.authenticationService.authToken}`
    };
    const response = await axios
      .post(URL, { data: { features } }, { headers })
      .then((res) => {
        const response = JSON.parse(res.data);
        const parsedResponse: PredictResultsResponse = {
          downloadId: response.downloadId,
          inferenceUrl: response.inferenceUrl,
          jobId: response.job_id
        };
        return parsedResponse;
      })
      .catch((err) => err);
    return response;
  }

  public async deleteCountDown(): Promise<AxiosResponse> {
    const URL = `${BASE_URL}${PREFIX_URI}/assistant/delete-countdown/${this.channelId}`;
    const headers = {
      Authorization: `Bearer ${this.authenticationService.authToken}`
    };
    const response = await axios
      .get(URL, { headers })
      .then()
      .catch((err) => err);
    return response;
  }

  public async createCountDown(): Promise<AxiosResponse> {
    const URL = `${BASE_URL}${PREFIX_URI}/assistant/create-countdown/${this.channelId}`;
    const headers = {
      Authorization: `Bearer ${this.authenticationService.authToken}`
    };
    const response = await axios
      .get(URL, { headers })
      .then()
      .catch((err) => err);
    return response;
  }

  private modifyLayoutPayload(
    _blocks: BlockAny[],
    selectedOption: string
  ): SlackLayoutPayload {
    const layoutPayloadModify: SlackLayoutPayload = deepCopy(
      this.slack_layout_payload
    );

    _blocks?.forEach((block: BlockAny) => {
      let optionsBlock: OptionBlock[] = [];
      let optionBlock: OptionBlock | undefined;
      if (
        block.type === TYPES.ACTIONS.toLowerCase() &&
        block?.elements?.length > 0
      ) {
        optionBlock =
          block.elements?.find(
            (option: OptionBlock) => option.options?.length > 0
          ) ?? block.elements[0];
        optionsBlock = optionBlock?.options;
      } else if (
        block.type === TYPES.SECTION.toLowerCase() &&
        block?.accessory?.options?.length > 0
      ) {
        optionBlock = block.accessory;
        optionsBlock = block.accessory.options;
      }
      if (optionsBlock?.length > 0 && optionBlock !== undefined) {
        const layoutSelectedOption: OptionValue | undefined =
          optionsBlock?.find(
            (option: OptionValue) => option.value === selectedOption
          );
        const blockId = block.block_id ?? uuidv4();
        const actionId = optionBlock.action_id;
        const actionType = optionBlock.type;
        layoutPayloadModify.message.text =
          block.text?.text ?? optionBlock.placeholder?.text;
        if (block.type === TYPES.ACTIONS.toLowerCase()) {
          layoutPayloadModify.message.blocks = _blocks;
        } else {
          const index = layoutPayloadModify.message.blocks.length - 1;
          layoutPayloadModify.message.blocks[index] = {
            ...block,
            ...{ block_id: blockId }
          };
        }
        layoutPayloadModify.state.values = this.stateValueGenerator(
          blockId,
          actionId,
          actionType,
          'selected_option',
          layoutSelectedOption
        );
        const actionOptions = {
          selectedOption: layoutSelectedOption,
          placeholder: optionBlock.placeholder
        };
        layoutPayloadModify.actions = this.actionsGenerator(
          actionType,
          actionId,
          blockId,
          actionOptions
        );
      }
    });
    return layoutPayloadModify;
  }

  private modifyInputLayoutPayload(
    _blocks: BlockAny[],
    inputValue: string
  ): SlackLayoutPayload {
    const layoutPayloadModify: SlackLayoutPayload = deepCopy(
      this.slack_layout_payload
    );
    const block = _blocks.find(
      (block: Block) => block.type.toLowerCase() === TYPES.INPUT.toLowerCase()
    );
    const uuid = uuidv4();
    const blockId = block.block_id ?? uuid;
    const actionId = block.element.action_id;
    const actionType = block.element.type;
    layoutPayloadModify.message.text =
      block.text?.text ?? "This content can't be displayed.";
    layoutPayloadModify.message.blocks = _blocks;
    layoutPayloadModify.message.blocks[0].block_id = blockId;
    const dispatchAction =
      layoutPayloadModify.message.blocks[0]?.dispatch_action === true
        ? 'True'
        : 'False';
    layoutPayloadModify.message.blocks[0].dispatch_action = dispatchAction;
    if (layoutPayloadModify.message.blocks[0].label !== undefined) {
      layoutPayloadModify.message.blocks[0].label.emoji = 'False';
    }
    layoutPayloadModify.state.values = this.stateValueGenerator(
      blockId,
      actionId,
      actionType,
      'value',
      inputValue
    );
    const timeStamp = this.timeStampGenerator();
    const actionOptions = { value: inputValue, action_ts: `${timeStamp}` };
    layoutPayloadModify.actions = this.actionsGenerator(
      actionType,
      actionId,
      blockId,
      actionOptions
    );
    return layoutPayloadModify;
  }

  private generateCheckBoxPayload(
    _blocks: Block[],
    optionsSelected: string[]
  ): SlackLayoutPayload {
    const payload: SlackLayoutPayload = deepCopy(this.slack_layout_payload);
    if (_blocks.length > 0) {
      const optionsSelected2Block: OptionValue[] = [];
      let inputBlockId = '';
      let inputActionId = '';
      let actionsActionId = '';
      let inputElementType = '';
      _blocks.map((block) => {
        block.block_id = uuidv4();
        switch (block.type.toUpperCase()) {
          case TYPES.INPUT:
            if (block?.element !== undefined) {
              block.element.action_id = uuidv4();
            }
            inputBlockId = block.block_id;
            if (block?.element?.action_id !== undefined) {
              inputActionId = block?.element?.action_id;
            }
            if (block?.element?.type !== undefined) {
              inputElementType = block?.element?.type;
            }
            if (optionsSelected.length > 0) {
              optionsSelected?.forEach((option: string) => {
                const optionFind = block?.element?.options.find(
                  (opt: { value: string }) => option === opt.value
                );
                if (optionFind !== undefined) {
                  optionsSelected2Block.push(optionFind);
                }
              });
            }
            break;
          case TYPES.ACTIONS:
            if (block?.elements !== undefined && block.elements.length > 0) {
              actionsActionId = block.elements[0].action_id;
            }
            break;
        }
        return block;
      });
      const blockAction = _blocks.find(
        (block: Block) => block.type === 'actions'
      );
      payload.state.values = this.stateValueGenerator(
        inputBlockId,
        inputActionId,
        inputElementType,
        'selected_options',
        optionsSelected2Block
      );
      payload.message.blocks = _blocks;
      if (blockAction?.elements !== undefined) {
        const actionOptions = {
          text: blockAction.elements[0].text,
          value: String(blockAction.elements[0].value)
        };
        payload.actions = this.actionsGenerator(
          blockAction.elements[0].type,
          actionsActionId,
          blockAction.block_id,
          actionOptions
        );
      }
    }
    return payload;
  }

  private timeStampGenerator(): number {
    return Math.floor(Date.now() / 1000) * 1000;
  }

  private stateValueGenerator(
    blockId: string,
    actionId: string,
    typeValue: string,
    optionKey: string,
    optionValue: string | OptionValue | OptionValue[] | undefined
  ): StateValueGenerator {
    const newValues = {
      [blockId]: {
        [actionId]: {
          type: typeValue,
          [optionKey]: optionValue
        }
      }
    };
    return newValues;
  }

  private actionsGenerator(
    actionType: string,
    actionId: string,
    blockId: string,
    options:
      | Options1ActionsGenerator
      | Options2ActionsGenerator
      | Options3ActionsGenerator
  ): Action[] {
    const newAction = {
      type: actionType,
      action_id: actionId,
      block_id: blockId,
      ...{ ...options }
    };
    return [newAction as Action];
  }

  private sendInteractiveMsg(payload: PendingInteractiveMsg): void {
    const jsonPayload = JSON.stringify(payload).replaceAll("'", '');
    if (this.assistantApiSocketClient.isConnected()) {
      try {
        this.assistantApiSocketClient.sendInteractiveMsg(jsonPayload);
        console.log('InteractiveMsg has been sent.', payload);
      } catch {
        console.log(
          'InteractiveMsg has not been sent. Now its pending.',
          payload
        );
        this.PendingInteractiveMsg.push(payload);
      }
    } else {
      console.log(
        'InteractiveMsg has not been sent. Now its pending.',
        payload
      );
      this.PendingInteractiveMsg.push(payload);
    }
  }

  private sendMessage(payload: LayoutPayload): void {
    const jsonPayload = JSON.stringify(payload).replaceAll("'", '');
    if (this.assistantApiSocketClient.isConnected()) {
      try {
        this.assistantApiSocketClient.sendMessage(jsonPayload);
        console.log('Message has been sent.', payload);
      } catch {
        console.log('Message has not been sent. Now its pending.', payload);
        this.PendingInteractiveMsg.push(payload);
      }
    } else {
      console.log('Message has not been sent. Now its pending.', payload);
      this.PendingInteractiveMsg.push(payload);
    }
  }

  private modifyPayload(text: string, element: TextType): void {
    const payload: LinkPayload = deepCopy(this.link_payload);
    payload.event.text = text;
    payload.event.client_msg_id = uuidv4();
    payload.event.blocks[0].elements[0].elements[0] = element;
    payload.event.blocks[0].block_id = uuidv4();
    const timeStamp = Math.floor(Date.now() / 1000) * 1000;
    payload.event.ts = `${timeStamp}`;
    payload.event.event_ts = `${timeStamp}`;
    payload.event_time = timeStamp;
    payload.event.channel = this.channelId;
    this.sendInteractiveMsg(payload);
  }
}
