import type { Dispatch, SetStateAction } from 'react';
import type { ContextSelection } from '../atoms/atomContextSelections';
import type { WarrningUpload } from '../UploadManager';

export const checkValidityOfSchemaContext = (
  contextSelection: ContextSelection,
  setWarring: Dispatch<SetStateAction<WarrningUpload | undefined>>
): boolean => {
  let isMissing = false;
  let foundEmptyContext = false;
  let checkCount = 0;
  let valuesCount = 0;
  contextSelection.forEach((existingSelection, ind) => {
    if (existingSelection.checked) {
      checkCount++;
      if (existingSelection.description === '') {
        foundEmptyContext = true;
      }
    }
    if (existingSelection.description !== '') {
      valuesCount++;
    }
  });
  if (foundEmptyContext) {
    isMissing = true;
    setWarring({
      title: 'Missing context for output columns ',
      body: [
        'Context is required for output features to be analysed.',
        `Please add context to each of the columns selected as outputs or
            remove those without context. Remember, output columns are those
            that will be taken into account for our analysis as your main
            goals.`
      ],
      allowContinue: false
    });
  } else if (!(checkCount > 0 && valuesCount > 4)) {
    isMissing = true;
    setWarring({
      title: 'Missing Information for Machine Learning Operations',
      body: [
        `To process your data and enable advanced insights through our
          assistant, we need at least 1 output column and 5 context-filled
          columns to proceed.`
      ],
      allowContinue: true
    });
  } else if (contextSelection.length !== valuesCount) {
    isMissing = true;
    setWarring({
      title: 'Context fields empty',
      body: [
        `If not all the context fields for columns are filled, it would be
          more difficult for the system to analyse your data and the
          assistant to answer your questions.`
      ],
      allowContinue: true
    });
  }
  return isMissing;
};
