import { Box } from '@mui/material';
import { TitleTypography } from 'common/TitleTypography';
import CustomButton from 'common/button/CustomButton';
import {
  useEffect,
  useState,
  type CSSProperties,
  type ReactElement
} from 'react';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { GAUserEvent } from '../../utils/utils';
import { generateInput, triggerSetText } from './common/common';
import ModalComposed from 'common/modal/ModalComposed';
import { useSetRecoilState } from 'recoil';
import { modalControllerAtom } from 'atoms/atomModalController';
import { MODAL_TYPES } from 'common/interfaces/enums';
import 'common/Common.scss';

const ForgotPassword = (): ReactElement => {
  const authenticationService = AuthenticationService.getInstance();
  const [email, setEmail] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [enableLoginButton, setEnableLoginButton] = useState<boolean>(false);
  const setModalController = useSetRecoilState(modalControllerAtom);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const EMAIL_REGEX =
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(_[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$';

  useEffect(() => {
    if (enableLoginButton) {
      const keyDownHandler = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
          event.preventDefault();
          recoverPasswordToken();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, [enableLoginButton]);

  useEffect(() => {
    setErrorMessage(null);
    if (email?.trim() !== '') {
      setEnableLoginButton(true);
    } else {
      setEnableLoginButton(false);
    }
  }, [email]);

  const generateTitleTypography = (
    text: string,
    style?: CSSProperties
  ): ReactElement => {
    return (
      <TitleTypography
        key={`title-${Math.random()}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...{ ...style }
        }}
      >
        {text}
      </TitleTypography>
    );
  };

  const generateSubTitleTypography = (
    text: string,
    color?: string,
    style?: CSSProperties
  ): ReactElement => {
    const commonStyle = {
      justifyContent: 'flex-start',
      fontFamily: 'ft-system-regular',
      fontSize: '14px',
      color: color ?? '#344054',
      fontWeight: '400',
      ...style
    };
    return generateTitleTypography(text, commonStyle);
  };

  const generateErrorMessage = (errorMessage: string): ReactElement => {
    return generateTitleTypography(errorMessage, {
      justifyContent: 'flex-start',
      fontFamily: 'ft-system-regular',
      fontSize: '14px',
      color: '#F04438',
      fontWeight: '400'
    });
  };

  const recoverPasswordToken = (): void => {
    setErrorMessage(null);
    authenticationService
      .recoverPasswordToken(email)
      .then(() => {
        setModalController({ type: MODAL_TYPES.LINK_SENT });
        GAUserEvent('RECOVER_PASSWORD');
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          'The e-mail address introduced does not match any account'
        );
        setInvalidEmail(true);
        GAUserEvent('RECOVER_PASSWORD_EMAIL_NOT_FOUND');
      });
  };

  return (
    <ModalComposed
      open={true}
      className="md-modal-wrapper"
      body={
        <Box style={{ height: '100%', width: '100%' }}>
          {generateTitleTypography('Need a hand with your password?', {
            marginBottom: '20px'
          })}
          {generateSubTitleTypography(
            "Let's reset it, please enter your email address. You will receive a link to create a new password via email.",
            '#667085',
            { marginBottom: '20px', textAlign: 'left' }
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              gap: '20px'
            }}
          >
            <Box
              style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}
            >
              {generateSubTitleTypography('Email')}
              {generateInput(
                'text',
                'example@aizwei.com',
                (e: string) => {
                  triggerSetText(
                    typeof e === 'string' ? e : '',
                    setEmail,
                    'email',
                    setInvalidEmail,
                    EMAIL_REGEX
                  );
                },
                null,
                () => {
                  recoverPasswordToken();
                }
              )}
              {invalidEmail &&
                generateErrorMessage(
                  'Please enter a valid email address' ?? errorMessage
                )}
            </Box>
            <Box
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <CustomButton
                variant="primary"
                disabled={!enableLoginButton}
                onClick={(e) => {
                  recoverPasswordToken();
                }}
                style={{
                  maxWidth: 'none'
                }}
              >
                {'Request password reset'}
              </CustomButton>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default ForgotPassword;
