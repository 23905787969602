import React, { type ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

const Spinner = (): ReactElement => {
  return (
    <>
      <svg height="0" width="0">
        <defs>
          <linearGradient
            id="spinner-gradient"
            x1="19.1816"
            y1="1.68164"
            x2="19.1816"
            y2="36.6816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FD471D" />
            <stop offset="0.494792" stopColor="#D3117C" />
            <stop offset="1" stopColor="#A018C9" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ 'svg circle': { stroke: 'url(#spinner-gradient)' } }}
      />
    </>
  );
};

export default Spinner;
