import PropTypes from 'prop-types';
import { createRef, useEffect, type ReactElement } from 'react';
import { Transition } from 'react-transition-group';
import './CustomTransition.scss';

interface TransitionStyles {
  entering?: Record<string, unknown>;
  entered?: Record<string, unknown>;
  exiting?: Record<string, unknown>;
  exited?: Record<string, unknown>;
  unmounted?: Record<string, unknown>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTransition = (props: any): ReactElement => {
  const { duration }: { duration: number } = props;
  const { delay }: { delay: number } = props;
  const transitionStyles: TransitionStyles = props.transitionStyle ?? {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 1 }
  };

  useEffect(() => {
    if (props?.nodeRef !== undefined && props?.onChangeRef !== undefined) {
      props.onChangeRef(props?.nodeRef);
    }
  }, [props?.nodeRef]);

  const animation = {
    animationDuration: duration !== undefined ? String(duration) + 'ms' : '0ms',
    animationTimingFunction: 'ease-out',
    animationDelay: delay !== undefined ? String(delay) + 'ms' : '0s',
    animationIterationCount: 1,
    animationDirection: 'normal',
    animationFillMode: 'forwards',
    animationPlayState: 'running',
    animationName: props.animation
  };

  const generateComponent = (): ReactElement => {
    if (props?.component !== undefined && props?.component !== null) {
      return props.component;
    }
    return <></>;
  };

  return (
    <Transition
      in={props.in}
      timeout={{
        appear: props.appear,
        enter: props.enter
      }}
      appear
      unmountOnExit
      nodeRef={props?.nodeRef}
    >
      {(state) => (
        <div
          style={{
            ...transitionStyles[state],
            ...animation,
            ...props.styled
          }}
          ref={props?.nodeRef}
        >
          {generateComponent()}
        </div>
      )}
    </Transition>
  );
};

CustomTransition.propTypes = {
  component: PropTypes.any,
  transitionStyles: PropTypes.any,
  appear: PropTypes.any,
  enter: PropTypes.any,
  in: PropTypes.bool,
  nodeRef: PropTypes.any,
  onChangeRef: PropTypes.func,
  duration: PropTypes.number,
  delay: PropTypes.number,
  styled: PropTypes.any,
  animation: PropTypes.oneOf(['move-up', 'move-down', 'fade-in', 'fade-out'])
};

CustomTransition.defaultProps = {
  in: false,
  appear: 0,
  enter: 0,
  nodeRef: createRef(),
  onChangeRef: () => undefined
};

export default CustomTransition;
