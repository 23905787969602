import { Box, Typography } from '@mui/material';
import type { ReactElement } from 'react';
import AssetsForm, {
  type AssetsFormProps
} from 'mySpace/components/assetsInputsByIntent/AssetsForm';
import 'common/Common.scss';

const UploadSave = ({
  assetsFormControllers,
  saveOnEnter
}: {
  assetsFormControllers: AssetsFormProps['inputControllers'];
  saveOnEnter: (event: React.KeyboardEvent) => void;
  handleSave: () => void;
}): ReactElement => {
  return (
    <Box>
      <Box className="upload__content__title">
        Saving dataset
        <Typography className="upload__content__description">
          Datasets saved will be available from your personal space
        </Typography>
      </Box>
      <AssetsForm
        inputControllers={assetsFormControllers}
        onEnter={saveOnEnter}
        disableInputs={false}
      />
    </Box>
  );
};

export default UploadSave;
