import { atom } from 'recoil';
import {
  type FeatureState,
  TRANSFORM_COLUMN
} from 'featureEngineering/featureEngineeringInterface';

export const transformFeatureStateAtom = atom<FeatureState>({
  key: 'transformFeatureHistoryAtom',
  default: {}
});

export const activeTransformViewAtom = atom<TRANSFORM_COLUMN>({
  key: 'activeTransformViewAtom',
  default: TRANSFORM_COLUMN.DEFAULT
});
