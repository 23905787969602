export const Cookie = {
  set: function (name: string, value: string): void {
    document.cookie = `${name}=${value}; path=/;${this.getMainDomain()}`;
  },

  get: function (name: string): string | undefined {
    const cookie = document.cookie;
    const allCookies = document.cookie.split(';');
    if (allCookies !== undefined && allCookies.length > 0) {
      return allCookies
        .find((cookie) => cookie.split('=')[0].trim() === name)
        ?.split('=')[1];
    } else {
      return cookie.split('=')[1];
    }
  },

  remove: function (name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;${this.getMainDomain()}`;
  },

  getMainDomain: function (): string {
    const host = location.host;
    if (host.split('.').length === 1) {
      return '';
    } else {
      return ` domain=.${host.split('.').slice(-2).join('.')};`;
    }
  }
};
