import {
  type ModelMetadata,
  FeatureType,
  type Plot,
  type Prediction,
  ScenarioSelectors,
  type Feature
} from 'playground/interfaces/playground';
import { type AlertColor } from '@mui/material';
import { atom, selector } from 'recoil';
import type { Dayjs } from 'dayjs';

const modelMatadataAtom = atom<ModelMetadata>({
  key: 'modelMatadataAtom',
  default: {
    categoryVariants: [],
    featureImportances: {},
    datasetUrl: '',
    label: '',
    modelId: ''
  }
});

const loadingJobAtom = atom<boolean>({
  key: 'loadingJobAtom',
  default: false
});

const loadingAtom = atom<boolean>({
  key: 'loadingAtom',
  default: false
});

const featuresAtom = atom<Record<string, Feature>>({
  key: 'featuresAtom',
  default: {
    name: {
      type: FeatureType.CATEGORICAL,
      categoryVariants: [''],
      scenario: {
        bestCase: '',
        worstCase: ''
      }
    }
  }
});
export type FeatureValueType = Record<string, string | Dayjs>;

const featureValuesAtom = atom<FeatureValueType>({
  key: 'featureValuesAtom',
  default: {}
});

const defaultFeatureValues = selector({
  key: 'defaultFeatureValues',
  get: () => {
    return {};
  },
  // TODO: fix type for setters to Record<string, Feature>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set: ({ set }, currentFeatures: any) => {
    const newFeatureValues: Record<string, string> = {};
    Object.keys(currentFeatures).forEach((feature) => {
      const current = currentFeatures[feature];
      if (
        (current.type === FeatureType.CATEGORICAL ||
          current.type === FeatureType.BINARY_CATEGORICAL) &&
        current.categoryVariants !== null
      ) {
        newFeatureValues[feature] = current.categoryVariants[0];
      } else if (current.type === FeatureType.NUMERICAL) {
        newFeatureValues[feature] = '0';
      } else {
        newFeatureValues[feature] = '';
      }
    });
    set(featureValuesAtom, newFeatureValues);
  }
});

const plotsAtom = atom<Plot>({
  key: 'plotsAtom',
  default: {
    featureNames: [],
    featureImportance: [],
    featurePlot: [],
    featureHighImportance: [],
    title: '',
    xlabel: '',
    ylabel: '',
    leftBarLabel: '',
    rightBarLabel: ''
  }
});

const predictionAtom = atom<Prediction>({
  key: 'predictionAtom',
  default: {
    targetColumn: '',
    prediction: 0,
    isProbability: false
  }
});

const snackBarAtom = atom<{
  status: AlertColor;
  open: boolean;
  message: string;
}>({
  key: 'snackBarAtom',
  default: { status: 'info', open: false, message: '' }
});

const runningButtonTextAtom = atom<string>({
  key: 'runningButtonTextAtom',
  default: 'Predict'
});

const playgroundGoalAtom = atom<{
  selector: string;
  intention: string;
  goal: string;
  update: boolean;
}>({
  key: 'playgroundGoalAtom',
  default: {
    selector: '',
    intention: '',
    goal: '',
    update: false
  }
});

const transformedPlotDataSelector = selector({
  key: 'transformedPlotDataSelector',
  get: ({ get }) => {
    const plotData = get(plotsAtom);
    const { intention, update } = get(playgroundGoalAtom);

    if (intention === 'Decrease' && update) {
      return {
        ...plotData,
        featureImportance: plotData.featureImportance.map((value) => -1 * value)
      };
    }
    return { ...plotData };
  }
});

interface ScenarioData {
  scenario: ScenarioSelectors;
  intention: ScenarioSelectors;
  goal: string;
  update: boolean;
}
const scenarioAtom = atom<ScenarioData>({
  key: 'scenarioAtom',
  default: {
    scenario: ScenarioSelectors.BEST,
    intention: ScenarioSelectors.INCREASE,
    goal: '',
    update: false
  }
});

const modelFeaturesHighImportanceSelector = selector({
  key: 'modelFeaturesHighImportanceSelector',
  get: ({ get }) => {
    const plotData = get(plotsAtom);
    const featuresHighImportance: Record<string, boolean> = {};
    plotData.featureNames.forEach((featureName: string, index: number) => {
      const feature = featureName.split('_')[0];
      const featureCoefficient = plotData.featureImportance[index];

      // Skip features with 0 impact on the prediction
      if (featureCoefficient === 0) {
        return;
      }
      // Array contains subcategories for each feature. Once a feature is
      // identified as high importance, all its subcategories are marked as
      // high importance as well.
      featuresHighImportance[feature] =
        featuresHighImportance[feature] ||
        plotData.featureHighImportance[index];
    });
    return featuresHighImportance;
  }
});

const showAllFeaturesButtonSelector = selector({
  key: 'showAllFeaturesButtonSelector',
  get: ({ get }) => {
    const featuresHighImportance = get(modelFeaturesHighImportanceSelector);
    return Object.values(featuresHighImportance).some((value) => !value);
  }
});

export {
  modelMatadataAtom,
  loadingAtom,
  loadingJobAtom,
  featuresAtom,
  featureValuesAtom,
  plotsAtom,
  predictionAtom,
  snackBarAtom,
  defaultFeatureValues,
  runningButtonTextAtom,
  playgroundGoalAtom,
  transformedPlotDataSelector,
  scenarioAtom,
  modelFeaturesHighImportanceSelector,
  showAllFeaturesButtonSelector
};
