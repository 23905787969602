import { useEffect, useState, type ReactElement } from 'react';

import type {
  ChatComponent,
  MultiSelectOptions,
  TypeAddNewMessage
} from 'chat/interfaces/messages';

import { BOT_COMPONENT, LOADING_STATE } from 'chat/interfaces/enums';

import {
  collapseMagicIslandDerived,
  magicMessageDerived
} from 'chat/atoms/atomMagicMessage';
import { useRecoilState } from 'recoil';

import { ReactComponent as AssistantLogo } from 'assets/imgs/assistant-logo.svg';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import { TitleTypography } from 'common/TitleTypography';
import InputSend from './components/InputSend';
import MultiSelect from './components/multiselect/MultiSelect';
import CheckBox from './components/checkbox/Checkbox';
import PreparingData from './components/preparingData/PreparingData';
import ResponseButtons from './components/responseButtons/ResponseButtons';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Collapse } from '@mui/material';
import { GABotEvent } from '../../utils/utils';
import CustomSelectIsland from './components/CustomSelectIsland';
import './MagicIsland.scss';

interface MagicIslandProps {
  loading: LOADING_STATE;
  addNewMessages: TypeAddNewMessage;
}

const MagicIsland = ({
  addNewMessages,
  loading
}: MagicIslandProps): ReactElement => {
  const [collapseMagicIsland, setCollapseMagicIsland] = useRecoilState(
    collapseMagicIslandDerived
  );
  const [magicMessage, setMagicMessage] = useRecoilState(magicMessageDerived);

  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const hasTitle =
      magicMessage?.title ?? magicMessage?.component?.title ?? '';
    if (hasTitle !== title) {
      setTitle(hasTitle);
    }
  }, [magicMessage]);

  const collapseIsland = (): void => {
    setTitle('');
    setMagicMessage(undefined);
  };

  const updateTitle = (title: string): void => {
    setTitle(title);
  };

  const renderBotComponent = (component: ChatComponent): ReactElement => {
    const gaAction = component.type.toUpperCase();
    if (
      component.action_id !== undefined &&
      component.action_id !== '' &&
      component.action_id !== null
    ) {
      gaAction.concat('+', component.action_id.toUpperCase());
    }
    if (gaAction !== '' && gaAction !== null && gaAction !== undefined) {
      GABotEvent('BOT_SHOWS_' + gaAction);
    }
    switch (component.type) {
      case BOT_COMPONENT.SELECT:
        return (
          <CustomSelectIsland
            options={
              component.options !== undefined &&
              Array.isArray(component.options)
                ? component.options
                : []
            }
            collapseIsland={collapseIsland}
            addNewMessages={addNewMessages}
          />
        );
      case BOT_COMPONENT.PREPARING_DATA:
        return (
          <PreparingData
            updateTitle={updateTitle}
            collapseIsland={collapseIsland}
            addNewMessages={addNewMessages}
          />
        );
      case BOT_COMPONENT.RADIO_BUTTONS:
      case BOT_COMPONENT.RESPONSE_BUTTONS:
        return (
          <ResponseButtons
            buttons={component.buttons !== undefined ? component.buttons : []}
            collapseIsland={collapseIsland}
            addNewMessages={addNewMessages}
            componenType={component.type}
          />
        );
      case BOT_COMPONENT.INPUT:
        return (
          <InputSend
            {...(Array.isArray(component.options)
              ? {}
              : {
                  caption: component.options?.caption ?? '',
                  regex: component.options?.regex ?? ''
                })}
            collapseIsland={collapseIsland}
            addNewMessages={addNewMessages}
          />
        );
      case BOT_COMPONENT.CHECKBOXES:
        return (
          <CheckBox
            buttons={component.elements !== undefined ? component.elements : []}
            options={
              component.options !== undefined &&
              Array.isArray(component.options)
                ? component.options
                : []
            }
            collapseIsland={collapseIsland}
            addNewMessages={addNewMessages}
          />
        );
      case BOT_COMPONENT.MULTI_SELECT.toLowerCase():
        return (
          <MultiSelect
            options={
              JSON.parse(
                JSON.stringify(component.options)
              ) as MultiSelectOptions
            }
            collapseIsland={collapseIsland}
            addNewMessages={addNewMessages}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (loading === LOADING_STATE.PROCESSING_DATA) {
      setTitle("I'm training your data...");
    }
  }, [loading]);

  return (
    <div id="magic-island">
      <div
        className="title-container"
        onClick={() => {
          if (magicMessage !== undefined) {
            setCollapseMagicIsland(!collapseMagicIsland);
          }
        }}
      >
        {!collapseMagicIsland && magicMessage === undefined && (
          <>
            <AssistantLogo className="assistant-logo pop-in-anim" />
            {loading === LOADING_STATE.WAITING_MESSAGE && (
              <LoadingDots className="loading-dots slide-in-anim" />
            )}
          </>
        )}
        <Box id="magic-island-title-container">
          <TitleTypography>{title}</TitleTypography>
          {magicMessage !== undefined && (
            <span className="expandArrow slide-in-anim">
              {collapseMagicIsland ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
            </span>
          )}
        </Box>
      </div>
      <Collapse in={collapseMagicIsland} collapsedSize={0}>
        <div className="component-container">
          {magicMessage?.component !== undefined &&
            renderBotComponent(magicMessage.component)}
        </div>
      </Collapse>
    </div>
  );
};

export { MagicIsland };
