import type {
  CSSInterpolation,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme
} from '@mui/material';
import {
  ACCENTS,
  BASE_COLORS,
  hexWithTransparencyPercentage
} from './foundations/Colors';
import { RADIUS } from './foundations/Radius';
import { BASE_FONT_SIZE_LABEL } from './foundations/Typography';

const root: CSSInterpolation = {
  display: 'flex',
  padding: '14px 24px',
  borderRadius: '16px',
  fontSize: BASE_FONT_SIZE_LABEL,
  textTransform: 'capitalize',
  '&:disabled': {
    backgroundColor: BASE_COLORS.BASE_20 + ' !important',
    color: BASE_COLORS.BASE_40 + ' !important',
    userSelect: 'none'
  }
};

interface MUIButtonComponent {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

export const MuiButtonRegular: MUIButtonComponent = {
  styleOverrides: {
    root
  },
  defaultProps: {
    variant: 'primary'
  },
  variants: [
    {
      props: { variant: 'primary' },
      style: {
        color: BASE_COLORS.BASE_0,
        backgroundColor: BASE_COLORS.BASE_90,
        lineHeight: '20px',
        borderRadius: RADIUS.SMALL,
        fontSize: '12px',
        '&:hover': {
          backgroundColor: BASE_COLORS.BASE_80,
          color: BASE_COLORS.BASE_0
        },
        '&:focus': {
          backgroundColor: BASE_COLORS.BASE_90,
          boxShadow:
            '0px 0px 0px 4px ' +
            hexWithTransparencyPercentage(ACCENTS.ACCENT_72, 30),
          color: BASE_COLORS.BASE_0
        },
        '&:active': {
          backgroundColor: BASE_COLORS.BASE_90,
          color: BASE_COLORS.BASE_0
        },
        '&:disabled': {
          background: BASE_COLORS.BASE_20,
          color: BASE_COLORS.BASE_40
        }
      }
    },
    {
      props: { variant: 'secondary' },
      style: {
        color: BASE_COLORS.BASE_90,
        border: '1px solid ' + BASE_COLORS.BASE_40,
        borderRadius: RADIUS.SMALL,
        backgroundColor: BASE_COLORS.BASE_0,
        fontSize: '12px',
        lineHeight: '20px',
        '&:hover': {
          background: BASE_COLORS.BASE_10
        },
        '&:focus': {
          boxShadow:
            '0px 0px 0px 4px ' +
            hexWithTransparencyPercentage(ACCENTS.ACCENT_72, 30)
        },
        '&:active': {
          background: BASE_COLORS.BASE_20,
          color: BASE_COLORS.BASE_90
        },
        '&:disabled': {
          background: BASE_COLORS.BASE_20,
          color: BASE_COLORS.BASE_90,
          borderColor: BASE_COLORS.BASE_40
        }
      }
    },
    {
      props: { variant: 'tertiary' },
      style: {
        color: BASE_COLORS.BASE_90,
        borderRadius: RADIUS.SMALL,
        backgroundColor: BASE_COLORS.BASE_0,
        fontSize: '12px',
        lineHeight: '20px',
        '&:hover': {
          backgroundColor: BASE_COLORS.BASE_10,
          color: BASE_COLORS.BASE_90
        },
        '&:focus': {
          boxShadow: '0px 0px 0px 4px ' + BASE_COLORS.BASE_20
        },
        '&:active': {
          color: BASE_COLORS.BASE_90
        },
        '&:disabled': {
          backgroundColor: BASE_COLORS.BASE_0,
          color: BASE_COLORS.BASE_40
        }
      }
    },
    {
      props: { variant: 'outlined' },
      style: {
        color: BASE_COLORS.BASE_90,
        width: '130px',
        height: '120px',
        borderRadius: RADIUS.SMALL,
        border: '1px solid #EAECF0',
        backgroundColor: BASE_COLORS.BASE_0,
        fontSize: '12px',
        lineHeight: '20px',
        '&:hover': {
          background:
            'linear-gradient(0deg,' +
            BASE_COLORS.BASE_0 +
            ', ' +
            BASE_COLORS.BASE_0 +
            '),linear-gradient(0deg, ' +
            BASE_COLORS.BASE_40 +
            ', ' +
            BASE_COLORS.BASE_40 +
            ')'
        },
        '&:focus': {
          boxShadow:
            '0px 0px 0px 4px ' +
            hexWithTransparencyPercentage(ACCENTS.ACCENT_72, 30)
        }
      }
    }
  ]
};

// Buttons over dark background
export const MuiButtonWhite: MUIButtonComponent = {
  styleOverrides: {
    root
  },
  defaultProps: {
    variant: 'primary'
  },
  variants: [
    {
      props: { variant: 'primary' },
      style: {
        color: BASE_COLORS.BASE_100,
        backgroundColor: BASE_COLORS.BASE_0,
        borderRadius: RADIUS.SMALL,
        fontSize: '12px',
        lineHeight: '20px',
        '&:hover': {
          backgroundColor: BASE_COLORS.BASE_30,
          color: BASE_COLORS.BASE_100
        },
        '&:focus': {
          backgroundColor: BASE_COLORS.BASE_0,
          boxShadow:
            '0px 0px 0px 4px ' +
            hexWithTransparencyPercentage(ACCENTS.ACCENT_72, 50),
          color: BASE_COLORS.BASE_90
        },
        '&:active': {
          backgroundColor: BASE_COLORS.BASE_10,
          color: BASE_COLORS.BASE_80
        },
        '&:disabled': {
          backgroundColor: BASE_COLORS.BASE_80,
          color: BASE_COLORS.BASE_60,
          userSelect: 'none'
        }
      }
    },
    {
      props: { variant: 'secondary' },
      style: {
        color: BASE_COLORS.BASE_0,
        border: '1px solid ' + BASE_COLORS.BASE_40,
        borderRadius: RADIUS.SMALL,
        backgroundColor: BASE_COLORS.BASE_90,
        fontSize: '12px',
        lineHeight: '20px',
        '&:hover': {
          backgroundColor: BASE_COLORS.BASE_80,
          border: '1px solid ' + BASE_COLORS.BASE_40,
          color: BASE_COLORS.BASE_0
        },
        '&:focus': {
          boxShadow:
            '0px 0px 0px 4px ' +
            hexWithTransparencyPercentage(ACCENTS.ACCENT_72, 50),
          color: BASE_COLORS.BASE_0
        },
        '&:active': {
          backgroundColor: BASE_COLORS.BASE_20,
          color: BASE_COLORS.BASE_90
        },
        '&:disabled': {
          backgroundColor: BASE_COLORS.BASE_90,
          border: '1px solid ' + BASE_COLORS.BASE_70,
          color: BASE_COLORS.BASE_80,
          userSelect: 'none'
        }
      }
    },
    {
      props: { variant: 'tertiary' },
      style: {
        color: BASE_COLORS.BASE_100,
        borderRadius: RADIUS.SMALL,
        fontSize: '12px',
        lineHeight: '20px',
        '&:hover': {
          backgroundColor: BASE_COLORS.BASE_0,
          color: BASE_COLORS.BASE_90
        },
        '&:focus': {
          boxShadow: '0px 0px 0px 4px ' + BASE_COLORS.BASE_20
        }
      }
    }
  ]
};
