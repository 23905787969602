/* eslint-disable @typescript-eslint/no-explicit-any */ // Disabling as d3 d parameter is not typed
import { useEffect, useRef, useState, type ReactElement } from 'react';
import type { Plot } from 'playground/interfaces/playground';
import './chartCorrelations.scss';
import {
  select,
  type Selection,
  scaleLinear,
  type ScaleLinear,
  scaleBand,
  type ScaleBand,
  axisTop,
  axisLeft
} from 'd3';
import { Box, Typography } from '@mui/material';

interface ChartCorrealtionsProps {
  isChat?: boolean;
  chartID?: string;
  dataCorrelations: Plot;
  chartName: string;
}

interface FeatureImportanceData {
  key: string;
  strength: string;
  importance: number;
}

const ChartCorrelations = (props: ChartCorrealtionsProps): ReactElement => {
  const [data, setData] = useState<FeatureImportanceData[]>([]);
  const svgRef = useRef<SVGSVGElement>(null);

  function getCorrelationType(absoluteImportanceValue: number): string {
    return absoluteImportanceValue < 0.3
      ? 'weak'
      : absoluteImportanceValue >= 0.7
      ? 'strong'
      : 'moderate';
  }

  // SVG dimensions
  const constrains = {
    padding: 32,
    paddingRange: 32 * 2,
    radius: 4,
    barHeight: props.isChat === false ? 20 : 16,
    barSpacing: props.isChat === false ? 8 : 4,
    axisLeftW: 130,
    duration: 1000
  };

  const correlations = [
    {
      label: 'Strong',
      color: '#02044c'
    },
    {
      label: 'Medium',
      color: '#0f46b1'
    },
    {
      label: 'Weak',
      color: '#007682'
    }
  ];

  // Variables, states & utils
  const [canvas, setCanvas] = useState<null | Selection<
    SVGSVGElement,
    unknown,
    null,
    undefined
  >>(null);
  const getCanvasWidth = (svg: SVGSVGElement): number =>
    svg.clientWidth - constrains.paddingRange - constrains.axisLeftW;
  const getCanvasHeight = (): number =>
    data.length * (constrains.barHeight + constrains.barSpacing);
  let cW = 0;
  let cH = 0;
  let handleResize: () => void;

  const drawChart: () => void = () => {
    // Create canvas obj as base for d3
    if (svgRef.current === null || svgRef.current === undefined) {
      throw new Error('not finding container to print chart');
    }
    if (canvas === null || canvas === undefined) {
      setCanvas(select(svgRef.current));
      return;
    }

    // Set responsive dimensions
    cW = getCanvasWidth(svgRef.current);
    cH = getCanvasHeight();

    // If there is no data, display a message and set height to 100% of container
    const importanceThreshold = data.filter((d) => d.importance !== 0);
    if (importanceThreshold.length === 0) {
      canvas.attr('height', '90%');

      canvas
        .append('text')
        .text('Not significant impact data to display, all values are 0')
        .attr('class', 'chart-no-data')
        .attr(
          'transform',
          `translate(${svgRef.current.clientWidth / 2}, ${
            svgRef.current.clientHeight / 2
          })`
        );

      return;
    }

    // Assign height to canvas, in case it changes based on the size of data
    canvas.attr('height', cH + constrains.paddingRange);

    // SCALES
    const xScale: ScaleLinear<number, number, never> = scaleLinear()
      .domain([-1, 1])
      .range([0, cW]);

    const yScale: ScaleBand<string> = scaleBand()
      .domain(data.map((d) => d.key))
      .range([0, cH])
      .padding(0.1);

    // Create a group for the chart content with the left margin applied
    const chartGroup = canvas
      .append('g')
      .attr(
        'transform',
        `translate(${constrains.padding + constrains.axisLeftW}, ${
          constrains.paddingRange
        })`
      );

    // Axis
    const axisTopObj = axisTop(xScale).ticks(2);
    const axisTopGroup = chartGroup
      .append('g')
      .attr('class', 'axis axis--top')
      .attr('transform', 'translate(0, -12)')
      .call(axisTopObj);

    axisTopGroup
      .selectAll('.tick line')
      .attr('y2', cH + 12)
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4 8');

    const axisLeftGroup = chartGroup
      .append('g')
      .attr('class', 'axis axis--left')
      .call(axisLeft(yScale));

    chartGroup
      .selectAll('.axis--left .tick text')
      .text((d: any) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const short = `${d.slice(0, 20).toLowerCase()}...`;
        const long = d.toLowerCase();
        return d.length > 20 ? short : long;
      })
      .append('title')
      .text((d: any) => d);

    // Axis labels
    chartGroup
      .select('.axis--left')
      .append('text')
      .text('Factors')
      .attr('class', 'axis__label')
      .attr('text-anchor', 'start')
      .attr('transform', `translate(${-constrains.axisLeftW}, -44)`);

    chartGroup
      .select('.axis--top')
      .append('text')
      .text('Impact')
      .attr('class', 'axis__label')
      .attr('text-anchor', 'end')
      .attr('transform', `translate(${cW}, -32)`);

    // Bars
    const bars = chartGroup
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', (d) => `importance--${d.strength}`)
      .attr('x', xScale(0))
      .attr('width', 0)
      .attr('y', (d: any): any => yScale(d.key))
      .attr('height', constrains.barHeight)
      .attr('rx', constrains.radius);

    // Animate the bars
    bars
      .transition()
      .duration(constrains.duration)
      .attr('x', (d) => xScale(Math.min(0, d.importance)))
      .attr('width', (d) => Math.abs(xScale(d.importance) - xScale(0)));

    // Adding bar-labels appearing on hover
    chartGroup
      .append('text')
      .attr('class', 'bar-label')
      .attr('data-strength', '');

    chartGroup
      .selectAll('rect')
      .on('mouseover', (e, d: any) => {
        const rectX = Number(select(e.currentTarget).attr('x'));
        const rectW = Number(select(e.currentTarget).attr('width'));
        const rectY = Number(select(e.currentTarget).attr('y'));

        chartGroup
          .select('.bar-label')
          .style('visibility', 'visible')
          .attr('data-strength', d.strength)
          .text(() => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${d?.importance.toFixed(5) ?? '*'}`;
          })
          .attr('text-anchor', d?.importance < 0 ? 'start' : 'end')
          .attr('x', d?.importance < 0 ? rectX + rectW + 12 : rectX - 12)
          .attr('y', rectY + 14);

        select(e.currentTarget).classed('hovered', true);

        axisLeftGroup
          .selectAll('.tick')
          .classed('hovered', (tickData) => tickData === d.key);
      })
      .on('mouseout', (e) => {
        chartGroup
          .select('.bar-label')
          .attr('data-strength', '')
          .style('visibility', 'hidden')
          .text('');

        select(e.currentTarget).classed('hovered', false);

        axisLeftGroup.selectAll('.tick').classed('hovered', false);
      });

    // Making the width of bars responsive
    handleResize = (): void => {
      // Getting new canvas width and resetting scales
      if (svgRef.current === null) return;
      cW = getCanvasWidth(svgRef.current);
      xScale.range([0, cW]);

      // Updating bars and scales
      chartGroup
        .selectAll('rect')
        .attr('x', (d: any) => xScale(Math.min(0, d.importance)))
        .attr('width', (d: any) => Math.abs(xScale(d.importance) - xScale(0)));

      axisTopGroup.attr('class', 'axis axis--top').call(axisTopObj);

      chartGroup
        .select('.axis--top .axis__label')
        .attr('transform', `translate(${cW}, -32)`);

      axisTopGroup
        .selectAll('.tick line')
        .attr('y2', cH + 12)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '4 8');
    };

    window.addEventListener('resize', () => {
      handleResize();
    });
  };

  // Shorting out data
  useEffect(() => {
    if (
      props?.dataCorrelations?.featureNames?.length !== undefined &&
      props?.dataCorrelations?.featureImportance?.length !== undefined &&
      props?.dataCorrelations?.featurePlot?.length !== undefined
    ) {
      // feature_plot is an array of booleans that indicates which features are being plotted
      const featurePlot = props.dataCorrelations.featurePlot;
      const newData: FeatureImportanceData[] = [];

      featurePlot.forEach((show: boolean, index: number) => {
        if (props.isChat === false) {
          show = true;
        }
        if (show && props.dataCorrelations?.featureNames !== undefined) {
          const absoluteImportanceValue = Math.abs(
            props.dataCorrelations.featureImportance[index]
          );
          const column = getCorrelationType(absoluteImportanceValue);

          newData.push({
            key: props.dataCorrelations.featureNames[index],
            strength: column,
            importance: props.dataCorrelations.featureImportance[index]
          });
        }
      });
      setData(newData);
    }
  }, [props.dataCorrelations]);

  // Rendering the plot's UI
  useEffect(() => {
    if (svgRef.current !== null) {
      // Clearing the canvas to avoid overlapping
      select(svgRef.current).selectAll('*').remove();
    }
    drawChart();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  return (
    <Box
      className={`chart-correlations-container ${
        props.isChat === true ? 'is-chat' : ''
      }`}
      id={props.chartID}
    >
      <Box className="chart-title">
        <Typography>{props.chartName}</Typography>
      </Box>
      <Box className="chart-scroll-container">
        <svg
          ref={svgRef}
          className="chart-correlations-canvas chart-correlations"
        />
      </Box>
      <Box className="chart-legend">
        <Typography className="chart-legend__title">Correlation:</Typography>
        <Box className="chart-legend__items">
          {correlations.map((correlation, index) => {
            return (
              <Box key={index} className="chart-legend__item">
                <Box
                  className="chart-legend__color"
                  style={{ backgroundColor: correlation.color }}
                ></Box>
                {correlation.label}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ChartCorrelations;
