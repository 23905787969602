import { type AxiosResponse } from 'axios';
import { ACCESS_TYPES, ICON_TYPE, RESTRICTIONS } from 'common/interfaces/enums';
import type {
  AccessSelectData,
  Restriction
} from 'common/interfaces/interfaces';
import ReactGA from 'react-ga4';
import { User } from '../../models/User';
import { GAUserEvent } from '../../utils/utils';
import { AuthenticationClient } from './AuthenticationClient';

const getServerResponseUser = (responseServer: AxiosResponse): User =>
  new User(
    responseServer.data.id ?? responseServer.data.user?.id,
    responseServer.data.firstName ?? responseServer.data.user?.firstName,
    responseServer.data.lastName ?? responseServer.data.user?.lastName,
    responseServer.data.email ?? responseServer.data.user?.email,
    responseServer.data.scope ?? responseServer.data.user?.scope,
    responseServer.data.createdDate !== undefined
      ? new Date(responseServer.data.createdDate)
      : new Date(responseServer.data.user?.createdDate),
    responseServer.data.slackId ?? responseServer.data.user?.slackId,
    responseServer.data.accountId ?? responseServer.data.user?.accountId,
    responseServer.data.account ?? responseServer.data.user?.account
  );

export class AuthenticationService {
  private readonly authenticationClient: AuthenticationClient;

  private _authToken: string;

  private authTokenExpiration: Date;

  private user: User | null;

  public accountRestrictions: Restriction[] = [];

  static instance: AuthenticationService;

  public static getInstance(): AuthenticationService {
    if (
      AuthenticationService.instance === undefined ||
      AuthenticationService.instance === null
    ) {
      AuthenticationService.instance = new AuthenticationService();
    }

    return AuthenticationService.instance;
  }

  constructor() {
    this.authenticationClient = AuthenticationClient.getInstance();
    this._authToken = '';
    this.user = null;
    this.authTokenExpiration = new Date();
  }

  public setAuthToken(token: string): void {
    this._authToken = token;
    this.setDateAuthTokenExpiration(new Date());
  }

  async authenticate(email: string, password: string): Promise<void> {
    const loginResponse = await this.authenticationClient.login(
      email,
      password
    );
    this.setUserData(loginResponse);
  }

  async authenticateByToken(token: string): Promise<void> {
    const loginResponse = await this.authenticationClient.getProfile(token);
    this.setUserData(loginResponse, token);
  }

  private setUserData(responseServer: AxiosResponse, token?: string): void {
    this._authToken = '';
    this.user = null;
    this.authTokenExpiration = new Date();
    if (responseServer.status === 200 && responseServer.data !== undefined) {
      this._authToken = responseServer.data.token ?? token;
      this.user = getServerResponseUser(responseServer);
      const accountRestriction = this.user.account?.restrictions ?? [];
      if (Array.isArray(accountRestriction) && accountRestriction.length > 0) {
        this.accountRestrictions = accountRestriction;
      }
      this.setDateAuthTokenExpiration(this.authTokenExpiration);
      GAUserEvent('AUTHENTICATION_SUCCESS');
      ReactGA.set({ user_id: this.user.id });
    } else {
      GAUserEvent('AUTHENTICATION_FAILED');
      throw Error('Not authorized');
    }
  }

  private setDateAuthTokenExpiration(date: Date): void {
    this.authTokenExpiration.setDate(date.getDate() + 1);
  }

  public unauthenticate(): void {
    this._authToken = '';
    this.user = null;
    this.authTokenExpiration = new Date();
  }

  async recoverPassword(email: string): Promise<void> {
    const recoverPasswordResponse =
      await this.authenticationClient.recoverPasswordByEmail(email);
    if (
      recoverPasswordResponse?.status === 0 ||
      recoverPasswordResponse.status !== 204
    ) {
      throw Error('User not exists');
    }
  }

  get authToken(): string {
    return this._authToken;
  }

  get userData(): User | null {
    return this.user;
  }

  get accountRestrictionsData(): Restriction[] {
    return this.accountRestrictions;
  }

  public accountRestrictionByKey(key: RESTRICTIONS): Restriction | undefined {
    return this.accountRestrictions.find(
      (restriction) => restriction.key === key
    );
  }

  public getAccess(): AccessSelectData[] {
    const access: AccessSelectData[] = [
      {
        id: ACCESS_TYPES.PRIVATE,
        text: 'Private',
        icon: ICON_TYPE.LOCK01
      }
    ];

    const sharedResources = this.accountRestrictionByKey(
      RESTRICTIONS.SHARED_RESOURCES
    );
    if (
      sharedResources !== undefined
      // sharedResources.defaultValue === true
    ) {
      access.push({
        id: ACCESS_TYPES.ACCOUNT_SHARED,
        text: 'Acount shared',
        icon: ICON_TYPE.USERS01
      });
    }

    return access;
  }

  public isAuthenticated(): boolean {
    const now = new Date();
    if (
      this.user !== null &&
      this.authTokenExpiration.getTime() > now.getTime()
    ) {
      return true;
    }
    return false;
  }

  async userExists(email: string): Promise<void> {
    const checkEmailIsValidResponse =
      await this.authenticationClient.checkEmailIsValid(email);
    if (
      checkEmailIsValidResponse?.status === 0 ||
      checkEmailIsValidResponse.status !== 204
    ) {
      throw Error('User not exists');
    }
  }

  async resetPassword(
    token: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<void> {
    await this.authenticationClient
      .resetPassword(token, newPassword, confirmPassword)
      .then((response) => {
        if (response.status !== 204) {
          throw Error('User not exists');
        }
      });
  }

  async recoverPasswordToken(email: string): Promise<AxiosResponse> {
    const recoverPasswordResponse =
      await this.authenticationClient.recoverPasswordToken(email);
    if (
      recoverPasswordResponse?.status === 0 ||
      recoverPasswordResponse.status !== 204
    ) {
      throw Error('User not exists');
    }
    return recoverPasswordResponse;
  }
}
