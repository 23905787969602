import type { RESTRICTIONS } from 'common/interfaces/enums';

interface Account {
  id: string;
  name: string;
  ownerUserId: string;
  restrictions: Array<{ key: RESTRICTIONS; defaultValue: unknown }>;
  companyName: string;
  companyIndustry: string;
  companySubsidiaries: string;
  companyWebsite: string;
}
export class User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  scope: string[];
  createdDate: Date;
  slackId: string | null;
  accountId: string | null;
  account: Account | null;

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    scope: string[],
    createdDate: Date,
    slackId: string | null = null,
    accountId: string | null = null,
    account: Account | null = null
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.scope = scope;
    this.createdDate = createdDate;
    this.slackId = slackId;
    this.accountId = accountId;
    this.account = account;
  }
}
