import { type ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import ModalComposed from 'common/modal/ModalComposed';

import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './UploadAlert.scss';

interface MergeOptions {
  title: string;
  body: string[];
  continueUpload: () => void;
  returnToProcess: () => void;
  allowContinue: boolean;
}

const UploadAlert = ({
  title,
  body,
  allowContinue,
  continueUpload,
  returnToProcess
}: MergeOptions): ReactElement => {
  const headerComponent = (
    <Box className="upload-alert__modal-header">
      <Typography className="sm-modal-title-header">{title}</Typography>
    </Box>
  );

  const bodyComponent = (
    <Box
      className={`upload-alert__modal-body ${
        body.length > 1 ? 'multiple-paragraphs' : ''
      }`}
    >
      {body.map((element, ind) => {
        return <Typography key={`alert-${ind}`}>{element}</Typography>;
      })}
    </Box>
  );

  const footerComponent = (
    <Box
      className="sm-modal-footer"
      style={{
        justifyContent: allowContinue ? 'space-between' : 'flex-end'
      }}
    >
      {allowContinue && (
        <CustomButton
          variant="secondary"
          style={{ maxWidth: 'none', width: '60%' }}
          onClick={continueUpload}
        >
          Continue with missing fields
        </CustomButton>
      )}
      <CustomButton
        onClick={returnToProcess}
        style={{ maxWidth: 'none', width: '150px' }}
      >
        Keep editing
      </CustomButton>
    </Box>
  );

  return (
    <ModalComposed
      className="md-modal-wrapper upload-alert"
      open={true}
      style={{ borderRadius: '12px' }}
      body={
        <Box>
          {headerComponent}
          {bodyComponent}
        </Box>
      }
      footer={footerComponent}
    />
  );
};

export default UploadAlert;
