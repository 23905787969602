import {
  type CSSProperties,
  forwardRef,
  type ForwardedRef,
  useState
} from 'react';
import TextField from '@mui/material/TextField';
import {
  type FilledInputProps,
  type OutlinedInputProps,
  type InputProps
} from '@mui/material';

interface TypeClasses<T> {
  root?: T;
}
interface RefInputProps {
  id?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  type?: 'text' | 'number' | 'password';
  variant?: 'standard' | 'filled' | 'outlined';
  maxLength?: string;
  style?: CSSProperties;
  classes?: TypeClasses<string>;
  regex?: string;
  onChange: (value: string) => void;
  enterAction?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  value?: string | number;
  minValue?: number;
  maxValue?: number;
}

const forwardedInput = (
  props: RefInputProps,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const classes = {
    textfield: {
      width: '100%',
      lineHeight: '125%',
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        fontFamily: 'ft-system-regular',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.01em',
        color: '#101828',
        '&.Mui-focused fieldset': {
          borderColor: '#A82AAB',
          boxShadow:
            '0px 0px 0px 4px rgba(168, 42, 171, 0.3), 0px 1px 2px rgba(16, 24, 40, 0.05)'
        }
      }
    }
  };

  const [defaultValue, setDefaultValue] = useState(props?.value);

  const getMinMaxValues = ():
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | undefined => {
    if (
      props?.type === 'number' &&
      (props?.minValue !== undefined || props?.maxValue !== undefined)
    ) {
      const inputProps: { min?: number; max?: number } = {};
      if (props?.minValue !== undefined) {
        inputProps.min = props?.minValue;
      }
      if (props?.maxValue !== undefined) {
        inputProps.max = props?.maxValue;
      }
      return { inputProps };
    }
    return undefined;
  };

  return (
    <TextField
      inputRef={ref}
      id={props.id}
      label={props.label}
      autoComplete="off"
      variant={props?.variant ?? 'outlined'}
      onChange={(e) => {
        e.preventDefault();
        if (defaultValue !== undefined) {
          setDefaultValue(e?.target?.value);
        }
        if (props.onChange !== undefined) {
          if (props?.type === 'number') {
            const localValue = e?.target?.value?.trim();
            if (
              props?.minValue !== undefined &&
              props?.maxValue !== undefined &&
              localValue >= props.minValue.toString() &&
              localValue <= props.maxValue.toString()
            ) {
              props.onChange(localValue);
            } else if (
              props?.minValue !== undefined &&
              localValue >= props.minValue.toString()
            ) {
              props.onChange(localValue);
            } else if (
              props?.maxValue !== undefined &&
              localValue <= props.maxValue.toString() &&
              localValue >= '1'
            ) {
              props.onChange(localValue);
            } else if (
              props?.minValue === undefined &&
              props?.maxValue === undefined
            ) {
              props.onChange(localValue);
            }
          } else {
            props.onChange(e?.target?.value?.trim());
          }
        }
      }}
      sx={props.style ?? classes.textfield}
      type={props?.type ?? 'text'}
      inputProps={{
        maxLength: props.maxLength,
        pattern: props.regex
      }}
      classes={props.classes}
      placeholder={props.placeholder}
      disabled={props.disabled ?? false}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          if (props.enterAction !== undefined) {
            props.enterAction(e);
          }
        }
      }}
      value={defaultValue}
      InputProps={getMinMaxValues()}
    />
  );
};

const Input = forwardRef(forwardedInput);

export default Input;
