import { ChevronLeft } from '@mui/icons-material';
import {
  Box,
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent
} from '@mui/material';
import 'common/Common.scss';
import CustomIcon from 'common/CustomIcon';
import CustomButton from 'common/button/CustomButton';
import {
  type DatasetSchema,
  type SendTransformation
} from 'common/interfaces/interfaces';
import {
  MENU,
  SUB_MENU,
  type IColumnSettingsProps
} from 'featureEngineering/featureEngineeringInterface';
import {
  FeatureType,
  FeatureTypeBigQuery
} from 'playground/interfaces/playground';
import { useState } from 'react';
import { ExposeService } from 'services/ExposeService';
import './SubmenusCommon.scss';

const columnTypes = [
  FeatureType.ID,
  FeatureType.CATEGORICAL,
  FeatureType.BINARY_CATEGORICAL,
  FeatureType.NUMERICAL,
  FeatureType.TEXTUAL,
  FeatureType.DATETIME
];

const ChangeColumnType = ({
  originalPreview,
  selectedMenu,
  setSelectedMenu,
  updateTransformState,
  removeColumnGroup,
  datasetId
}: IColumnSettingsProps & { datasetId: string }): JSX.Element => {
  const exposeService = ExposeService.getInstance();

  const [selectedNewColumnType, setSelectedNewColumnType] =
    useState<DatasetSchema>();
  const [isError, setIsError] = useState(false);
  const [isOriginal, setIsOriginal] = useState(true);

  const getInvalidTypesBySelected = (): FeatureType[] => {
    const blockedTypes = [selectedMenu.columnData.data.dataValue];
    switch (selectedMenu.columnData.data.dataValue) {
      case FeatureType.NUMERICAL:
      case FeatureType.TEXTUAL:
      case FeatureType.ID:
        if (selectedMenu.columnData.type === FeatureTypeBigQuery.INTEGER) {
          blockedTypes.push(
            FeatureType.DATETIME,
            FeatureType.BINARY_CATEGORICAL
          );
        } else {
          blockedTypes.push(
            FeatureType.NUMERICAL,
            FeatureType.DATETIME,
            FeatureType.BINARY_CATEGORICAL
          );
        }
        break;
      case FeatureType.BINARY_CATEGORICAL:
        if (selectedMenu.columnData.type !== FeatureTypeBigQuery.INTEGER) {
          blockedTypes.push(FeatureType.NUMERICAL);
        }
        if (selectedMenu.columnData.type !== FeatureTypeBigQuery.STRING) {
          blockedTypes.push(FeatureType.TEXTUAL);
        }
        blockedTypes.push(FeatureType.DATETIME, FeatureType.ID);
        break;
      case FeatureType.CATEGORICAL:
        if (selectedMenu.columnData.type !== FeatureTypeBigQuery.INTEGER) {
          blockedTypes.push(FeatureType.NUMERICAL);
        }
        if (
          !Array.isArray(selectedMenu.columnData.data.sampleData) ||
          (Array.isArray(selectedMenu.columnData.data.sampleData) &&
            selectedMenu.columnData.data.sampleData.length !== 2)
        ) {
          blockedTypes.push(FeatureType.BINARY_CATEGORICAL);
        }
        blockedTypes.push(FeatureType.DATETIME);
        break;
      case FeatureType.DATETIME:
        blockedTypes.push(
          FeatureType.ID,
          FeatureType.NUMERICAL,
          FeatureType.CATEGORICAL,
          FeatureType.BINARY_CATEGORICAL
        );
        break;
    }
    return blockedTypes;
  };

  const dataValues = columnTypes.filter(
    (type) => !getInvalidTypesBySelected().includes(type)
  );

  const handleNewColumnSelection = async (
    value: SelectChangeEvent
  ): Promise<void> => {
    if (
      originalPreview !== undefined &&
      originalPreview[selectedMenu.columnData.index].dataValue ===
        value.target.value
    ) {
      setIsOriginal(false);
      setIsError(false);
      setSelectedNewColumnType(originalPreview[selectedMenu.columnData.index]);
      return;
    } else if (!isOriginal) {
      setIsOriginal(true);
    }
    try {
      const response = await exposeService.checkColumnTypeChange(
        {
          type: value.target.value,
          column: selectedMenu.columnData.name
        },
        datasetId
      );
      if (response.status === 200 && response.data.schema !== undefined) {
        setSelectedNewColumnType(response.data.schema);
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSaveNewColumn = (): void => {
    if (
      originalPreview !== undefined &&
      removeColumnGroup !== undefined &&
      !isOriginal
    ) {
      removeColumnGroup();
      return;
    }
    const values: SendTransformation = {
      action: SUB_MENU.CHANGE_COLUMN.toLowerCase(),
      type: selectedMenu.columnData.data.dataValue,
      column: selectedMenu.columnData.name,
      params: {
        newType: selectedNewColumnType?.dataValue
      }
    };
    updateTransformState(
      selectedMenu.selected,
      SUB_MENU.CHANGE_COLUMN,
      values,
      selectedNewColumnType
    );
    setSelectedMenu({
      ...selectedMenu,
      text: 'Column settings',
      selected: MENU.COLUMN_SETTINGS
    });
  };

  return datasetId !== undefined ? (
    <>
      <Box className="transformation-selected">
        <Box>
          <CustomIcon type={selectedMenu.icon} />
          <p>{selectedMenu.text}</p>
        </Box>
        <ChevronLeft
          className="cursor-pointer"
          onClick={() => {
            setSelectedMenu({
              ...selectedMenu,
              text: 'Column settings',
              selected: MENU.COLUMN_SETTINGS
            });
          }}
        />
      </Box>
      <Box className="submenu-regular-wrapp">
        <Box className="submenu-regular-header">
          <Box className="submenu-regular-name">
            <Select
              className={`${isError ? 'input-error,' : ''}`}
              style={{ marginLeft: '10px' }}
              onChange={handleNewColumnSelection}
            >
              {dataValues?.map((value, index) => {
                return (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box className="submenu-regular-footer">
        {isError ? (
          <Typography className="errorMsg">
            The selected type is not valid
          </Typography>
        ) : (
          <></>
        )}
        <CustomButton
          disabled={isError || selectedNewColumnType === undefined}
          variant="tertiary"
          style={{
            marginTop: '10px'
          }}
          onClick={handleSaveNewColumn}
        >
          Save
        </CustomButton>
      </Box>
    </>
  ) : (
    <></>
  );
};

export default ChangeColumnType;
