import { Box, Typography } from '@mui/material';
import CustomButton from 'common/button/CustomButton';
import { PredictStatus } from 'common/interfaces/enums';
import {
  modelMatadataAtom,
  predictionAtom,
  runningButtonTextAtom
} from 'playground/atoms/atomPlayground';
import { useState, type ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import Prediction from '../prediction/Prediction';
import Scenario from '../scenario/Scenario';
import './PredictScenario.scss';

enum Mode {
  PREDICT = 'Choose Scenario',
  SCENARIO = 'Single Prediction'
}

interface PredictScenarioProps {
  runScenario: (
    selector: string,
    intention: string,
    goal: string
  ) => Promise<void>;
}

const PredictScenario = ({
  runScenario
}: PredictScenarioProps): ReactElement => {
  const modelMetadata = useRecoilValue(modelMatadataAtom);
  const prediction = useRecoilValue(predictionAtom);
  const [target] = useState<string>(modelMetadata.label);
  const [mode, setMode] = useState<Mode>(Mode.PREDICT);
  const runningButtonText = useRecoilValue(runningButtonTextAtom);

  const toggleMode = (): void => {
    if (mode === Mode.PREDICT) {
      setMode(Mode.SCENARIO);
    } else {
      setMode(Mode.PREDICT);
    }
  };

  return (
    <Box id="predict-scenario-container">
      <Box id="predict-scenario__header">
        <Typography component="div" id="predict-scenario__header--title">
          <Box fontWeight="bold">
            {mode === Mode.PREDICT ? 'PREDICT ' : 'SCENARIO '}
          </Box>
          {target}
        </Typography>
        <CustomButton
          variant="secondary"
          disabled={runningButtonText === PredictStatus.RUNNING}
          onClick={() => {
            toggleMode();
          }}
        >
          {mode}
        </CustomButton>
      </Box>
      <Box id="predict-scenario-container__body">
        {mode === Mode.PREDICT ? (
          <>
            <Typography>
              For a single prediction use the fields on the left, representing
              the key factors from your dataset, to predict the probability of
              your selected goal below.
            </Typography>
            <Prediction
              targetColumn={prediction.targetColumn}
              isProbability={prediction.isProbability}
              prediction={prediction.prediction}
            />
          </>
        ) : (
          <Scenario
            categoryVariants={modelMetadata.categoryVariants}
            runScenario={runScenario}
          />
        )}
      </Box>
    </Box>
  );
};

export default PredictScenario;
