import axios, { type AxiosResponse } from 'axios';

export class AuthenticationClient {
  baseUrl: string;

  static instance: AuthenticationClient;

  public static getInstance(): AuthenticationClient {
    if (
      AuthenticationClient.instance === undefined ||
      AuthenticationClient.instance === null
    ) {
      AuthenticationClient.instance = new AuthenticationClient();
    }

    return AuthenticationClient.instance;
  }

  constructor() {
    const baseUrl: string =
      String(process.env.REACT_APP_AUTHENTICATION_API_BASE_URL) +
      String(process.env.REACT_APP_AUTHENTICATION_API_PREFIX_URI) +
      '/auth';
    this.baseUrl = baseUrl;
  }

  async login(email: string, password: string): Promise<AxiosResponse> {
    const request = { email, password };
    const response = await axios.post(this.baseUrl + '/login', request);
    return response;
  }

  generateHeaders(token: string): { accept: string; Authorization: string } {
    return {
      accept: 'application/json',
      Authorization: 'Bearer ' + token
    };
  }

  async getProfile(token: string): Promise<AxiosResponse> {
    const headers = this.generateHeaders(token);
    const response = await axios.get(this.baseUrl + '/users/profile', {
      headers
    });
    return response;
  }

  async recoverPasswordByEmail(email: string): Promise<AxiosResponse> {
    const request = { email };
    const response = await axios.post(this.baseUrl + '/soft-recover', request);
    return response;
  }

  async checkEmailIsValid(email: string): Promise<AxiosResponse> {
    const body = { email };
    const response = await axios.post(this.baseUrl + '/email', body);
    return response;
  }

  async recoverPasswordToken(email: string): Promise<AxiosResponse> {
    const response = await axios.get(
      this.baseUrl + '/users/recoverPassword/' + email + '?mode=1'
    );
    return response;
  }

  async resetPassword(
    token: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<AxiosResponse> {
    const body = { newPassword, confirmPassword, refreshToken: token };
    const response = await axios.post(
      this.baseUrl + '/users/resetPassword',
      body
    );
    return response;
  }
}
