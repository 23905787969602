export const BASE_FONT_WEIGHT = {
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
  EXTRABOLD: 800,
  BLACK: 900,
  EXTRA_BLACK: 1000
};

export const BASE_FONT_HEADER = {
  TITLE: '28px',
  HEAD_TITLE: '20px',
  SUB_HEADING: '18px'
};

export const BASE_FONT_BODY = {
  LARGE: '16px',
  MEDIUM: '14px',
  SMALL: '12px',
  XSMALL: '11px'
};

export const BASE_FONT_SIZE_LABEL = '12px';
