export interface FilterConstructor {
  name?: string;
  type?: string;
  value?: string | string[] | MinMax;
}

export interface MinMax {
  min?: number;
  max?: number;
}

export class Filter {
  readonly name?: string;
  readonly type?: string;
  private wrappedValue?: string | string[] | MinMax;

  constructor(filterConstructor: FilterConstructor) {
    this.name = filterConstructor.name;
    this.type = filterConstructor.type;
    this.wrappedValue = filterConstructor.value;
  }

  set value(value: string | string[] | MinMax | undefined) {
    this.wrappedValue = value;
  }

  get value(): string | string[] | MinMax | undefined {
    return this.wrappedValue;
  }

  serializeFilter(): string {
    let filter = '';
    let filterValue = '';

    if (
      this.name !== undefined &&
      this.type !== undefined &&
      this.wrappedValue !== undefined
    ) {
      if (
        !Array.isArray(this.wrappedValue) &&
        typeof this.wrappedValue === 'object'
      ) {
        const { min, max } = this.wrappedValue;

        if (min !== undefined && !isNaN(min)) {
          filterValue += `&filter[value][min]=${min}`;
        }
        if (max !== undefined && !isNaN(max)) {
          filterValue += `&filter[value][max]=${max}`;
        }
      } else if (Array.isArray(this.wrappedValue)) {
        for (let i = 0; i < this.wrappedValue.length; i++) {
          filterValue += `&filter[value][${i}]=${this.wrappedValue[i]}`;
        }
      } else if (this.wrappedValue !== '') {
        filterValue += `&filter[value]=${this.wrappedValue}`;
      }
      if (filterValue !== '') {
        filter += `&filter[name]=${this.name}`;
        filter += `&filter[type]=${this.type}`;
        filter += filterValue;
      }
    }
    return filter;
  }
}
