import React, { type ReactElement } from 'react';
import { type Prediction as PredictionProps } from '../../interfaces/playground';
import { Box, Divider, Typography } from '@mui/material';
import './Prediction.scss';

const Prediction = ({
  targetColumn,
  prediction,
  isProbability
}: PredictionProps): ReactElement => {
  const results = (result: number): string => {
    if (result === 0) {
      return '--';
    } else {
      return isProbability ? `${result}%` : `${result}`;
    }
  };
  return (
    <Box id="prediction-container">
      <Box>
        <Typography component="div">
          <Box fontWeight="bold">
            {isProbability ? 'Prediction result' : 'Predicted variable'}
          </Box>
        </Typography>
        <Typography>{targetColumn !== '' ? targetColumn : '--'}</Typography>
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Box>
        <Typography component="div">
          <Box fontWeight="bold">
            {isProbability ? 'Probability' : 'Result'}
          </Box>
        </Typography>
        <Typography>{results(prediction)}</Typography>
      </Box>
    </Box>
  );
};

export default Prediction;
