import { atom, selector } from 'recoil';

export interface OperationItem {
  type: 'column' | 'operator' | 'number';
  value: string;
}

interface FormattedValues {
  formula: string;
  otherColumns: string[];
}

export const operationBuilderModalAtom = atom({
  key: 'operationBuilderModalAtom',
  default: false
});

export const newColumnOperationAtom = atom<OperationItem[]>({
  key: 'newColumnOperationAtom',
  default: []
});

export const getFormattedValues = selector<FormattedValues>({
  key: 'getFormattedValues',
  get: ({ get }) => {
    const operation = get(newColumnOperationAtom);
    const otherColumns: string[] = [];
    const formula = operation
      .map((item) => {
        if (item.type === 'column') {
          otherColumns.push(item.value);
          return `\`${item.value}\``;
        }
        return item.value;
      })
      .join(' ');
    return { formula, otherColumns };
  }
});
