interface Feature {
  type: FeatureType;
  categoryVariants: string[] | null;
  scenario: {
    bestCase: string | number[];
    worstCase: string | number[];
  };
}

enum FeatureType {
  ID = 'id',
  BINARY_CATEGORICAL = 'binary categorical',
  CATEGORICAL = 'categorical',
  NUMERICAL = 'numerical',
  TEXTUAL = 'textual',
  DATETIME = 'datetime'
}

enum FeatureTypeBigQuery {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  TIMESTAMP = 'TIMESTAMP',
  BOOLEAN = 'BOOLEAN'
}

interface Prediction {
  targetColumn: string;
  prediction: number;
  isProbability: boolean;
}
interface PredictResultsResponse {
  downloadId: string;
  inferenceUrl: string;
  jobId: string;
}

interface Plot {
  title: string;
  xlabel: string;
  ylabel: string;
  featureNames: string[];
  featureImportance: number[];
  featurePlot: boolean[];
  featureHighImportance: boolean[];
  rightBarLabel: string;
  leftBarLabel: string;
}

interface Scenario {
  datasetUrl: string;
  targetVariable: string;
  categoryVariant: string[];
}

interface ModelMetadata {
  categoryVariants: string[];
  datasetUrl: string;
  featureImportances: Record<string, Feature>;
  label: string;
  modelId: string;
}

interface ScenarioResult {
  downloadUrl: string;
  runId: string;
}

enum ScenarioSelectors {
  BEST = 'Best Case',
  WORST = 'Worst Case',
  INCREASE = 'Increase',
  DECREASE = 'Decrease'
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Payload = any;

export {
  FeatureType,
  FeatureTypeBigQuery,
  ScenarioSelectors,
  type Feature,
  type ModelMetadata,
  type Plot,
  type PredictResultsResponse,
  type Prediction,
  type Scenario,
  type ScenarioResult
};
