import {
  useEffect,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction
} from 'react';

import { Box, Typography } from '@mui/material';

import type { Dataset, NotifyBoxTypes } from 'common/interfaces/interfaces';
import ModalComposed from 'common/modal/ModalComposed';

import {
  USER_TRACKING_DETAILS_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import { HOME_VIEW_TYPES, ModalSize } from 'common/interfaces/enums';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ExposeService } from 'services/ExposeService';
import { AuthenticationService } from 'services/authentication/AuthenticationService';
import { GAUserEvent } from '../../utils/utils';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import 'common/Common.scss';
import './DeleteAsset.scss';

interface DeleteOptions {
  messageError: [
    null | NotifyBoxTypes,
    Dispatch<SetStateAction<null | NotifyBoxTypes>>
  ];
  closeDeleteModal: () => void;
}

const DEFAULT_TEXT_BODY =
  "Your dataset is private. Deleting the dataset will permanently remove it from your account and it won't be accessible from the assistant.";
const SHARED_TEXT_BODY =
  'Your dataset is shared. Deleting the dataset will permanently remove it from your account and this will prevent you and other users from accessing it. ';

const DeleteAsset = ({
  messageError,
  closeDeleteModal
}: DeleteOptions): ReactElement => {
  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const exposeService = ExposeService.getInstance();
  const authenticationService = AuthenticationService.getInstance();
  const user = authenticationService.userData;
  const [textBody, setTextBody] = useState<string>();
  const { asset } = viewRender.payload;
  const isDataset = viewRender.type === HOME_VIEW_TYPES.DETAIL_DATASETS;
  const [, setMessageError] = messageError;

  useEffect(() => {
    if (
      user?.account?.restrictions !== null &&
      Array.isArray(user?.account?.restrictions)
    ) {
      const foundSharedResources = user?.account?.restrictions.find(
        (restrictions: { key: string; defaultValue: unknown }) =>
          restrictions.key === 'shared_resources'
      );
      const sharedResourceBool = Boolean(foundSharedResources?.defaultValue);
      setTextBody(sharedResourceBool ? SHARED_TEXT_BODY : DEFAULT_TEXT_BODY);
    }
  }, [user]);

  const catchErrorContinue = (): void => {
    closeDeleteModal();
    setMessageError({
      type: 'error',
      message: 'An error occurred while deleting the asset.'
    });
  };

  const executedDelete = (): void => {
    closeDeleteModal();
    setViewRender({
      type: isDataset
        ? HOME_VIEW_TYPES.LOAD_DATASETS
        : HOME_VIEW_TYPES.LOAD_MODELS,
      stored: viewRender.stored
    });
    GAUserEvent(
      `${userLocationVariable.current}_${
        (isDataset ? 'DATASET' : 'MODEL') + USER_TRACKING_DETAILS_ACTIONS.DELETE
      }`
    );
  };

  const handleContinue = (): void => {
    const hasDatasetId = (asset as Dataset).datasetId;
    if (hasDatasetId !== undefined) {
      // If we find the datasetId in the asset is a view
      exposeService
        .deleteView(asset.id)
        .then(executedDelete)
        .catch(catchErrorContinue);
    } else if (isDataset) {
      exposeService
        .deleteDataset(asset.id)
        .then(executedDelete)
        .catch(catchErrorContinue);
    } else {
      exposeService
        .deleteModel(asset.id)
        .then(executedDelete)
        .catch(catchErrorContinue);
    }
  };

  const closeModal = (): void => {
    closeDeleteModal();
  };

  const headerComponent = (
    <Box className="delete-modal-header">
      <Typography className="sm-modal-title-header">
        {`Are you sure you want to delete this ${
          isDataset ? 'Model' : 'dataset'
        }?`}
      </Typography>
    </Box>
  );

  const bodyComponent = (
    <Box className="delete-modal-body">
      <Typography className="sm-modal-body-title">{textBody}</Typography>
    </Box>
  );

  const footerComponent = (
    <Box className="sm-modal-footer">
      <CustomButton variant="secondary" onClick={closeModal}>
        Cancel
      </CustomButton>
      <CustomButton style={{ borderColor: '#D0D5DD' }} onClick={handleContinue}>
        Yes, delete
      </CustomButton>
    </Box>
  );

  return (
    <ModalComposed
      className="sm-modal-wrapper"
      open={true}
      size={ModalSize.SMALL}
      header={headerComponent}
      body={bodyComponent}
      footer={footerComponent}
    />
  );
};

export default DeleteAsset;
