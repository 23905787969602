import { type RefObject, useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { Box } from '@mui/material';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }]
];

interface QuillEditorProps {
  label?: string;
  descriptionRef: RefObject<HTMLTextAreaElement>;
  maxLength?: number;
  readOnly?: boolean;
  value: string;
  placeholder?: string;
  onChange?: (content: string) => void;
}

const TEXT_CHANGE_EVENT = 'text-change';

const AreaTextWysiwyg = ({
  label,
  descriptionRef,
  maxLength = 5000,
  readOnly = false,
  value,
  placeholder = '',
  onChange
}: QuillEditorProps): JSX.Element => {
  const editorRef = useRef<Quill | null>(null);

  const quillConfig = {
    theme: 'snow',
    modules: {
      toolbar: !readOnly && toolbarOptions
    },
    readOnly,
    placeholder
  };

  useEffect(() => {
    if (descriptionRef.current !== null && editorRef.current === null) {
      editorRef.current = new Quill(descriptionRef.current, quillConfig);
      editorRef.current.root.innerHTML = value;
      if (onChange !== undefined) {
        editorRef.current.on(TEXT_CHANGE_EVENT, handleChange);
      }
    }

    return () => {
      editorRef.current?.off(TEXT_CHANGE_EVENT);
      editorRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (
      editorRef.current !== null &&
      editorRef.current.root.innerHTML !== value
    ) {
      editorRef.current.root.innerHTML = value;
    }
  }, [value]);

  const handleChange = (): void => {
    onChange?.(editorRef.current?.root.innerHTML ?? '');
  };

  return (
    <>
      {label !== undefined ? (
        <label
          onClick={() => {
            editorRef.current?.focus();
          }}
        >
          {label}
        </label>
      ) : null}
      <Box className={`${readOnly ? 'textArea-display' : 'textArea'}`}>
        <Box ref={descriptionRef} />
      </Box>
    </>
  );
};

export default AreaTextWysiwyg;
