export const heightResponsive = {};

export const widthResposive = {
  '@media (max-width: 975px)': {
    maxWidth: '700px!important'
  },
  '@media (max-width: 1024px)': {
    maxWidth: '975px!important'
  }
};
