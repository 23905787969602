import { useEffect, useRef, useState, type ReactElement } from 'react';

import { Check } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  Typography
} from '@mui/material';

import { HOME_VIEW_TYPES, ICON_TYPE } from 'common/interfaces/enums';

import { ExposeService } from 'services/ExposeService';

import {
  createErrorNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import MergeCompatibility from './components/mergeCompabilityAlert/MergeCompatibilityAlert';

import CustomIcon from 'common/CustomIcon';
import type {
  Dataset,
  DatasetSchema,
  TableData
} from 'common/interfaces/interfaces';

import ColumnTableImage from 'assets/imgs/ai-set-2.png';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import { GAUserEvent } from 'utils/utils';

import {
  USER_TRACKING_MERGE_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import 'common/Common.scss';
import './MergeDatasets.scss';
import RenderViewComposed from 'home/components/renderView/RenderView';

export interface MergeDatasetsKeys {
  id: string;
  keyIdentifier: string[];
}
export interface MergedDatasetValues {
  selectedKeyId: string;
  disregardedColumns: string[];
  duplicatedColumns: string[];
  id: string;
  name: string;
  schema: DatasetSchema[];
}

interface MergeCollectedData {
  id: string;
  mergeData: MergedDatasetValues;
  rowData: TableData;
}

const MergeDatasets = ({
  selectedAssetsToMerge
}: {
  selectedAssetsToMerge: Dataset[] | [];
}): ReactElement => {
  const exposeService = ExposeService.getInstance();
  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const [openMergeCompatability, setOpenMergeCompatability] =
    useState<boolean>(false);
  const [allowSend, setAllowSend] = useState<boolean>(false);
  const [sendData, setSendData] = useState<boolean>(false);

  const [mainDatasetSelected, setMainDatasetSelected] = useState(0);
  const [amountKeyIdentifyers, setAmountKeyIdentifyers] = useState(1);

  const [keyIdentifierRecord, setKeyIdentifierRecord] = useState<
    MergeDatasetsKeys[]
  >([]);

  const mergedDatasetCollectedData = useRef<MergeCollectedData | undefined>();

  useEffect(() => {
    const hasNecesaryKeys =
      selectedAssetsToMerge.length === keyIdentifierRecord.length &&
      keyIdentifierRecord.every(
        (keys) => keys.keyIdentifier.length === amountKeyIdentifyers
      );

    if (!allowSend && hasNecesaryKeys) {
      setAllowSend(true);
    } else if (allowSend && !hasNecesaryKeys) {
      setAllowSend(false);
    }
  }, [keyIdentifierRecord, amountKeyIdentifyers]);

  const handleException = (): void => {
    createErrorNotification(
      'An error occurred while trying to merge the datasets',
      setNotifyMessage
    );
    setSendData(false);
  };

  const handleSave = async (): Promise<void> => {
    setSendData(true);
    const secondKeyGroup = keyIdentifierRecord.filter(
      (identifiers, index) => index !== mainDatasetSelected
    );
    const arrangedKeyIdentifierGroup = [
      keyIdentifierRecord[mainDatasetSelected],
      ...secondKeyGroup
    ];
    try {
      const { data: mergeCompabilityValues }: { data: MergedDatasetValues } =
        await exposeService.mergeDatasetsKeyValidation(
          arrangedKeyIdentifierGroup
        );
      if (mergeCompabilityValues?.id !== undefined) {
        const [mergedDatasetRows, mergedDatasetPreview] = await Promise.all([
          exposeService.getDatasetRows(mergeCompabilityValues.id),
          exposeService.getDatasetById(
            mergeCompabilityValues.id
          ) as Promise<Dataset>
        ]);
        if (
          mergedDatasetRows?.keys !== undefined &&
          mergedDatasetPreview?.schema !== undefined
        ) {
          mergeCompabilityValues.schema = mergedDatasetPreview.schema;
          mergeCompabilityValues.name = mergedDatasetPreview.name;

          const keyId =
            keyIdentifierRecord[mainDatasetSelected].keyIdentifier[0];
          if (mergeCompabilityValues.duplicatedColumns.includes(keyId)) {
            mergeCompabilityValues.selectedKeyId = `main_${keyId}`;
          } else {
            mergeCompabilityValues.selectedKeyId = keyId;
          }
          const compatabilityPercentage =
            (mergeCompabilityValues.disregardedColumns.length * 100) /
            mergedDatasetRows.keys.length;
          if (compatabilityPercentage > 60) {
            const collectionData = {
              id: mergeCompabilityValues.id,
              mergeData: mergeCompabilityValues,
              rowData: mergedDatasetRows
            };
            mergedDatasetCollectedData.current = collectionData;
            setOpenMergeCompatability(true);
          } else {
            GAUserEvent(
              `${userLocationVariable.current}_${USER_TRACKING_MERGE_ACTIONS.SAVED}`
            );
            setViewRender({
              type: HOME_VIEW_TYPES.MERGE_DATASETS_PREVIEW,
              payload: {
                keyIdentifiers: mergeCompabilityValues,
                tableData: mergedDatasetRows
              },
              stored: viewRender.stored
            });
          }
        }
      } else {
        handleException();
      }
    } catch (error) {
      handleException();
    }
  };

  const handleCancel = (): void => {
    setViewRender({
      type: HOME_VIEW_TYPES.LOAD_DATASETS,
      stored: viewRender.stored
    });
  };

  const handleOnChangeMergeSelection = (
    value: string,
    dataset: Dataset,
    keyIndex: number | undefined,
    position: number
  ): void => {
    let existed = false;
    let remove = false;
    const newKeysIdentiyers = keyIdentifierRecord.map((keysData) => {
      if (keysData.id === dataset.id) {
        existed = true;
        if (keysData.keyIdentifier.includes(value)) {
          keysData.keyIdentifier = keysData.keyIdentifier.filter(
            (identifyers) => identifyers !== value
          );
          if (keysData.keyIdentifier.length === 0) {
            remove = true;
          }
        } else {
          keysData.keyIdentifier[position] = value;
        }
        return keysData;
      }
      return keysData;
    });

    if (existed) {
      if (remove) {
        setKeyIdentifierRecord(
          newKeysIdentiyers.filter((keysData, index) => keyIndex !== index)
        );
      } else {
        setKeyIdentifierRecord(newKeysIdentiyers);
      }
    } else {
      setKeyIdentifierRecord([
        ...keyIdentifierRecord,
        {
          id: dataset.id,
          keyIdentifier: [value]
        }
      ]);
    }
  };

  const continueMerge = (): void => {
    if (mergedDatasetCollectedData.current !== undefined) {
      const { mergeData, rowData } = mergedDatasetCollectedData.current;
      setViewRender({
        type: HOME_VIEW_TYPES.MERGE_DATASETS_PREVIEW,
        payload: {
          keyIdentifiers: mergeData,
          tableData: rowData
        },
        stored: viewRender.stored
      });
    }
  };

  const discardMerge = (): void => {
    if (mergedDatasetCollectedData.current !== undefined) {
      setOpenMergeCompatability(false);
      exposeService
        .deleteDataset(mergedDatasetCollectedData.current.id)
        .then(() => {
          GAUserEvent(
            `${userLocationVariable.current}_${USER_TRACKING_MERGE_ACTIONS.FAILURE}`
          );
          setSendData(false);
        })
        .catch(handleException);
    }
  };

  const renderSelectKeyId = (
    keyIndex: number | undefined,
    position: number,
    dataset: Dataset
  ): JSX.Element => {
    const selectValue =
      keyIndex !== undefined
        ? keyIdentifierRecord[keyIndex].keyIdentifier[position]
        : '';
    return (
      <Select
        key={`${dataset.name}-${position}`}
        disabled={sendData}
        placeholder="Select key identifier"
        labelId="merge-select-key-label"
        className="merge-dataset-selected__select"
        value={selectValue}
        onChange={(event) => {
          const { value } = event.target as { value: string };
          handleOnChangeMergeSelection(value, dataset, keyIndex, position);
        }}
      >
        {dataset.schema?.map((datasetSchema: DatasetSchema, index) => {
          let renderInSelection = null;
          let removeKey = false;
          if (keyIndex !== undefined) {
            const isSelected =
              keyIdentifierRecord[keyIndex].keyIdentifier[position] ===
              datasetSchema.name;
            removeKey = keyIdentifierRecord[keyIndex].keyIdentifier.includes(
              datasetSchema.name
            );
            if (isSelected && removeKey) {
              renderInSelection = <Check />;
              removeKey = false;
            }
          }
          return !removeKey ? (
            <MenuItem
              className="justify-space-between"
              key={`${datasetSchema.name}-${index}`}
              value={datasetSchema.name}
            >
              {datasetSchema.name}
              {renderInSelection}
            </MenuItem>
          ) : null;
        })}
      </Select>
    );
  };

  const headerComponent = (
    <Box className="merge-dataset-header">
      <Typography className="header-title">Merge multiple datasets</Typography>
      <Chip className="alfa-feature" label={'Early access'} />
    </Box>
  );

  const bodyComponent = (
    <Box className="merge-dataset-body">
      <Typography className="ft-md">
        Merge this datasets by selecting your main dataset (the main) and
        identify the key columns (key identifiers) that match between the main
        dataset and the others.
      </Typography>
      <Box className="merge-dataset">
        {selectedAssetsToMerge.map((dataset: Dataset, index) => {
          let position: number | undefined;
          keyIdentifierRecord.find((keys, index) => {
            if (keys.id === dataset.id) {
              position = index;
              return true;
            }
            return false;
          });
          const selectorsArray = [renderSelectKeyId(position, 0, dataset)];
          for (let i = 1; i < amountKeyIdentifyers; i++) {
            selectorsArray.push(renderSelectKeyId(position, i, dataset));
          }
          return (
            <Box
              key={`${dataset.name}-${index}`}
              className={`merge-dataset-selected ${
                index === mainDatasetSelected ? 'merge-main-selected' : ''
              }`}
            >
              <Box className="merge-dataset-selected__name">
                {dataset.datasetId !== undefined ? (
                  <CustomIcon
                    className="patternImage"
                    type={ICON_TYPE.MID_CHILD}
                  />
                ) : (
                  <img
                    className="patternImage"
                    src={ColumnTableImage}
                    alt="Column Image"
                  />
                )}
                <Typography>{dataset.name}</Typography>
              </Box>
              <Box className="merge-dataset-selected__main">
                <FormControlLabel
                  control={
                    <Radio
                      disabled={sendData}
                      id={`main-dataset-selected-${index}`}
                      name={`main-dataset-selected-${index}`}
                      checked={index === mainDatasetSelected}
                      onClick={() => {
                        setMainDatasetSelected(index);
                      }}
                    />
                  }
                  label="Main dataset"
                />
              </Box>
              {selectorsArray}
              <Box className="main-merge-add">
                {index === mainDatasetSelected ? (
                  <>
                    <CustomButton
                      variant="secondary"
                      onClick={() => {
                        setAmountKeyIdentifyers(amountKeyIdentifyers + 1);
                      }}
                      icon={{ type: ICON_TYPE.PLUS, position: 'left' }}
                    ></CustomButton>
                    <Typography className="ft-sm">
                      Add column to the key identifiers
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  const footerComponent = (
    <Box className="justify-space-between" style={{ marginRight: '15px' }}>
      <CustomButton
        variant="secondary"
        disabled={sendData}
        onClick={handleCancel}
        data-cy="merge-modal-cancel-button"
      >
        Cancel merge
      </CustomButton>
      <CustomButton
        disabled={!allowSend || sendData}
        onClick={handleSave}
        data-cy="merge-modal-save-button"
      >
        Validate key identifiers
        {sendData && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ margin: '0px 10px' }}
            />
            <LoadingDots />{' '}
          </>
        )}
      </CustomButton>
    </Box>
  );

  return (
    <>
      <RenderViewComposed
        id="merge-assets"
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      {openMergeCompatability &&
        mergedDatasetCollectedData.current !== undefined && (
          <MergeCompatibility
            continueMerge={continueMerge}
            discardMerge={discardMerge}
          />
        )}
    </>
  );
};

export default MergeDatasets;
